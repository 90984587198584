import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Typography,
  useScreenSize
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import slugify from 'react-slugify';
import { kebabCase } from 'lodash-es';

import { AuthContext } from 'AuthProvider';
import { CategoriesContext } from 'Categories/CategoriesProvider';
import BackToTop from 'common/BackToTop';
import {
  AggregationResults,
  Product,
  ProductSearchResult,
  useGetProductPricingQuery,
  useSearchProductQuery
} from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useDomainInfo } from 'hooks/useDomainInfo';
import SearchBreadcrumbs from 'Search/SearchBreadcrumbs';
import SearchFilters from 'Search/SearchFilters';
import SearchResult from 'Search/SearchResult';
import SearchResultSkeleton from 'Search/SearchResultSkeleton';
import SearchNoResults from 'Search/SearchNoResults';
import SearchVisitorAlert from 'Search/SearchVisitorAlert';
import useSearchQueryParams from 'Search/util/useSearchQueryParams';
import { HeaderContext } from 'common/Header/HeaderProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { BranchContext } from 'providers/BranchProvider';
import FeaturedSearch from 'Search/FeaturedSearch';
import AdvancedToolTip from 'old-components/AdvancedToolTip';
import { WarningIcon } from 'icons';
import { useCartContext } from 'providers/CartProvider';
import { Pagination } from 'components';
import { trackSearchPageOneConversionAction } from 'utils/analytics';
import { encryptData } from 'utils/encrypt';
import { useListsContext } from 'providers/ListsProvider';

/**
 * Types
 */
export type Filters = Pick<
  ProductSearchResult,
  'selectedAttributes' | 'selectedCategories' | 'categoryLevel'
>;

export type LocationStateSearch = {
  categories: string[];
  filters: ProductSearchResult['selectedAttributes'];
};

const PAGE_SIZE = 24;
// Initialize this above & outside the component
const HEATING_COOLING = ['heating and cooling', 'heating & cooling'];
const WATER_HEATERS = ['water heaters', 'water heater'];
const TOOL_RENTAL = ['tool rental'];

function Search() {
  /**
   * Custom components
   */
  const history = useHistory();
  const { pathname } = useLocation();
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const { engine } = useDomainInfo();
  useDocumentTitle(t('common.businessPortal'));

  const [params, setParams] = useSearchQueryParams();
  const { criteria = '', page = 1, categories = [], filters = [] } = params;

  /**
   * Context
   */
  const { authState, profile, user } = useContext(AuthContext);
  const { categories: productCategories, categoriesLoading } =
    useContext(CategoriesContext);
  const {
    setSearchPage,
    setTrackedSearchTerm,
    setPageIndex,
    trackedSearchTerm
  } = useContext(HeaderContext);
  const { selectedAccounts } = useSelectedAccountsContext();
  const { shippingBranch } = useContext(BranchContext);
  const { disableAddToCart } = useCartContext();
  const { callGetAssociatedLists } = useListsContext();
  const userEmail = user?.email ?? '';
  const isHeatingCooling = HEATING_COOLING.includes(criteria.toLowerCase());
  const isWaterHeaters = WATER_HEATERS.includes(criteria.toLowerCase());
  const isToolRental = TOOL_RENTAL.includes(criteria.toLowerCase());
  /**
   * State
   */
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortedAggregates, setSortedAggregates] =
    useState<AggregationResults | null>(null);

  /**
   * Memos
   */
  const filterCount = useMemo(filterCountMemo, [categories, filters]);

  // 🔵 Memo - Encrypted ShipTo and BillTo
  // 🔶 Initialized here so it can be used on graphql query
  const encryptedBillTo = useMemo(
    () => encryptData(selectedAccounts.billToErpAccount?.erpAccountId ?? ''),
    [selectedAccounts.billToErpAccount?.erpAccountId]
  );
  const encryptedShipTo = useMemo(
    () => encryptData(selectedAccounts.shipTo?.erpAccountId ?? ''),
    [selectedAccounts.shipTo?.erpAccountId]
  );

  /**
   * Queries
   */
  const { data, previousData, loading } = useSearchProductQuery({
    fetchPolicy: 'no-cache',
    variables: {
      productSearch: {
        searchTerm: criteria.replace('"', '\\"'),
        page: Number(page),
        size: PAGE_SIZE,
        categoryLevel: categories.length,
        categories,
        filters,
        engine,
        state: selectedAccounts.shipToErpAccount?.state?.toLowerCase(),
        customerNumber: encryptedBillTo
      },
      userId: profile?.userId ?? '',
      shipToAccountId: selectedAccounts.shipTo?.id ?? ''
    },
    onCompleted: (data) => {
      setTotalCount(data.searchProduct?.pagination?.totalItemCount ?? 0);
      //set aggregates in order based on aggregate sortOrder value
      if (data.searchProduct?.aggregates) {
        const sortedAggregates = sortAggregates(data.searchProduct?.aggregates);
        setSortedAggregates(sortedAggregates);
      }
      // Get products ids
      const productsIds = (data?.searchProduct?.products || []).map(
        (product) => product.partNumber ?? ''
      );
      callGetAssociatedLists({ products: productsIds });
    }
  });

  const partNumbers = useMemo(partNumbersMemo, [data?.searchProduct?.products]);

  const { data: pricingData, loading: pricingLoading } =
    useGetProductPricingQuery({
      fetchPolicy: 'no-cache',
      skip: Boolean(!authState?.isAuthenticated || !data?.searchProduct),
      variables: {
        input: {
          customerId: encryptedShipTo,
          branchId: shippingBranch?.branchId ?? '',
          productIds: partNumbers,
          includeListData: true
        }
      }
    });

  /**
   * Effects
   */
  useEffect(handleBadPage, [history, page, params, setParams]);

  useEffect(handleUrlSearch, [
    categoriesLoading,
    pathname,
    params,
    productCategories,
    setParams
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      shippingBranch?.isPricingOnly &&
      filters.includes('inStockLocation|in_stock_location')
    ) {
      setParams({
        ...params,
        page: '1',
        filters: filters.filter(
          (filter) => filter !== 'inStockLocation|in_stock_location'
        )
      });
    }
  }, [shippingBranch?.isPricingOnly, filters, params, setParams]);

  /**
   * Callbacks
   */
  const handlePagination = (newPage: number) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setParams({ ...params, page: newPage.toString() });
  };

  const PaginationElement = () => (
    <Pagination
      count={Math.ceil(
        (data?.searchProduct?.pagination?.totalItemCount || 0) / PAGE_SIZE
      )}
      current={Number(page)}
      ofText={t('common.of')}
      onChange={handlePagination}
      onPrev={handlePagination}
      onNext={handlePagination}
      testId="pagination"
    />
  );

  const searchString = (value: string) => {
    let newSearchValue: string = '';
    if (value) {
      newSearchValue = value.replaceAll(/(")\1+/g, '"');
    }
    return `${newSearchValue} (${totalCount} ${
      totalCount !== 1 ? 'results' : 'result'
    })`;
  };

  if (criteria === null) return <Redirect to="/" />;
  else if (isWaterHeaters) return <Redirect to="/category/water-heaters" />;
  else if (isToolRental) return <Redirect to="/tool-rental" />;
  else if (isHeatingCooling) return <Redirect to="/category/heating-cooling" />;
  else if (
    !loading &&
    (data?.searchProduct?.products?.length === 0 || !data?.searchProduct)
  )
    return <SearchNoResults searchTerm={criteria} />;
  else
    return (
      <>
        <Hidden mdUp>
          <SearchFilters
            loading={Boolean(previousData) && loading}
            count={data?.searchProduct?.pagination?.totalItemCount || 0}
            filters={sortedAggregates as AggregationResults}
            filtersOpen={filtersOpen}
            onShowHide={(value) => setFiltersOpen(value)}
          />
        </Hidden>
        <Box
          flex="1"
          pb={isSmallScreen ? 6 : 12}
          sx={{ bgcolor: 'common.white' }}
        >
          {!authState?.isAuthenticated && !loading ? (
            <SearchVisitorAlert />
          ) : null}
          <Hidden mdUp>
            <Box m={3}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {!loading ? <SearchBreadcrumbs /> : null}
                </Grid>
                <Grid
                  container
                  item
                  xs
                  justifyContent="flex-end"
                  alignItems="center"
                  data-testid={kebabCase(
                    `${
                      categories.length !== 0
                        ? categories[categories.length - 1]
                        : criteria
                    }-title`
                  )}
                >
                  <Typography
                    component="span"
                    textAlign="right"
                    sx={{ fontWeight: 700 }}
                  >
                    &nbsp;
                    {categories.length !== 0
                      ? categories[categories.length - 1]
                      : criteria}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    display="block"
                    sx={{ fontWeight: 700 }}
                  >
                    &nbsp; ({totalCount}{' '}
                    {totalCount !== 1 ? 'results' : 'result'})
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Hidden>
          <Container
            maxWidth="lg"
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                py: 0,
                px: 4
              }
            })}
          >
            <Grid container spacing={6} sx={{ mt: 0 }}>
              <Hidden mdDown>
                <Grid item md={3}>
                  <Box
                    mt={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                  >
                    <SearchFilters
                      loading={loading}
                      count={
                        data?.searchProduct?.pagination?.totalItemCount || 0
                      }
                      filters={sortedAggregates as AggregationResults}
                      filtersOpen={filtersOpen}
                      onShowHide={(value: boolean) => setFiltersOpen(value)}
                    />
                  </Box>
                </Grid>
              </Hidden>
              <Grid item xs={12} md={9}>
                <FeaturedSearch />
                <Hidden mdDown>
                  {!loading ? <SearchBreadcrumbs /> : null}
                  <Box mt={2} mb={5}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        md={6}
                        data-testid={
                          categories.length
                            ? kebabCase(
                                `${categories[categories.length - 1]}-title`
                              )
                            : kebabCase(`${criteria}-title`)
                        }
                      >
                        <Typography
                          variant="h5"
                          component="span"
                          sx={{ fontWeight: 700 }}
                        >
                          {criteria
                            ? searchString(criteria)
                            : searchString(categories[categories.length - 1])}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        md={6}
                        justifyContent="flex-end"
                        data-testid="pagination-counter-top"
                      >
                        {/* {!loading ? <SearchSort /> : null} */}
                        {!loading &&
                        (data?.searchProduct?.pagination?.totalItemCount || 0) >
                          0 ? (
                          <PaginationElement />
                        ) : null}
                      </Grid>
                    </Grid>
                  </Box>
                </Hidden>
                <Grid container spacing={2} data-testid="search-container">
                  {loading ? (
                    [...new Array(3)].map((_, i) => (
                      <Grid
                        container
                        item
                        md={3}
                        direction="column"
                        key={i}
                        sx={(theme) => ({
                          '&:not(:last-child)': {
                            [theme.breakpoints.down('md')]: {
                              mb: 4
                            }
                          }
                        })}
                      >
                        <SearchResultSkeleton
                          testid={`search-result-skeleton-${i}`}
                        />
                      </Grid>
                    ))
                  ) : (
                    <>
                      <Hidden mdUp>
                        <Grid
                          item
                          xs
                          mb={
                            disableAddToCart && isSmallScreen ? 16 : undefined
                          }
                        >
                          <AdvancedToolTip
                            title="Warning"
                            text={t('cart.maxLimitToolTip')}
                            icon={<WarningIcon />}
                            placement="bottom"
                            disabled={disableAddToCart && isSmallScreen}
                          >
                            <Button
                              variant="secondary"
                              size="small"
                              fullWidth
                              onClick={() => setFiltersOpen(true)}
                              sx={{ mb: 3 }}
                              data-testid="filter-count-mob"
                            >
                              {t('common.filter')}
                              {filterCount > 0 ? ` (${filterCount})` : null}
                            </Button>
                          </AdvancedToolTip>
                        </Grid>
                      </Hidden>
                      {data?.searchProduct?.products?.map((product, i) => (
                        <Grid
                          container
                          item
                          md={3}
                          direction="column"
                          key={i}
                          sx={(theme) => ({
                            '&:not(:last-child)': {
                              [theme.breakpoints.down('md')]: {
                                mb: 4
                              }
                            }
                          })}
                        >
                          <SearchResult
                            product={product as Product}
                            pricing={pricingData?.productPricing.products ?? []}
                            pricingLoading={pricingLoading}
                            index={i}
                            onClick={() => handleResultClick(i)}
                          />
                          {isSmallScreen && (
                            <Box pt={3}>
                              <Divider />
                            </Box>
                          )}
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
                {!loading &&
                (data?.searchProduct?.pagination?.totalItemCount || 0) > 0 ? (
                  <Box
                    mt={isSmallScreen ? 6 : 4}
                    mb={isSmallScreen ? 6 : 0}
                    display="flex"
                    justifyContent="center"
                    data-testid="pagination-counter-bottom"
                  >
                    <PaginationElement />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </Box>
        {!loading && isSmallScreen ? <BackToTop /> : null}
      </>
    );

  /**
   * Memo defs
   */
  function filterCountMemo() {
    return categories.length + filters.length;
  }

  function partNumbersMemo() {
    return (data?.searchProduct?.products || []).map(
      (product) => product.partNumber ?? ''
    );
  }

  function sortAggregates(aggregationResults: AggregationResults) {
    if (aggregationResults.builtAggregates) {
      aggregationResults.builtAggregates.sort((a, b) => {
        const sortOrderA = a.sortOrder || 0;
        const sortOrderB = b.sortOrder || 0;
        return sortOrderA - sortOrderB;
      });
    }
    // console.log('************** SORTED AGGS: ***************');
    // console.log(aggregationResults);
    const sortedAggregates = aggregationResults;
    return sortedAggregates;
  }

  /**
   * Effect defs
   */
  function handleBadPage() {
    if (page !== null && Number(page) < 0) {
      setParams({ ...params, page: '1' });
    }
  }

  function handleUrlSearch() {
    const searchPath = '/search/category/';
    if (
      !params.criteria &&
      !params.categories &&
      !params.filters &&
      !categoriesLoading &&
      pathname.startsWith(searchPath) &&
      pathname !== searchPath
    ) {
      const pathCategories = pathname.split('/');
      if (pathCategories.length > 3) {
        const matchedCategories = [''];
        productCategories?.map(
          (c) =>
            c?.name &&
            slugify(c?.name) === pathCategories[3] &&
            matchedCategories.push(c?.name) &&
            pathCategories[4] &&
            c?.children?.map(
              (c1) =>
                c1?.name &&
                slugify(c1?.name) === pathCategories[4] &&
                matchedCategories.push(c1?.name) &&
                pathCategories[5] &&
                c1?.children?.map(
                  (c2) =>
                    c2?.name &&
                    slugify(c2?.name) === pathCategories[5] &&
                    matchedCategories.push(c2?.name)
                )
            )
        );
        matchedCategories &&
          setParams({ ...params, categories: matchedCategories });
      }
    }
  }

  /**
   * Callback Definitions
   */
  function handleResultClick(pageIndex: number) {
    setTrackedSearchTerm(criteria);
    setSearchPage(Number(page));
    setPageIndex(pageIndex);
    if ((page === '1' || page === 1) && trackedSearchTerm !== null) {
      trackSearchPageOneConversionAction({
        billTo: selectedAccounts.billToErpAccount?.erpAccountId ?? '',
        searchTerm: criteria,
        conversionType: 'Product Detail',
        userEmail: userEmail
      });
    }
  }
}

export default Search;
