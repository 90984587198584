import { useMemo } from 'react';

import { Skeleton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import ChevronRight from 'icons/chevron-right.svg';

import { useAuthContext } from 'AuthProvider';
import { Button, CircularProgress } from 'components';
import { BranchIcon, WarningIcon } from 'icons';
import { useBranchContext } from 'providers/BranchProvider';

/**
 * Config
 */
const iconProps = { className: 'text-primary-1-100', width: 20, height: 20 };

/**
 * Component
 */
function BranchButton() {
  /**
   * Custom hooks
   */
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const {
    homeBranchError,
    shippingBranch,
    shippingBranchLoading,
    homeBranchLoading,
    setBranchSelectOpen
  } = useBranchContext();
  const loading = shippingBranchLoading || homeBranchLoading;

  /**
   * Memo
   */
  // 🔵 memo - Button start icon
  const startIcon = useMemo(
    () => (
      <div className="relative">
        {homeBranchError && !shippingBranch ? (
          <WarningIcon
            {...iconProps}
            data-testid="branch-button-icon-warning"
          />
        ) : (
          <BranchIcon {...iconProps} data-testid="branch-button-icon-normal" />
        )}
        {loading && (
          <div className="absolute -top-1 -left-1">
            <CircularProgress size={30} testId="branch-button-icon-loading" />
          </div>
        )}
      </div>
    ),
    [homeBranchError, loading, shippingBranch]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - button click
  const onButtonClick = () => {
    if (authState?.isAuthenticated) {
      setBranchSelectOpen(true);
      return;
    }
    history.push('/login');
  };

  /**
   * Render
   */
  return (
    <Button
      color="gray"
      disabled={loading}
      iconStart={startIcon}
      kind="text"
      onClick={onButtonClick}
      data-testid="change-branch-button"
    >
      {authState?.isAuthenticated ? (
        <div className="w-auto overflow-hidden text-base text-primary-2-100">
          {shippingBranchLoading ? (
            <span data-testid="change-branch-button-loading">
              <Skeleton width={150} />
            </span>
          ) : (
            <Tooltip
              placement="top"
              PopperProps={{ className: 'max-w-none' }}
              title={'Switch store'}
            >
              <p
                data-testid="change-branch-button-text"
                className="flex underline underline-offset-1 ml-1"
              >
                <span
                  className="text-sm font-bold truncate"
                  data-testid="change-branch-button-text-location"
                >
                  {shippingBranch?.city}
                  {shippingBranch?.city && `${shippingBranch?.state} `
                    ? `, ${shippingBranch?.state} `
                    : ''}
                </span>
                <span
                  className="text-sm font-bold truncate ml-1"
                  data-testid="change-branch-button-text-name"
                >
                  {' - '}
                  {shippingBranch?.name}
                </span>
                <img
                  alt="chevronRight-icon"
                  width={'12px'}
                  src={ChevronRight}
                />
              </p>
            </Tooltip>
          )}
        </div>
      ) : (
        t('common.signInToShop')
      )}
    </Button>
  );
}

export default BranchButton;
