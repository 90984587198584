// istanbul ignore file
import { useAuthContext } from 'AuthProvider';
import Header from 'common/Header';
import OldHeader from 'common/OldHeader';
/**
 * Component
 */
function HeaderSelector() {
  /**
   * Context
   */
  const { activeFeatures } = useAuthContext();

  // General Header
  if (activeFeatures?.includes('NEW_HEADER')) {
    return <Header />;
  }
  return <OldHeader />;
}

export default HeaderSelector;
