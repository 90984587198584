import { useMemo } from 'react';

import { Autocomplete, TextField } from '@mui/material';
import clsx from 'clsx';
import {
  FieldValues,
  UseControllerProps,
  useController
} from 'react-hook-form';

import { Modify } from '@reece/global-types';
import Label from 'components/Label';

/**
 * Styles
 */
const autoCompleteStyle = [
  '[&.MuiInputBase-fullWidth.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot]:pr-10',
  '[&_.Mui-error_.MuiOutlinedInput-notchedOutline]:!border-support-1-100',
  '[&_.MuiFormControl-root]:mt-0',
  '[&_.MuiFormControl-root]:p-0',
  '[&_.MuiAutocomplete-clearIndicator]:hidden'
];

/**
 * Types
 */
type NewProps = {
  className?: string;
  disabled?: boolean;
  label?: string;
  options: FormAutocompleteOption[];
  required?: boolean;
  testId?: string;
  disablePortal?: boolean;
};
export type FormAutocompleteOption = { label: string; value: string };
export type FormAutocompleteInputProps<TFields extends FieldValues> = Modify<
  UseControllerProps<TFields>,
  NewProps
>;

/**
 * Component
 */
function FormAutocompleteInput<TFields extends FieldValues>(
  props: FormAutocompleteInputProps<TFields>
) {
  /**
   * Props
   */
  const {
    className,
    disabled,
    disablePortal,
    label,
    options,
    required,
    testId,
    ...rest
  } = props;

  /**
   * Custom Hooks
   */
  const {
    field: { onChange, onBlur, value, name },
    fieldState
  } = useController({ ...rest });

  /**
   * Memos
   */
  const selectedValue = useMemo(
    () => options.find((opt) => opt.value === value),

    [value, options]
  );

  /**
   * Render
   */
  return (
    <div data-testid={testId} className="flex flex-col grow">
      {/* Label */}
      <Label
        label={label}
        required={required}
        htmlFor={props.name}
        testId={testId}
      />
      {/* Autocomplete */}
      <Autocomplete
        id={name}
        options={options}
        value={selectedValue}
        className={clsx(className, autoCompleteStyle)}
        disablePortal={disablePortal}
        onBlur={onBlur}
        onChange={(_, val) => onChange(val?.value)}
        getOptionLabel={({ label }) => label}
        isOptionEqualToValue={({ value }) => value === selectedValue?.value}
        renderInput={({ inputProps, ...rest }) => (
          <TextField
            {...rest}
            error={Boolean(fieldState.error)}
            inputProps={{ ...inputProps, 'data-testid': `${testId}-input` }}
          />
        )}
      />
      {/* Message */}
      {Boolean(fieldState.error) && (
        <span
          className="px-4 pt-1 text-support-1-100 text-xs sm:pt-0"
          data-testid={`${testId}-error-text`}
        >
          {fieldState.error!.message}
        </span>
      )}
    </div>
  );
}

export default FormAutocompleteInput;
