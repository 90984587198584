import { useContext, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Image,
  Link,
  Typography,
  Skeleton,
  useScreenSize
} from '@dialexa/reece-component-library';
import Dotdotdot from 'react-dotdotdot';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { AuthContext } from 'AuthProvider';
import FeedbackButton from 'old-components/FeedbackButton';
import QtyInput from 'common/QtyInput';
import AdvancedToolTip from 'old-components/AdvancedToolTip';
import { Product, ProductPricing } from 'generated/graphql';
import useSavingsMemo from 'hooks/useSavingsMemo';
import { WarningIcon } from 'icons';
import notfound from 'images/notfound.png';
import AddToListbutton from 'common/AddToListButton';
import AvailabilityChip from 'common/AvailabilityChip';
import { BranchContext } from 'providers/BranchProvider';
import { useCartContext, MAX_CART_ITEMS } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import useSearchQueryParams from 'Search/util/useSearchQueryParams';
import CustomerPartNumber from 'Search/CustomerPartNumber';
import 'Search/styles/featuredSearch.scss';
import { format } from 'utils/currency';
import { makeProductSlug } from 'Cart/util';
import { trackSearchPageOneConversionAction } from 'utils/analytics';
interface Props {
  product: Product;
  pricing: ProductPricing[];
  pricingLoading: boolean;
  index: number;
  onClick: () => void;
}

let isEnabled = false;

function SearchResult(props: Props) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * State
   */
  const [quantity, setQuantity] = useState(props.product.minIncrementQty || 1);
  const [availableInList, setAvailableInList] = useState<string[]>([]);

  /**
   * Context
   */
  const { authState, activeFeatures, user } = useContext(AuthContext);
  const { addItemToCart, cart, disableAddToCart, itemCount } = useCartContext();
  const { shippingBranch } = useContext(BranchContext);
  const { selectedAccounts } = useSelectedAccountsContext();
  const billToAccountId = selectedAccounts.billToErpAccount?.erpAccountId ?? '';
  const [params] = useSearchQueryParams();
  const { criteria = '', page = 1 } = params;
  const userEmail = user?.email ?? '';

  const productSlug = makeProductSlug(
    props.product?.manufacturerName,
    props.product?.categories?.at(-1)
  );

  const productPricing = props.pricing.find(
    (p) => p.productId === props.product.partNumber
  );

  const hasValidPrice = productPricing && productPricing?.sellPrice > 0;

  const saved = useSavingsMemo(productPricing?.cmp, productPricing?.sellPrice);
  const CMPEnabled = activeFeatures?.includes('COMPETITIVE_MARKET_PRICE');
  const showsPricesEnabled = activeFeatures?.includes('SHOWS_PRICES');
  const isCompetitiveMarketPrice = saved.isSavings && CMPEnabled;
  const listAddedIds = JSON.parse(
    localStorage.getItem('itemsAddedList') || '{}'
  );

  /**
   * Effects
   */
  useEffect(() => {
    !!productPricing?.listIds && setAvailableInList(productPricing?.listIds);
  }, [productPricing?.listIds]);

  useEffect(() => {
    isEnabled = itemCount >= MAX_CART_ITEMS;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);

  /**
   * Render
   */

  if (isSmallScreen) {
    return (
      <Grid container spacing={2} data-testid={`search-result-${props.index}`}>
        {authState?.isAuthenticated && (
          <Grid
            item
            container
            display="flex"
            justifyContent="flex-end"
            alignItems="top"
          >
            <AddToListbutton
              availableInList={availableInList}
              updatedAddedToLists={updatedAddedToLists}
              partNumber={props.product.partNumber ?? ''}
              quantity={quantity}
              isAddedToList={Array.from(listAddedIds).includes(
                props.product.partNumber
              )}
              index={props.index}
            />
          </Grid>
        )}
        <Grid container item justifyContent="center" alignItems="center">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="50%"
            height="50%"
          >
            <Link
              to={`/product/${productSlug}${props.product.id}`}
              component={RouterLink}
            >
              <Box
                onClick={props.onClick}
                data-testid={`product-image-${props.index}`}
              >
                <Image
                  fallback={notfound}
                  alt={props.product.name ?? t('product.productName')}
                  src={props.product.imageUrls?.thumb ?? ''}
                  title={props.product.name ?? t('product.productName')}
                />
              </Box>
            </Link>
          </Box>
        </Grid>
        <Grid item width="100%">
          <Box flex="1">
            <Typography
              variant="caption"
              component="p"
              gutterBottom
              textTransform="uppercase"
              color="text.secondary"
              data-testid={`product-manufacturer-name-${props.index}`}
            >
              {props.product.manufacturerName}
            </Typography>
            <Typography
              component="div"
              gutterBottom
              data-testid={`product-name-${props.index}`}
            >
              <Dotdotdot clamp={3}>
                <Link
                  to={`/product/${productSlug}${props.product.id}`}
                  component={RouterLink}
                >
                  <Typography
                    onClick={props.onClick}
                    sx={{ overflowWrap: 'break-word' }}
                  >
                    {props.product.name}
                  </Typography>
                </Link>
              </Dotdotdot>
            </Typography>
            {props.product.manufacturerNumber && (
              <Box mb={2}>
                <Typography
                  variant="caption"
                  component="p"
                  data-testid={`product-manufacturer-number-${props.index}`}
                >
                  {t('product.mfr')} {props.product.manufacturerNumber}
                </Typography>
                {props.product.customerPartNumber && (
                  <CustomerPartNumber
                    partNumber={props.product.customerPartNumber}
                    stylingForPage="PLP"
                  />
                )}
              </Box>
            )}
            {!authState?.isAuthenticated && (
              <div className="search-results__cmp-container">
                {showsPricesEnabled && (
                  <span
                    className="search-results__cmp-text-content"
                    data-testid={`search-results-cmp-text-content-${props.index}`}
                  >
                    {format(props.product.cmp ?? 0)}
                  </span>
                )}
                <p
                  className="search-results__cmp-signin-info-text"
                  data-testid={`search-results-cmp-signin-info-text-${props.index}`}
                >
                  <Link to="/login" component={RouterLink}>
                    <span
                      className="search-results__cmp-signin-link-text"
                      data-testid={`search-results-cmp-signin-link-text-${props.index}`}
                    >
                      {t('search.signIn')}
                    </span>
                  </Link>
                  {t('search.signInInfo')}
                </p>
              </div>
            )}
            {authState?.isAuthenticated && (
              <>
                <Box pr={0} mb={2}>
                  <Typography
                    color="primary"
                    variant="h5"
                    my={2}
                    mx={0}
                    data-testid={`product-price-${props.index}`}
                  >
                    {props.pricingLoading ? (
                      <Skeleton
                        variant="rectangular"
                        data-testid={`search-result-pricing-skeleton-${props.index}`}
                      />
                    ) : (
                      hasValidPrice && (
                        <>
                          {isCompetitiveMarketPrice && (
                            <Typography
                              variant="subtitle2"
                              component="p"
                              color="secondary02.main"
                              fontWeight="bold"
                              sx={{ textDecoration: 'line-through' }}
                              data-testid={`product-cmp-${props.index}`}
                            >
                              {format(productPricing?.cmp ?? 0)}{' '}
                            </Typography>
                          )}
                          <Typography
                            component="span"
                            variant="h4"
                            fontWeight="bold"
                            textTransform="lowercase"
                            data-testid={`product-sell-price-${props.index}`}
                          >
                            {format(productPricing?.sellPrice ?? 0)}{' '}
                          </Typography>
                          <Typography
                            component="span"
                            fontWeight="fontWeightHeavy"
                            textTransform="lowercase"
                            data-testid={`product-each-${props.index}`}
                          >
                            {productPricing?.orderUom ?? t('product.each')}
                          </Typography>
                          {isCompetitiveMarketPrice && (
                            <Typography
                              variant="caption"
                              color="success.main"
                              component="p"
                              data-testid={`product-savings-${props.index}`}
                            >
                              {t('common.youSave')}{' '}
                              <Typography
                                variant="caption"
                                fontWeight="bold"
                                component="span"
                                data-testid={`product-saved-price-${props.index}`}
                              >
                                {format(saved.price)}{' '}
                              </Typography>
                              {'('}
                              {saved.percentage}
                              {'%'}
                              {')'}
                            </Typography>
                          )}
                        </>
                      )
                    )}
                  </Typography>
                  <AvailabilityChip
                    branch={shippingBranch}
                    loading={props.pricingLoading}
                    stock={
                      shippingBranch?.isPricingOnly
                        ? productPricing?.totalAvailableQty
                        : productPricing?.branchAvailableQty
                    }
                  />
                </Box>
                <div className="flex items-center gap-2">
                  <QtyInput
                    disabled={props.pricingLoading || !hasValidPrice || !cart}
                    value={quantity}
                    onUpdate={setQuantity}
                    increment={props.product.minIncrementQty || 1}
                    max={999999}
                    index={props.index}
                    size="sm"
                    fullWidth
                    noDebounce
                    testId={`quantity-input-${props.index}`}
                  />

                  <FeedbackButton
                    disabled={
                      props.pricingLoading ||
                      !hasValidPrice ||
                      !cart ||
                      isEnabled
                    }
                    testId={`add-to-cart-button-${props.index}`}
                    fullWidth
                    onClick={handleAddToCart}
                    size="medium"
                    value={t('common.addToCart')}
                    valueDone={t('common.addedToCart')}
                    sx={{ height: '37px' }}
                  />
                </div>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        pb: 2,
        mb: 2,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
      data-testid={`search-result-${props.index}`}
    >
      <CardContent
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        {authState?.isAuthenticated && (
          <Box pb={1.5}>
            <AddToListbutton
              availableInList={availableInList}
              updatedAddedToLists={updatedAddedToLists}
              partNumber={props.product.partNumber ?? ''}
              quantity={quantity}
              isAddedToList={Array.from(listAddedIds).includes(
                props.product.partNumber
              )}
              index={props.index}
            />
          </Box>
        )}
        <Link
          component={RouterLink}
          to={`/product/${productSlug}${props.product.id}`}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            paddingBottom={3}
            height={120}
            onClick={props.onClick}
            data-testid={`product-image-${props.index}`}
          >
            <Image
              fallback={notfound}
              alt={props.product.name ?? t('product.productName')}
              src={props.product.imageUrls?.thumb ?? ''}
              title={props.product.name ?? t('product.productName')}
            />
          </Box>
        </Link>
        <Typography
          variant="caption"
          component="p"
          gutterBottom
          textTransform="uppercase"
          color="text.secondary"
          data-testid={`product-manufacturer-name-${props.index}`}
        >
          {props.product?.manufacturerName}
        </Typography>
        <Typography component="div" gutterBottom>
          <Dotdotdot clamp={3}>
            <Link
              to={`/product/${productSlug}${props.product.id}`}
              component={RouterLink}
              data-testid={`product-name-${props.index}`}
            >
              <Typography
                onClick={props.onClick}
                sx={{ overflowWrap: 'break-word' }}
              >
                {props.product.name}
              </Typography>
            </Link>
          </Dotdotdot>
        </Typography>
        {!!props.product.manufacturerNumber && (
          <Typography
            variant="caption"
            component="p"
            data-testid={`product-manufacturer-number-${props.index}`}
          >
            {t('product.mfr')} {props.product.manufacturerNumber}
          </Typography>
        )}
        {props.product.customerPartNumber && (
          <CustomerPartNumber
            partNumber={props.product.customerPartNumber}
            stylingForPage="PLP"
          />
        )}
      </CardContent>
      {!authState?.isAuthenticated && (
        <div className="search-results__cmp-container">
          {showsPricesEnabled && (
            <span
              className="search-results__cmp-text-content"
              data-testid={`search-results-cmp-text-content-${props.index}`}
            >
              {format(props.product.cmp ?? 0)}
            </span>
          )}
          <p
            className="search-results__cmp-signin-info-text"
            data-testid={`search-results-cmp-signin-info-text-${props.index}`}
          >
            <Link to="/login" component={RouterLink}>
              <span
                className="search-results__cmp-signin-link-text"
                data-testid={`search-results-cmp-siginin-link-text-${props.index}`}
              >
                {t('search.signIn')}
              </span>
            </Link>
            {t('search.signInInfo')}
          </p>
        </div>
      )}
      {authState?.isAuthenticated && (
        <>
          <Box pl={2} pr={2} mb={2}>
            <Typography
              color="primary"
              variant="h5"
              mb={2}
              mx={0}
              data-testid={`product-price-${props.index}`}
            >
              {props.pricingLoading ? (
                <Skeleton
                  variant="rectangular"
                  data-testid={`search-result-pricing-skeleton-${props.index}`}
                />
              ) : (
                hasValidPrice && (
                  <>
                    {isCompetitiveMarketPrice && (
                      <Typography
                        variant="subtitle2"
                        component="p"
                        color="secondary02.main"
                        fontWeight="bold"
                        sx={{ textDecoration: 'line-through' }}
                        data-testid={`product-cmp-${props.index}`}
                      >
                        {format(productPricing?.cmp ?? 0)}{' '}
                      </Typography>
                    )}
                    <Typography
                      component="span"
                      variant="h4"
                      fontWeight="bold"
                      textTransform="lowercase"
                      data-testid={`product-sell-price-${props.index}`}
                    >
                      {format(productPricing?.sellPrice ?? 0)}{' '}
                    </Typography>
                    <Typography
                      component="span"
                      fontWeight="regular"
                      textTransform="lowercase"
                      data-testid={`product-each-${props.index}`}
                    >
                      {productPricing?.orderUom ?? t('product.each')}
                    </Typography>
                    {isCompetitiveMarketPrice && (
                      <Typography
                        variant="caption"
                        color="success.main"
                        component="p"
                        data-testid={`product-savings-${props.index}`}
                      >
                        {t('common.youSave')}{' '}
                        <Typography
                          variant="caption"
                          fontWeight="bold"
                          component="span"
                          data-testid={`product-saved-price-${props.index}`}
                        >
                          {format(saved.price)}{' '}
                        </Typography>
                        {'('}
                        {saved.percentage}
                        {'%'}
                        {')'}
                      </Typography>
                    )}
                  </>
                )
              )}
            </Typography>
            <AvailabilityChip
              branch={shippingBranch}
              loading={props.pricingLoading}
              stock={
                shippingBranch?.isPricingOnly
                  ? productPricing?.totalAvailableQty
                  : productPricing?.branchAvailableQty
              }
              index={props.index}
            />
          </Box>
          <div className="py-0 px-1 flex flex-wrap items-center gap-2">
            <QtyInput
              disabled={props.pricingLoading || !hasValidPrice || !cart}
              value={quantity}
              onUpdate={setQuantity}
              increment={props.product?.minIncrementQty || 1}
              max={9999}
              size="xs"
              index={props.index}
              noDebounce
              testId={`quantity-input-${props.index}`}
            />
            <AdvancedToolTip
              title="Warning"
              text={t('cart.maxLimitToolTip')}
              icon={<WarningIcon width={16} height={16} />}
              placement="bottom"
              disabled={disableAddToCart}
            >
              <FeedbackButton
                disabled={
                  props.pricingLoading || !hasValidPrice || !cart || isEnabled
                }
                testId={`add-to-cart-button-${props.index}`}
                fullWidth
                onClick={handleAddToCart}
                size="small"
                value={t('common.addToCart')}
                valueDone={t('common.addedToCart')}
              />
            </AdvancedToolTip>
          </div>
        </>
      )}
    </Card>
  );

  /**
   * Callback Definitions
   */
  function handleAddToCart() {
    isEnabled = true;
    addItemToCart(
      props.product.partNumber ?? '',
      quantity,
      props.product.minIncrementQty ?? 0,
      productPricing
    );
    if ((page === '1' || page === 1) && criteria !== null) {
      trackSearchPageOneConversionAction({
        billTo: billToAccountId,
        searchTerm: criteria,
        conversionType: 'Add to Cart',
        userEmail: userEmail
      });
    }
  }

  function updatedAddedToLists(value: string[]) {
    setAvailableInList(value);
  }
}

export default SearchResult;
