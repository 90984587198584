import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';
import InfoChip, { InfoChipProps } from 'components/InfoChip';
import { useBranchContext } from 'providers/BranchProvider';
import { DeliveryIcon, InStockIcon, QuickOrder } from 'icons';
import { Branch } from 'generated/graphql';

/**
 * Config
 */
export const MAX_CHIP_STOCK = 999;

/**
 * Props
 */
export type AvailabilityChipProps = {
  loading?: boolean;
  index?: number;
  branch?: Branch;
  stock?: number;
  error?: boolean;
};

/**
 * Component
 */
function AvailabilityChip(props: AvailabilityChipProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const { shippingBranchLoading } = useBranchContext();

  /**
   * Memo
   */
  // 🔵 memo - display
  const chipDisplay: InfoChipProps = useMemo(() => {
    // default - no stock is undefined or 0
    if (!props.stock) {
      return { text: t('common.availableForOrder'), icon: <QuickOrder /> };
    }
    // Non EHG Branch
    const numberAvailable =
      props.stock > MAX_CHIP_STOCK ? `${MAX_CHIP_STOCK}+` : props.stock;
    if (!props.branch?.isPricingOnly) {
      return {
        contained: true,
        text: `${numberAvailable} ${t('common.inStock')}`,
        icon: <InStockIcon />,
        color: 'green'
      };
    }
    // EHG Branch
    return {
      contained: true,
      text: `${numberAvailable} ${t('common.readyToShip')}`,
      icon: <DeliveryIcon />,
      color: 'primary'
    };
  }, [props.stock, props.branch?.isPricingOnly, t]);

  /**
   * Render
   */
  if (!authState?.isAuthenticated || props.error) {
    return null;
  }
  return (
    <InfoChip
      testId={`availability-chip-${props.index ?? props.branch?.branchId}`}
      loading={props.loading || shippingBranchLoading}
      loadingClassName="!w-28"
      {...chipDisplay}
    />
  );
}

export default AvailabilityChip;
