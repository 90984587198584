import { useMemo } from 'react';

import { AccordionDetails, AccordionSummary } from '@mui/material';
import clsx from 'clsx';
import { chunk } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Accordion } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ArrowDropDownIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Types
 */
type PackageDimensionsData = { label: string; value: string };

/**
 * Component
 */
function PackageDimensions() {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { product } = useProductPageContext();
  const pkgDimensions = product?.packageDimensions;

  /**
   * Memo
   */
  // 🔵 memo - main data
  const data = useMemo(() => {
    const list: PackageDimensionsData[] = [];
    pkgDimensions?.length &&
      list.push({
        label: `${t('product.length')}: `,
        value: `${pkgDimensions.length}`
      });
    pkgDimensions?.width &&
      list.push({
        label: `${t('product.width')}: `,
        value: `${pkgDimensions.width}`
      });
    pkgDimensions?.height &&
      list.push({
        label: `${t('product.height')}: `,
        value: `${pkgDimensions?.height}`
      });
    pkgDimensions?.volume &&
      list.push({
        label: `${t('product.volume')}: `,
        value: `${pkgDimensions.volume} ${
          pkgDimensions?.volumeUnitOfMeasure ?? ''
        }`
      });
    pkgDimensions?.weight &&
      list.push({
        label: `${t('product.weight')}: `,
        value: `${pkgDimensions.weight} ${
          pkgDimensions?.weightUnitOfMeasure ?? ''
        }`
      });
    return list;
  }, [pkgDimensions, t]);
  // 🔵 memo - desktop columns
  const [columnLeft, columnRight] = useMemo(
    () => chunk(data, Math.ceil(data.length / 2)),
    [data]
  );

  /**
   * Render
   */
  // 🟩 Empty
  if (!pkgDimensions || !data.length) {
    return null;
  }
  // 🟩 Desktop
  if (!isSmallScreen) {
    return (
      <div
        className="flex flex-nowrap gap-20"
        data-testid="pdp-pkg-dimensions-desktop"
      >
        <div className="basis-1/2">
          <table className="table-row-group text-primary-3-100 text-base">
            <tbody>
              {columnLeft?.map((item) => (
                <tr className="align-middle" key={item.label}>
                  <td className="pr-3 py-4 font-bold">{item.label}</td>
                  <td className="pl-3 py-4 ">{item?.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="basis-1/2">
          <table className="table-row-group text-primary-3-100 text-base">
            <tbody>
              {columnRight?.map((item) => (
                <tr className="align-middle" key={item.label}>
                  <td className="pr-3 py-4 font-bold">{item.label}</td>
                  <td className="pl-3 py-4 ">{item?.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
  // 🟩 Mobile
  return (
    <Accordion
      type="product"
      className="text-primary-3-100"
      data-testid="pdp-pkg-dimensions-mobile"
    >
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        {t('product.dimensions')}
      </AccordionSummary>
      <AccordionDetails>
        <ul className="w-full list-none px-6 py-2">
          {data.map((item, i) => (
            <li
              key={`product-package-dimensions-${i}`}
              className={clsx(
                'flex justify-start items-center w-full py-4 border-secondary-3-100 hover:bg-secondary-4-100',
                { 'border-b': i < data.length - 1 }
              )}
            >
              <p className="text-sm text-primary-3-100">
                <b>{item.label}</b>
                {item.value}
              </p>
            </li>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}

export default PackageDimensions;
