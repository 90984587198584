import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { AxiosResponse } from 'axios';
import { escapeRegExp, noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'yup/lib/types';

import { WrapperProps } from '@reece/global-types';
import {
  useApiAddProductsToList,
  useApiCreateList,
  useApiDeleteList,
  useApiDeleteProductList,
  useApiDuplicateList,
  useApiPrintList,
  useApiPrintListBarcode,
  useApiDownloadListCSV,
  useApiGetListDetails,
  useApiGetLists,
  useApiGetListsBySearchTerm,
  useApiUpdateList,
  useApiSortLists
} from 'API/lists.api';
import { useApiGetProductsPricing } from 'API/products.api';
import {
  AddProductsToListRequest,
  CreateListRequest,
  UpdateListRequest,
  DeleteProductListResponse,
  DownloadListCSVRequest,
  DownloadListPDFRequest,
  List,
  GetListDetailsResponse,
  GetListsResponse,
  ProductBasicInfo,
  ProductsIds
} from 'API/types/lists.types';
import { Product, ProductPricing } from 'API/types/products.types';
import useScreenSize from 'hooks/useScreenSize';
import { useQueryParams } from 'hooks/useSearchParam';
import { useBranchContext } from 'providers/BranchProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useToastContext } from 'providers/ToastProvider';
import { downloadFile } from 'utils/downloadFile';
import { asyncNoop, defaultNoopOutput } from 'utils/etc';
import { getQueryParam } from 'utils/getQueryParam';

/**
 * Types
 */
export type ListsPageContextType = {
  lists: List[];
  setLists: (lists: List[]) => void;
  listsLoading: boolean;
  selectedList?: SelectedList;
  selectedListLoading: boolean;
  addProductsToListLoading: boolean;
  updateListLoading: boolean;
  createListLoading: boolean;
  duplicateListLoading: boolean;
  fileLoading: boolean;
  setFileLoading: (loading: boolean) => void;
  listProducts: ListProduct[];
  filteredListProducts: ListProduct[];
  setListProducts: (lists: ListProduct[]) => void;
  searchAllListsValue?: string;
  setSearchAllListsValue: (searchTerm?: string) => void;
  emptyListSearch: boolean;
  openCreateFormDialog: boolean;
  setOpenCreateFormDialog: (open: boolean) => void;
  productsToAddToList: ProductBasicInfo[];
  setProductsToAddToList: (products: ProductBasicInfo[]) => void;
  productsPricing?: ProductPricing[];
  pricingLoading: boolean;
  handleGetProductPricing: (listId: string) => Promise<void>;
  applySelectedList: (list: List, index: number) => void;
  callCreateList: (
    params: CreateListRequest
  ) => Promise<Maybe<GetListsResponse>>;
  callDeleteList: (listId: string) => Promise<number | undefined>;
  callDuplicateList: (
    listId: string,
    body: UpdateListRequest
  ) => Promise<number | undefined>;
  callPrintList: (
    listId: string,
    params: DownloadListPDFRequest
  ) => Promise<Maybe<AxiosResponse<Maybe<BlobPart>>> | undefined>;
  callPrintBarcodeList: (
    listId: string,
    params: DownloadListPDFRequest,
    body: ProductsIds
  ) => Promise<Maybe<AxiosResponse<Maybe<BlobPart>>> | undefined>;
  callAddProductsToList: (
    body: AddProductsToListRequest
  ) => Promise<List[] | undefined>;
  callUpdateList: (
    listId: string,
    body: UpdateListRequest
  ) => Promise<number | undefined>;
  callDownloadListCSV: (
    listId: string,
    params: DownloadListCSVRequest
  ) => Promise<Maybe<AxiosResponse<Maybe<BlobPart>>> | undefined>;
  callSearchList: (searchTerm: string) => void;
  callDeleteProductList: (
    listId: string,
    body: ProductsIds
  ) => Promise<Maybe<number>>;
  listsRefetch: () => void;
  selectedLineItems: SelectedLineItemMap;
  setSelectedLineItems: (obj: SelectedLineItemMap) => void;
  toggleSelectedLineItem: (id: string, availability: boolean) => void;
  findProductPricing: (index: number, id: string) => ProductPricing | undefined;
  searchListValue: string;
  setSearchListValue: (s: string) => void;
  sortLists: (list: List[]) => void;
  sortListLineItem: (products: ListProduct[]) => void;
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (open: boolean) => void;
  searchLoading: boolean;
  pricingError: boolean;
};
export type ListQueryParam = {
  id?: string;
};
export type ListProduct = Product & {
  selected?: boolean;
  isDragDisabled?: boolean;
};
// Note - boolean used for availability
export type SelectedLineItemMap = { [id: string]: boolean };
export enum ListAction {
  create = 'created',
  upload = 'uploaded',
  update = 'updated',
  duplicate = 'duplicated',
  print = 'printed',
  delete = 'deleted'
}
export type SelectedList = {
  list: List;
  index: number;
};
export type ListSpecial = List & { isDragDisabled: boolean };

/**
 * Context
 */
export const defaultListsPageContext: ListsPageContextType = {
  lists: [],
  setLists: noop,
  listsLoading: false,
  selectedListLoading: false,
  addProductsToListLoading: false,
  updateListLoading: false,
  createListLoading: false,
  duplicateListLoading: false,
  fileLoading: false,
  setFileLoading: noop,
  listProducts: [],
  filteredListProducts: [],
  setListProducts: noop,
  searchAllListsValue: undefined,
  setSearchAllListsValue: noop,
  emptyListSearch: false,
  openCreateFormDialog: false,
  setOpenCreateFormDialog: noop,
  productsToAddToList: [],
  setProductsToAddToList: noop,
  applySelectedList: noop,
  pricingLoading: false,
  handleGetProductPricing: asyncNoop,
  callCreateList: asyncNoop,
  callDeleteList: asyncNoop,
  callDuplicateList: asyncNoop,
  callPrintList: asyncNoop,
  callPrintBarcodeList: asyncNoop,
  callAddProductsToList: asyncNoop,
  callUpdateList: asyncNoop,
  callDownloadListCSV: asyncNoop,
  callSearchList: asyncNoop,
  callDeleteProductList: asyncNoop,
  listsRefetch: asyncNoop,
  selectedLineItems: {},
  setSelectedLineItems: noop,
  toggleSelectedLineItem: noop,
  findProductPricing: defaultNoopOutput(undefined),
  searchListValue: '',
  setSearchListValue: noop,
  sortLists: noop,
  sortListLineItem: noop,
  mobileDrawerOpen: false,
  setMobileDrawerOpen: noop,
  searchLoading: false,
  pricingError: false
};
export const ListsPageContext = createContext(defaultListsPageContext);
export const useListsPageContext = () => useContext(ListsPageContext);

/**
 * Provider
 */
function ListsPageProvider({ children }: WrapperProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const [queryParam, setQueryParam] = useQueryParams<ListQueryParam>(
    { arrayKeys: [] },
    true
  );

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const { shippingBranch } = useBranchContext();
  const { toast } = useToastContext();

  /**
   * States
   */
  const [lists, setLists] = useState<List[]>([]);
  const [searchAllListsValue, setSearchAllListsValue] = useState<string>();
  const [emptyListSearch, setEmptyListSearch] = useState(false);
  const [openCreateFormDialog, setOpenCreateFormDialog] = useState(false);
  const [productsToAddToList, setProductsToAddToList] = useState<
    ProductBasicInfo[]
  >([]);
  const [selectedList, setSelectedList] = useState<SelectedList>();
  const [listProducts, setListProducts] = useState<ListProduct[]>([]);
  const [productsPricing, setProductsPricing] = useState<ProductPricing[]>();
  const [selectedLineItems, setSelectedLineItems] =
    useState<SelectedLineItemMap>({});
  const [searchListValue, setSearchListValue] = useState('');
  const [listRefetchCalled, setListRefetchCalled] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);
  const [pricingError, setPricingError] = useState(false);

  /**
   * API
   */
  // 🟣 API - get all lists
  const {
    loading: listsLoading,
    called: listsCalled,
    refetch: listsRefetch
  } = useApiGetLists({
    onCompleted: onCompleteLists(false, true),
    skip: !selectedAccounts.billTo?.id
  });
  // 🟣 Lazy API - get specific list
  const { call: getListDetails, loading: selectedListLoading } =
    useApiGetListDetails();
  // 🟣 Lazy API - create new list
  const { call: createList, loading: createListLoading } = useApiCreateList();
  // 🟣 Lazy API - get products pricing
  const {
    call: getProductsPricing,
    called: productsPricingCalled,
    loading: pricingLoading
  } = useApiGetProductsPricing();
  // 🟣 Lazy API - delete list
  const { call: deleteList } = useApiDeleteList({
    onCompleted: onCompleteLists(false)
  });
  // 🟣 Lazy API - update list
  const { call: updateList, loading: updateListLoading } = useApiUpdateList({
    onCompleted: onCompleteLists(false)
  });
  // 🟣 Lazy API - duplicate list
  const { call: duplicateList, loading: duplicateListLoading } =
    useApiDuplicateList({
      onCompleted: onCompleteLists(true)
    });
  // 🟣 Lazy API - print list
  const { call: callPrintList } = useApiPrintList({
    onCompleted: ({ data, config: { url } }) =>
      handleCompleteDownloadList(data, url ?? '', 'application/pdf')
  });
  // 🟣 Lazy API - print barcode list
  const { call: callPrintBarcodeList } = useApiPrintListBarcode({
    onCompleted: ({ data, config: { url } }) =>
      handleCompleteDownloadList(data, url ?? '', 'application/pdf')
  });
  // 🟣 Lazy API - create new list
  const { call: callDownloadListCSV } = useApiDownloadListCSV({
    onCompleted: ({ data, config: { url } }) =>
      handleCompleteDownloadList(data, url ?? '', 'text/csv')
  });
  // 🟣 Lazy API - search lists
  const { call: callSearchList, loading: searchLoading } =
    useApiGetListsBySearchTerm({
      onCompleted: (res) =>
        res.data?.lists?.length
          ? onCompleteLists(false)(res)
          : setEmptyListSearch(true)
    });
  // 🟣 Lazy API - add products to list
  const { call: addProductsToList, loading: addProductsToListLoading } =
    useApiAddProductsToList();
  // 🟣 Lazy API - remove products from list
  const { call: deleteProductList } = useApiDeleteProductList({
    onCompleted: ({ data }) => handleCompletedDeleteProductList(data)
  });
  // 🟣 Lazy API - update lists sorting
  const { call: sortListCall } = useApiSortLists();

  /**
   * Memo
   */
  // 🔵 Memo - List product with search filter
  const filteredListProducts = useMemo(() => {
    const search = searchListValue.toLowerCase();
    // when not searching, return as it is
    if (!search) {
      return listProducts;
    }
    // when searching, return filtered list
    return listProducts.reduce<ListProduct[]>((prev, item) => {
      const regExp = new RegExp(`(${escapeRegExp(search)})`, 'gi');
      const match =
        regExp.test(item.name) ||
        regExp.test(item.manufacturerName ?? '') ||
        regExp.test(item.manufacturerNumber ?? '') ||
        regExp.test(`MSC-${item.productId}`) ||
        item.customerProductId?.some((i) => regExp.test(i));
      match && prev.push({ ...item, isDragDisabled: true });
      return prev;
    }, []);
  }, [listProducts, searchListValue]);

  /**
   * Callbacks
   */
  // 🟤 Cb - Find at nth based on queryParam id
  const findListByQueryParam = useCallback(
    (list: List[]) => {
      const index = list.findIndex(({ id }) => queryParam.id === id);
      if (index === -1) {
        setQueryParam({ id: undefined });
      }
      return index;
    },
    [queryParam, setQueryParam]
  );
  // 🟤 Cb - set the selected list and make the call
  const applySelectedList = async (list: List, index: number) => {
    const lastSelectedList = selectedList && { ...selectedList };
    setSelectedList({ list, index });
    // Exit if it's same list
    if (lastSelectedList?.list.id === list.id) {
      return;
    }
    setSelectedLineItems({});
    const getListCall = () =>
      getListDetails(list.id)
        .then(
          (res) =>
            // Populate lists data
            res?.data?.listInfo &&
            res.data.products &&
            refreshListsAndProducts(res.data, list.id, index)
        )
        .catch(() => {
          toast({
            message: t('lists.listLoadingError', { name: list.name }),
            kind: 'error',
            button: {
              display: t('common.retry'),
              action: getListCall
            }
          });
          setSelectedList(lastSelectedList);
        });
    getListCall();
  };

  // 🟤 Cb - Refresh products and lists
  const refreshListsAndProducts = (
    data: GetListDetailsResponse,
    listId: string,
    index: number
  ) => {
    if (data?.listInfo && data?.products) {
      const { listInfo, products } = data;
      setQueryParam({ ...queryParam, id: listInfo.id });
      setSelectedList({ list: listInfo, index });
      setListProducts(products);
      data.products.length && handleGetProductPricing(listId);
    }
  };

  // 🟤 Cb - get product pricing API
  const handleCompletedDeleteProductList = (
    data: DeleteProductListResponse
  ) => {
    const listIndex =
      data.lists?.findIndex(
        (list) => list.id === data.listDetails.listInfo?.id
      ) ?? 0;
    const listId = data.listDetails.listInfo?.id ?? '';
    setSelectedLineItems({});
    data.lists && setLists(data.lists);
    refreshListsAndProducts(data.listDetails, listId, listIndex);
  };

  // 🟤 Cb - get product pricing API
  const handleGetProductPricing = async (listId: string) => {
    setPricingError(false);
    // Callable function used to be called x times
    const call = (times: number = 3) =>
      getProductsPricing(listId)
        .then((res) => {
          setProductsPricing(res?.data?.products);
          !res?.data?.products && call(times - 1);
        })
        .catch(() => {
          if (times > 1) {
            call(times - 1);
          } else {
            setPricingError(true);
            toast({
              message: t('lists.pricingError'),
              kind: 'error',
              button: { display: t('common.retry'), action: call }
            });
          }
        });
    call();
  };
  // 🟤 Cb - find product pricing by index and product id
  const findProductPricing = useCallback(
    (index: number, id: string) => {
      if (!productsPricing) {
        return;
      }
      // Attempt to find product pricing by nth index automatically
      const nthPricing = productsPricing[index];
      if (nthPricing?.productId === id) {
        return nthPricing;
      }
      // manually find the right pricing if the nth pricing doesn't match
      return productsPricing.find(({ productId }) => productId === id);
    },
    [productsPricing]
  );
  // 🟤 Cb - handle queries that get current or updated lists
  const handleCompleteDownloadList = (
    response: BlobPart,
    requestUrl: string,
    fileType: 'application/pdf' | 'text/csv'
  ) => {
    const listName = getQueryParam(requestUrl, 'listName');
    if (fileType === 'text/csv') {
      downloadFile(response, listName, fileType);
      return;
    }

    const blob = new Blob([response], { type: fileType });
    const url = window.URL.createObjectURL(blob);
    if (url) {
      setFileLoading(false);
      window.open(url);
      window.URL.revokeObjectURL(url);
    }
  };

  // 🟤 Cb - call query to create list
  const callCreateList = async (params: CreateListRequest) => {
    const res = await createList(params);
    return res?.data;
  };
  // 🟤 Cb - toggle line item select
  const toggleSelectedLineItem = (id: string, availability: boolean) => {
    const mutableData = { ...selectedLineItems };
    const selected = id in mutableData;
    if (selected) {
      delete mutableData[id];
    } else {
      mutableData[id] = availability;
    }
    setSelectedLineItems(mutableData);
  };

  // 🟤 Cb - call query to delete list
  const callDeleteList = async (listId: string) => {
    const res = await deleteList(listId);
    return res?.status;
  };

  // 🟤 Cb - call query to update list
  const callUpdateList = async (listId: string, body: UpdateListRequest) => {
    const res = await updateList(listId, body).catch((error) => {
      console.error({ error });
      toast({ message: t('lists.updateError'), kind: 'error' });
    });
    return res?.status;
  };

  // 🟤 Cb - call query to duplicate list
  const callDuplicateList = async (listId: string, body: UpdateListRequest) => {
    const res = await duplicateList(listId, body);
    return res?.status;
  };

  // 🟤 Cb - call query add product to list
  const callAddProductsToList = async (body: AddProductsToListRequest) => {
    const res = await addProductsToList(body);
    return res?.data?.lists;
  };

  // 🟤 Cb - call delete product in list
  const callDeleteProductList = async (listId: string, body: ProductsIds) => {
    const res = await deleteProductList(listId, body);
    return res?.status;
  };

  // 🟤 Cb - API onComplete - shared getList response
  // 🔶 has to be a `function` given how it is called with API onCompleted
  function onCompleteLists(nonIdempotent: boolean, initial?: boolean) {
    return ({ data }: AxiosResponse<GetListsResponse>) => {
      // No lists
      setSelectedList(undefined);
      if (!data.lists?.length) {
        setLists([]);
        return;
      }
      // Apply values
      setEmptyListSearch(false);
      setProductsToAddToList([]);
      setLists(data.lists);
      // Select a list
      if (nonIdempotent) {
        const firstList = data.lists[0];
        setQueryParam({ ...queryParam, id: firstList.id });
        applySelectedList(firstList, 0);
        return;
      }
      // set current list by queryParam
      const index = findListByQueryParam(data.lists);
      if (index !== -1) {
        applySelectedList(data.lists[index], index);
        return;
      }
      if (isSmallScreen && initial) {
        setMobileDrawerOpen(true);
      } else {
        applySelectedList(data.lists[0], 0);
      }
    };
  }
  // 🟤 Cb - Sort lists
  const sortLists = (lists: List[]) => {
    setLists(lists);
    sortListCall({ lists }).catch((error) => {
      console.error({ error });
      toast({ message: t('lists.updateError'), kind: 'error' });
    });
  };
  // 🟤 Cb - Sort list line item
  const sortListLineItem = (products: ListProduct[]) => {
    if (!selectedList) {
      return;
    }
    setListProducts(products);
    const simplfiedProducts = products.map<ProductBasicInfo>(
      ({ productId, quantity }) => ({ productId, quantity })
    );
    callUpdateList(selectedList.list.id, {
      name: selectedList.list.name,
      description: selectedList.list.description ?? undefined,
      products: simplfiedProducts
    });
  };

  /**
   * Effects
   */
  // 🟡 Effect - Refresh on bill-to account change
  useEffect(() => {
    if (listsCalled) {
      listsRefetch();
      setListRefetchCalled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccounts.billTo?.id]);
  // 🟡 Effect - Refresh on branch change
  useEffect(() => {
    // Call get product pricing when branch changes
    listProducts.length &&
      selectedList?.list.id &&
      productsPricingCalled &&
      !listRefetchCalled &&
      handleGetProductPricing(selectedList?.list.id);

    // If account has changed only change flag to allow call
    listRefetchCalled && setListRefetchCalled(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippingBranch?.branchId]);

  /**
   * Render
   */
  return (
    <ListsPageContext.Provider
      value={{
        lists,
        setLists,
        listsLoading,
        selectedList,
        selectedListLoading,
        addProductsToListLoading,
        updateListLoading,
        createListLoading,
        duplicateListLoading,
        fileLoading,
        setFileLoading,
        listProducts,
        filteredListProducts,
        setListProducts,
        searchAllListsValue,
        setSearchAllListsValue,
        emptyListSearch,
        openCreateFormDialog,
        setOpenCreateFormDialog,
        productsToAddToList,
        setProductsToAddToList,
        applySelectedList,
        productsPricing,
        pricingLoading,
        handleGetProductPricing,
        callCreateList,
        callDeleteList,
        callDuplicateList,
        callPrintList,
        callPrintBarcodeList,
        callAddProductsToList,
        callUpdateList,
        callDownloadListCSV,
        callSearchList,
        callDeleteProductList,
        listsRefetch,
        selectedLineItems,
        setSelectedLineItems,
        toggleSelectedLineItem,
        findProductPricing,
        searchListValue,
        setSearchListValue,
        sortLists,
        sortListLineItem,
        mobileDrawerOpen,
        setMobileDrawerOpen,
        searchLoading,
        pricingError
      }}
    >
      {children}
    </ListsPageContext.Provider>
  );
}

export default ListsPageProvider;
