import { Container, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';
import CategoriesButtonWrapper from 'Categories/CategoriesButtonWrapper';
import DesktopSubheaderMenu from 'common/OldHeader/sub/DesktopSubheaderMenu';
import SwitchAccounts from 'common/OldHeader/sub/SwitchAccounts';
import { Link } from 'components';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { MaxIcon } from 'icons';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Component
 */
function DesktopSubheader() {
  /**
   * Hooks
   */
  const { t } = useTranslation();
  const { isWaterworks } = useDomainInfo();

  /**
   * Context
   */
  const { profile } = useAuthContext();
  const { isMincron } = useSelectedAccountsContext();

  /**
   * Render
   */
  return (
    <Toolbar disableGutters className="min-h-14">
      <Container maxWidth="lg" data-testid="header-desktopsubheader-container">
        <div className="flex items-center">
          {/* Browse Product */}
          {!isWaterworks && !isMincron && (
            // <div> is needed here since CategoriesButtonWrapper has `flex-1`
            <div>
              <CategoriesButtonWrapper />
            </div>
          )}
          {/* Brands */}
          {!isWaterworks && !isMincron && (
            <Link
              className="block mr-2 px-3 py-1 text-primary-3-100"
              to="/brands"
              data-testid="nav-brands"
            >
              {t('common.brands')}
            </Link>
          )}
          {/* MAX */}
          <DesktopSubheaderMenu item="max">
            <MaxIcon className="!w-[45px] !h-[15px]" />
          </DesktopSubheaderMenu>
          {/* Resources */}
          <DesktopSubheaderMenu item="resources">
            {t('common.resources')}
          </DesktopSubheaderMenu>
          {Boolean(profile?.userId) && (
            <div
              className="ml-auto -mr-2"
              data-testid="header-desktopsubheader-switchaccount"
            >
              <SwitchAccounts />
            </div>
          )}
        </div>
      </Container>
    </Toolbar>
  );
}

export default DesktopSubheader;
