import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import {
  GetLastSelectedAccountsRequest,
  GetBannerRequest,
  GetLastSelectedAccountsResponse,
  PutLastSelectedAccountsResponse,
  GetBannerResponse
} from 'API/types/user.types';

/**
 * Config
 */
const BASE_URL = 'user';

/**
 * Hooks
 */
// 🟦 GET/user/last-selected-accounts - Get last or default selected billTo or ShipTo
export function useApiGetLastSelectedAccounts(
  options: APIOptions<GetLastSelectedAccountsResponse> = {}
) {
  const apiProps: UseAPILazyProps<GetLastSelectedAccountsResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  // API
  const api = useApiLazyAuth<GetLastSelectedAccountsResponse>(apiProps);
  const call = async () => {
    return await api.call({
      ...apiProps,
      url: `${BASE_URL}/last-selected-accounts`
    });
  };
  return { ...api, call };
}

// 🟧 PUT /last-selected-accounts - Update selected billTo and shipTo
export function useApiPutLastSelectedAccounts(
  options: APIOptions<PutLastSelectedAccountsResponse> = {}
) {
  const props: UseAPILazyProps<PutLastSelectedAccountsResponse> = {
    url: '',
    kind: 'put',
    options,
    header: {}
  };
  const api = useApiLazyAuth<PutLastSelectedAccountsResponse>(props);
  const call = async (body: GetLastSelectedAccountsRequest) =>
    await api.call({
      ...props,
      url: `${BASE_URL}/last-selected-accounts`,
      body
    });
  return { ...api, call };
}

// 🟦 GET/user/banner - Get the flag for show or hide the banner in homepage
export function useApiGetBannerFlag(
  options: APIOptions<GetBannerResponse> = {},
  params: GetBannerRequest
) {
  const apiProps: UseAPILazyProps<GetBannerResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  // API
  const api = useApiLazyAuth<GetBannerResponse>(apiProps);
  const call = async () => {
    return await api.call({
      ...apiProps,
      url: `${BASE_URL}/banner/${params.msgType}`
    });
  };
  return { ...api, call };
}
