import { Alert, styled, Typography } from '@dialexa/reece-component-library';

export const PricingInfoTypography = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '24px',
  display: 'flex',
  alignItems: 'center',
  letterSpacing: '0.005em',
  color: theme.palette.primary02.main
}));

export const StyledAlert = styled(Alert)(({ theme }) => ({
  display: 'flex',
  height: 75,
  alignItems: 'center',
  color: 'primary02.main',
  border: `1px solid ${theme.palette.primary02.main}`,
  '& .MuiAlert-message': {
    color: 'primary02.main',
    p: 0
  },
  '& .MuiAlert-icon': {
    color: 'primary02.main'
  },
  '& a': {
    fontWeight: 500,
    color: 'primary02.main',
    '&:hover': {
      color: 'primary.main',
      cursor: 'pointer'
    }
  }
}));
