import { useEffect, useMemo, useState } from 'react';

import { DateRange as DateRangeType } from '@dialexa/reece-component-library';
import { differenceInCalendarDays, isSameDay, subMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DateRange from 'common/DateRange';
import TablePageLayout from 'common/TablePageLayout';
import TableRenderer from 'common/TablePageLayout/TableRenderer';
import {
  ErpSystemEnum,
  KOrder,
  KOrdersQueryVariables,
  Order,
  useKOrdersLazyQuery
} from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { KOrderPagination, useOrdersQueryParam } from 'KOrders/util/queryParam';
import 'Orders/util/styles.scss';
import { useOrdersTable } from 'KOrders/util/table';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import SearchCard from 'common/SearchCard';
import { formatDate } from 'utils/dates';
import { encryptData } from 'utils/encrypt';
import trimSpaces from 'utils/trimSpaces';

/**
 * TODO
 * [ ] Sort in collapsed
 */

/**
 * Const
 */
export const defaultRange = {
  from: subMonths(new Date(), 1),
  to: new Date()
};

/**
 * Component
 */
export default function KOrders() {
  /**
   * Custom hooks
   */
  const [queryParam, setQueryParams] = useOrdersQueryParam();
  const { searchBy, from, to, page, sortBy } = queryParam;
  const { t } = useTranslation();
  useDocumentTitle(t('common.myOrders'));

  /**
   * Context
   */
  const { selectedAccounts, isEclipse } = useSelectedAccountsContext();
  const erpName = selectedAccounts?.erpSystemName ?? ErpSystemEnum.Eclipse;
  const accountKind = isEclipse ? 'shipTo' : 'billTo';
  const accountId = selectedAccounts?.[accountKind]?.erpAccountId ?? '';

  /**
   * State
   */
  const initialRange: DateRangeType = {
    from: new Date(from),
    to: new Date(to)
  };
  const [range, setRange] = useState<DateRangeType>(initialRange);
  const [searchValue, setSearchValue] = useState(searchBy);
  const [paginationData, setPaginationData] = useState<KOrderPagination>({
    totalItems: 0,
    totalPages: 0
  });
  const [kOrders, setKOrders] = useState<KOrder[]>([]);
  const [customPageNumber, setCustomPageNumber] = useState<number>(
    parseInt(page)
  );

  /**
   * Data
   */
  const [
    getKOrders,
    { data: kOrdersQuery, loading: kOrdersLoading, called: KOrderCalled }
  ] = useKOrdersLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: () => {
      if (kOrdersQuery?.kOrders.orders) {
        getKOrdersData(
          kOrdersQuery.kOrders.orders,
          kOrdersQuery.kOrders.totalItems ?? 1
        );
      }
    }
  });

  /**
   * Memo
   */
  // These has to be memorized to keep React Tables stable or it will result in infinite re-rendering
  // 🔵 Memo - Encrypted ShipTo and BillTo
  const encryptedAccountId = useMemo(() => encryptData(accountId), [accountId]);

  const data = useMemo(getOrdersOrEmpty, [kOrders]);

  const warningMessage = useMemo(() => {
    const differenceInDays = differenceInCalendarDays(range.to!, range.from!);
    if (
      isNaN(differenceInDays) ||
      (isSameDay(range.to!, new Date()) &&
        isSameDay(range.from!, subMonths(new Date(), 1)))
    ) {
      return;
    }
    if (differenceInDays > 30) {
      return 'warning';
    }
    return '';
  }, [range]);

  /**
   * Table
   */
  const tableInstance = useOrdersTable(
    data,
    !kOrdersLoading && KOrderCalled,
    true
  );

  /**
   * Effect
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callOnLoad, [accountId, KOrderCalled, erpName]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(syncQueryParamEffect, [
    page,
    sortBy,
    tableInstance,
    customPageNumber
  ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callGetOrders, [customPageNumber]);

  const getKOrdersData = (orders: KOrder[], pagination: number) => {
    setPaginationData({
      totalItems: pagination ?? 0,
      totalPages: Math.ceil((pagination ?? 0) / 10)
    });
    setKOrders(orders);
  };

  /**
   * Render
   */
  return (
    <DateRange value={range} onChange={setRange} onClear={resetRange}>
      <TablePageLayout
        pageTitle={t('common.orders')}
        filters={
          <SearchCard
            placeholder={
              isEclipse
                ? t('orders.orderSearchPlaceholder')
                : t('orders.orderSearchPlaceholderWaterworks')
            }
            search={searchValue}
            setSearch={setSearchValue}
            onReset={onReset}
            onViewResultsClicked={onViewResults}
            resultsCount={paginationData?.totalItems}
            testId="search-orders-input"
            ordersPage
            warningMessage={warningMessage}
          />
        }
        table={
          <TableRenderer
            hasGroups={isEclipse}
            loading={kOrdersLoading}
            resultsCount={tableInstance.rows.length}
            noResultsMessage={
              isEclipse ? t('orders.noOrders') : t('orders.noOrdersWaterworks')
            }
            resultsCountText={t('common.orders').toLowerCase()}
            tableInstance={tableInstance}
            testId="orders-table"
            isWaterworks={!isEclipse}
            noResultsContactMessage={t('orders.noOrdersContactMessage')}
            noResultsContactBranch={t('orders.noOrdersContactBranch')}
            primaryKey="orderNumber"
            customPreviousPage={customPreviousPage}
            customNextPage={customNextPage}
            customPageCount={paginationData.totalPages}
            customTotalItems={paginationData?.totalItems}
            customPageNumber={customPageNumber}
            customGoToPage={customGoToPage}
          />
        }
      />
    </DateRange>
  );

  /**
   * Callbacks
   */
  // 🟤 CB - Reset Search
  function onReset() {
    resetRange();

    setSearchValue('');
    setCustomPageNumber(1);
    tableInstance.setGlobalFilter(undefined);
    tableInstance.gotoPage(0);

    const myDates = {
      from: formatDate(defaultRange.from),
      to: formatDate(defaultRange.to)
    };

    setQueryParams({ searchBy: '', ...myDates, page: '1' });

    callGetOrders({
      startDate: myDates.from,
      endDate: myDates.to,
      searchBy: '',
      page: 1
    });
  }

  // 🟤 CB - Apply Search Result
  function onViewResults() {
    const trimmedSearchValue = trimSpaces(searchValue);
    setSearchValue(trimmedSearchValue);
    setCustomPageNumber(1);

    const myDates = { from: formatDate(range.from), to: formatDate(range.to) };

    setQueryParams({ searchBy: trimmedSearchValue, ...myDates, page: '1' });

    tableInstance.setGlobalFilter(trimmedSearchValue);
    tableInstance.gotoPage(0);

    callGetOrders({
      startDate: myDates.from,
      endDate: myDates.to,
      searchBy: trimmedSearchValue,
      page: 1
    });
  }
  //Funtion to convert KOrders in Orders
  function kOrdersToOrders(kOrders: KOrder[]): Order[] {
    return kOrders.map((korder) => ({
      amount: `${korder.orderTotal}`,
      customerPO: korder.customerPO,
      invoiceNumber: null,
      jobNumber: null,
      orderDate: korder.orderDate,
      orderNumber: korder.orderNumber,
      orderStatus: korder.orderStatus,
      orderTotal: korder.orderTotal,
      shipAddress: null,
      shipDate: korder.shipDate,
      shipToName: korder.orderedBy,
      webStatus: korder.webStatus
    }));
  }

  /**
   * Memos
   */
  // 🔵 Memo -  Assemble orders GQL res data (needed to prevent unneeded re-renders which cause react-tables to re-render and freeze)
  function getOrdersOrEmpty(): Order[] {
    return kOrdersToOrders(kOrders);
  }

  /**
   * Effect defs
   */
  // 🟡 Effect - call getOrders when components is mounted and selected variables are truthy
  function callOnLoad() {
    if (accountId && erpName && !KOrderCalled) {
      callGetOrders();
    }
  }
  // 🟡 Effect - Sync queryParam effect
  function syncQueryParamEffect() {
    const tableSortString = tableInstance.state.sortBy.map(
      // Note - some instances were ignored since React Table is nearly impossible to mock/test
      // istanbul ignore next
      (s) => (s.desc ? '!' : '') + s.id
    );
    const matchSortBy =
      tableSortString.join('|') !== sortBy.join('|') &&
      // istanbul ignore next
      tableSortString.length;

    // istanbul ignore next
    if (matchSortBy || customPageNumber !== parseInt(page)) {
      setQueryParams({
        page: customPageNumber.toString(),
        sortBy: tableSortString
      });
    }
  }

  /**
   * Util
   */
  // 🔣 util - reset date range
  function resetRange() {
    setRange({ ...defaultRange });
  }
  // 🔣 util - call getOrders with pre-arranged variabled
  function callGetOrders(override: Partial<KOrdersQueryVariables> = {}) {
    if (searchBy !== '') {
      setCustomPageNumber(1);
    }
    const variables = {
      accountId: encryptedAccountId,
      startDate: formatDate(from),
      endDate: formatDate(to),
      erpName,
      page: customPageNumber,
      searchBy,
      ...override
    };
    getKOrders({ variables });
  }

  // Pagination custom functions
  function customPreviousPage() {
    setCustomPageNumber((prevPageNumber) => prevPageNumber - 1);
  }

  function customNextPage() {
    setCustomPageNumber((prevPageNumber) => prevPageNumber + 1);
  }

  function customGoToPage(pageIndex: number) {
    setCustomPageNumber(pageIndex);
  }
}
