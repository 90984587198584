import { useTranslation } from 'react-i18next';

import { OrderSummary } from 'API/types/orders.types';
import { useAuthContext } from 'AuthProvider';
import { Button } from 'components';
import { Maybe, Order, ProductPricing } from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useCartContext } from 'providers/CartProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useToastContext } from 'providers/ToastProvider';
import addItemsToCartCb from 'Order/utils/addItemsToCartCb';
import { trackReorderAllFromNewOrdersDetailsPage } from 'utils/analytics';

/** Types Order and ProductPricing on some sections of the new orders work are using generated/graphql to be able to reuse components
 * that are still needed, specially for the <ProductCard> component that will be added in ticket MAX-7077. This also affects MAX-7216.
 * Will be adressed with a more updated code in the future to not depend on these versions.
 */

export type OrderDetailsSummaryProps = {
  content?: OrderSummary;
  customerPO?: Maybe<string>;
  productOrder: Order;
  productPricing: ProductPricing[];
};

function OrderDetailsSummary(props: OrderDetailsSummaryProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { addItemsToCart, cartLoading, disableAddToCart } = useCartContext();

  const shipping = Number(props.content?.shipping) || 0;
  const handling = Number(props.content?.handling) || 0;
  const shippingAndHandling = shipping + handling;
  const { toast } = useToastContext();

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const billToAccountId = selectedAccounts.billToErpAccount?.erpAccountId ?? '';
  const shipToAccountId = selectedAccounts.shipToErpAccount?.erpAccountId ?? '';
  const { user } = useAuthContext();
  const firstName = user?.firstName ?? '';
  const lastName = user?.lastName ?? '';
  const phoneNumber = user?.phoneNumber ?? '';
  const email = user?.email ?? '';
  const { isWaterworks } = useDomainInfo();
  const division = !isWaterworks ? 'PHVAC' : 'Waterworks';
  const role = user?.role ?? '';
  const customerId = user?.customerId ?? '';
  const homeBranch = user?.homeBranch ?? '';
  const creditOrRebill =
    props.customerPO === 'credit' ||
    (Number(props.content?.subTotal) || 0) <= 0;

  /**
   * Callbacks
   */
  const handleAddItemsToCartCb = () => {
    addItemsToCartCb({
      order: props.productOrder,
      toast,
      t,
      addItemsToCart,
      pricingData: props.productPricing
    });
    trackReorderAllFromNewOrdersDetailsPage({
      billTo: billToAccountId,
      shipTo: shipToAccountId,
      userEmail: email,
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      email: email,
      division: division,
      role: role,
      customerId: customerId,
      homeBranch: homeBranch,
      orderNumber: props.productOrder.orderNumber,
      products:
        props.productOrder?.lineItems?.map(
          (item) =>
            `Product ID: ${item.productId}, Quantity: ${item.orderQuantity}`
        ) ?? []
    });
  };

  /**
   * Render
   */
  return (
    <div
      className="bg-common-white rounded-md shadow-surround pt-4 pb-8"
      data-testid="order-summary"
    >
      <div className="px-6 py-3">
        <p
          className="text-left text-primary-1-100 font-bold text-xl"
          data-testid="order-summary-text"
        >
          {t('cart.orderSummary')}
        </p>
        <hr className="h-px my-2 px-5 bg-gray-200"></hr>
      </div>
      <div className="text-base grid gap-y-2 px-6">
        <div className="bg-primary-2-5 rounded-md p-4">
          <p className="text-primary-1-100 flex justify-between">
            <span className="font-bold">{t('cart.subtotal')}</span>
            <span data-testid="order-subtotal-value">
              ${props.content?.subTotal}
            </span>
          </p>
          <p className="text-primary-1-100 flex justify-between">
            <span>{t('common.tax')}</span>
            <span data-testid="order-tax-value">${props.content?.tax}</span>
          </p>
          <p className="text-primary-1-100 flex justify-between">
            <span>{t('cart.shippingHandling')}</span>
            <span data-testid="order-shipping-value">
              {shippingAndHandling ? `$${shippingAndHandling}` : t('cart.free')}
            </span>
          </p>
          <hr className="h-px my-2 bg-gray-200 border-0"></hr>
          <p className="text-primary-1-100 flex font-bold text-xl justify-between">
            <span>{t('cart.orderTotal')}</span>
            <span data-testid="order-total-value">
              ${props.content?.orderTotal}
            </span>
          </p>
        </div>
        <Button
          className="p-2 mt-5 rounded-sm bg-primary-1-100 w-full"
          disabled={cartLoading || disableAddToCart || creditOrRebill}
          onClick={handleAddItemsToCartCb}
          data-testid="order-add-all-button"
        >
          <span
            className="leading-5 text-sm font-semibold"
            data-testid="order-add-all-text"
          >
            {t('orders.addAll')}
          </span>
        </Button>
      </div>
    </div>
  );
}
export default OrderDetailsSummary;
