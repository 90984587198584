import { useContext, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Skeleton,
  Typography
} from '@dialexa/reece-component-library';
import { ArrowDropDownIcon } from 'icons';
import { findIndex, isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import VirtualizedList from 'common/VirtualizedList';
import {
  Maybe,
  ProductAttribute,
  AggregationItem,
  AggregateDisplay
} from 'generated/graphql';
import SearchSubfilter from 'Search/SearchSubfilter';
import { BranchContext } from 'providers/BranchProvider';

type Props = {
  index: number;
  expanded: boolean;
  filter: Maybe<AggregateDisplay> | undefined;
  subfilters: Maybe<AggregationItem>[];
  loading: boolean;
  skeletonTotal: number;
  selectedFilters: {
    attributeType: string;
    attributeValue: string;
  }[];
  handleToggle: (changedFilter: ProductAttribute) => void;
};

function SearchFilterList(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { shippingBranch, shippingBranchLoading } = useContext(BranchContext);

  /**
   * State
   */
  const [isExpanded, setIsExpanded] = useState<boolean>();
  const stockDisabled =
    !!shippingBranch?.isPricingOnly &&
    props.filter?.filterKey === ' in_stock_location';
  /**
   * Data
   */
  const stockText =
    props.filter?.filterKey === 'in_stock_location'
      ? `${t('search.inStockAt')} ${shippingBranch?.name ?? ''}`
      : '';
  /**
   * Effects
   */
  useEffect(() => {
    if (
      props.filter?.filterKey === 'in_stock_location' &&
      !props.subfilters.length
    ) {
      props.subfilters.push({ count: 0, value: stockText });
    }
  }, [props.filter, props.subfilters, stockText]);
  /**
   * Render
   */
  return (
    <Accordion
      key={props.filter?.filterKey}
      type="filter"
      onChange={(_event, isExpanded) => setIsExpanded(isExpanded)}
      expanded={isExpanded ?? props.expanded}
      data-testid={`search-filter-list-${props.filter?.filterKey}`}
    >
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="filter-content"
        id="filter-header"
      >
        {stockDisabled ? (
          <Typography color="primary03.main">
            {props.filter?.filterName}
          </Typography>
        ) : (
          <Typography>{props.filter?.filterName}</Typography>
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          flexDirection: 'column',
          px: 1,
          pb: 3
        }}
      >
        {props.loading &&
          Array(props.skeletonTotal).map((_, i) => (
            <Skeleton
              key={i}
              variant="rectangular"
              width="100%"
              height={32}
              sx={{ mb: 1 }}
            />
          ))}

        {!props.loading && (
          <VirtualizedList
            key={props.subfilters.length}
            defaultItemSize={40}
            maxHeight={200}
            dataArray={props.subfilters}
            renderItem={(subfilter, idx) => (
              <SearchSubfilter
                key={idx}
                filter={props.filter}
                subfilter={subfilter ?? { count: 0, value: stockText }}
                checked={getChecked(idx)}
                handleToggle={props.handleToggle}
                loading={shippingBranchLoading}
                stockText={stockText}
                filterDisabled={stockDisabled}
              />
            )}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );

  /**
   * Callback defs
   */
  function getChecked(index: number) {
    return (
      findIndex(props.selectedFilters, (currentFilter) =>
        isEqual(currentFilter, {
          attributeType: props.filter?.filterKey?.replace(/s$/, ''),
          attributeValue: props.subfilters?.[index]?.value
        })
      ) !== -1
    );
  }
}

export default SearchFilterList;
