import { useMemo } from 'react';

import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from 'AuthProvider';
import { Button, CircularProgress } from 'components';
import { BranchIcon, WarningIcon } from 'icons';
import { useBranchContext } from 'providers/BranchProvider';

/**
 * Config
 */
const iconProps = { className: 'text-primary-1-100', width: 20, height: 20 };

/**
 * Component
 */
function OldBranchButton() {
  /**
   * Custom hooks
   */
  const history = useHistory();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const {
    homeBranchError,
    shippingBranch,
    shippingBranchLoading,
    homeBranchLoading,
    setBranchSelectOpen
  } = useBranchContext();
  const loading = shippingBranchLoading || homeBranchLoading;

  /**
   * Memo
   */
  // 🔵 memo - Button start icon
  const startIcon = useMemo(
    () => (
      <div className="relative">
        {homeBranchError && !shippingBranch ? (
          <WarningIcon
            {...iconProps}
            data-testid="old-branch-button-icon-warning"
          />
        ) : (
          <BranchIcon
            {...iconProps}
            data-testid="old-branch-button-icon-normal"
          />
        )}
        {loading && (
          <div className="absolute -top-1 -left-1">
            <CircularProgress
              size={30}
              testId="old-branch-button-icon-loading"
            />
          </div>
        )}
      </div>
    ),
    [homeBranchError, loading, shippingBranch]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - button click
  const onButtonClick = () => {
    if (authState?.isAuthenticated) {
      setBranchSelectOpen(true);
      return;
    }
    history.push('/login');
  };

  /**
   * Render
   */
  return (
    <Button
      color="gray"
      disabled={loading}
      iconStart={startIcon}
      kind="text"
      onClick={onButtonClick}
      data-testid="change-old-branch-button"
    >
      {authState?.isAuthenticated ? (
        <div className="max-w-[100px] overflow-hidden text-base text-primary-2-100">
          {shippingBranchLoading ? (
            <span data-testid="change-old-branch-button-loading">
              <Skeleton width={92} />
              <Skeleton width={80} />
            </span>
          ) : (
            <span
              data-testid="change-old-branch-button-text"
              className="text-left"
            >
              <p
                className="text-sm font-normal truncate"
                data-testid="change-old-branch-button-text-name"
              >
                {shippingBranch?.name}
              </p>
              <p
                className="text-sm font-normal truncate"
                data-testid="change-old-branch-button-text-location"
              >
                {shippingBranch?.city}
                {shippingBranch?.city && shippingBranch?.state
                  ? `, ${shippingBranch?.state}`
                  : ''}
              </p>
            </span>
          )}
        </div>
      ) : (
        t('common.signInToShop')
      )}
    </Button>
  );
}

export default OldBranchButton;
