import clsx from 'clsx';

import { List } from 'API/types/lists.types';
import { CheckboxInput } from 'components';

/**
 * Types
 */
type ListDrawerItemProps = {
  list: List;
  selected: boolean;
  disabled?: boolean;
  onClick: () => void;
};

/**
 * Component
 */
function ListDrawerItem(props: ListDrawerItemProps) {
  /**
   * Callbacks
   */
  const onClick = () => !props.disabled && props.onClick();

  /**
   * Render
   */
  return (
    <div
      className={clsx('w-full p-3 flex gap-3 select-none', {
        'cursor-pointer': !props.disabled,
        'bg-primary-2-100/10': props.selected && !props.disabled,
        'bg-secondary-2-100/10': props.selected && props.disabled
      })}
      data-testid={`list-item-${props.list.id}`}
      onClick={onClick}
    >
      <CheckboxInput
        color="lightBlue"
        checked={props.selected}
        disabled={props.disabled}
      />
      <span
        className={clsx('text-primary-3-100 text-base truncate', {
          'text-secondary-3-100': props.disabled
        })}
      >
        {props.list.name}
      </span>
    </div>
  );
}

export default ListDrawerItem;
