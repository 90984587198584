import { useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  AddProductsToListRequest,
  ProductBasicInfo
} from 'API/types/lists.types';
import {
  Button,
  FormAutocompleteInput,
  FormAutocompleteOption,
  Modal
} from 'components';
import { AddIcon } from 'icons';
import useScreenSize from 'hooks/useScreenSize';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Types
 */
export type ListCopyDialogProps = {
  open: boolean;
  onClose: () => void;
};

type CopyFormValues = {
  selectedListId: string;
};

/**
 * Component
 */
function ListCopyDialog(props: ListCopyDialogProps) {
  /**
   * Props
   */
  const { open, onClose } = props;

  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const { toast } = useToastContext();
  const {
    addProductsToListLoading,
    callAddProductsToList,
    lists,
    listProducts,
    selectedLineItems,
    selectedList,
    setLists,
    setOpenCreateFormDialog,
    setProductsToAddToList
  } = useListsPageContext();
  const resolver = yupResolver(
    yup.object({
      selectedListId: yup.string().required(t('validation.nameRequired'))
    })
  );

  const labelText = t('lists.copyItem');
  const [buttonText, setButtonText] = useState(labelText);

  const { control, handleSubmit } = useForm<CopyFormValues>({
    resolver,
    mode: 'onTouched',
    reValidateMode: 'onSubmit'
  });

  /**
   * Memos
   */
  // 🔵 memo - list options
  const listOptions = useMemo(
    () =>
      lists.reduce<FormAutocompleteOption[]>((previous, current) => {
        current.id !== selectedList?.list.id &&
          previous.push({ label: current.name, value: current.id });
        return previous;
      }, []),
    [lists, selectedList]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - Copy products to selected list
  const copyProductsToList: SubmitHandler<CopyFormValues> = async (data) => {
    // Close first so that the copying is done in the background
    onClose();
    setButtonText(buttonText === labelText ? t('common.copying') : labelText);
    const products = createProductsObject();

    const requestBody: AddProductsToListRequest = {
      listId: [data.selectedListId],
      products
    };
    const response = await callAddProductsToList(requestBody).catch(() =>
      toast({ message: t('lists.productsNotCopied'), kind: 'error' })
    );
    setButtonText(labelText);

    // Post-API logics
    if (!response) {
      return;
    }
    setLists(response);
    const copiedListIndex = response.findIndex(
      (list) => list.id === data.selectedListId
    );
    toast({
      message: t('lists.productCopied', {
        count: products.length,
        listName: response[copiedListIndex].name
      }),
      kind: 'success'
    });
  };

  // 🟤 Cb - Open create modal with products array
  const handleOpenCreateDialog = () => {
    onClose();
    setOpenCreateFormDialog(true);
    setProductsToAddToList(createProductsObject());
  };

  // 🟤 Cb - Create product object from selected items
  const createProductsObject = () => {
    // process selected line items each to copy to list
    const products = Object.entries(selectedLineItems).reduce<
      ProductBasicInfo[]
    >((prev, [productId]) => {
      // process data
      const product = listProducts.find((item) => item.productId === productId);
      const quantity = product?.quantity || product?.minIncrementQty || 1;
      const newItem = { productId, quantity };
      return [...prev, newItem];
    }, []);

    // Create a map of productId to index in listProducts
    const indexMap = new Map(
      listProducts.map((item, index) => [item.productId, index])
    );

    // Sort products based on the index positions in listProducts
    return products.sort((a, b) => {
      const indexA = indexMap.get(a.productId) ?? -1;
      const indexB = indexMap.get(b.productId) ?? -1;
      return indexA - indexB;
    });
  };

  /**
   * Render
   */
  return (
    <Modal
      open={open}
      onClose={onClose}
      width={isSmallScreen ? 'xs' : 'sm'}
      testId="list-copy-dialog"
      headerContent={
        <h5 className="text-primary-3-100 text-xl font-medium">
          {t(isSmallScreen ? 'lists.copyItem' : 'lists.copyProductToList')}
        </h5>
      }
    >
      {/* List to copy dropdown */}
      <form onSubmit={handleSubmit(copyProductsToList)}>
        <div className="my-6 flex justify-center">
          <FormAutocompleteInput
            label={t('lists.selectListToCopy')}
            control={control}
            options={listOptions}
            name="selectedListId"
            disablePortal
          />
        </div>

        {/* <>-------------------------<> SUBMIT BUTTONS <>-------------------------<> */}
        <div className="flex gap-x-8 gap-y-4 items-center transition-none !justify-end flex-col pb-4">
          <Button
            data-testid="list-copy-dialog-copy-button"
            key="btn-submit"
            type="submit"
            fullWidth
            disabled={
              addProductsToListLoading ||
              Object.keys(selectedLineItems).length === 0
            }
          >
            {buttonText}
          </Button>

          {/* OR Divider */}
          <div className="flex items-center w-full my-2">
            <div className="flex-grow border-t border-primary-3-20"></div>
            <span className="mx-2 text-secondary-3-100 uppercase">
              {t('common.or')}
            </span>
            <div className="flex-grow border-t border-primary-3-20"></div>
          </div>

          <Button
            data-testid="list-copy-dialog-create-button"
            type="button"
            onClick={handleOpenCreateDialog}
            kind="outline"
            fullWidth
            iconStart={<AddIcon />}
            disabled={
              addProductsToListLoading ||
              Object.keys(selectedLineItems).length === 0
            }
          >
            {t('lists.createNewList')}
          </Button>
        </div>
      </form>
    </Modal>
  );
}

export default ListCopyDialog;
