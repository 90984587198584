import { useMemo } from 'react';

import { Hidden } from '@mui/material';
import clsx from 'clsx';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation, Trans } from 'react-i18next';
import { FixedSizeList } from 'react-window';

import { Button, Loader } from 'components';
import { InfoOutlineIcon, LeftArrowBaseIcon } from 'icons';
import baseI18nComponents from 'locales/baseComponents';
import useScreenSize from 'hooks/useScreenSize';
import {
  ListSpecial,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import ListsSearch from 'pages/Lists/sub/ListsSearch';
import ListsSelectorItem from 'pages/Lists/sub/ListsSelectorItem';
import ListsSelectorItemRow from 'pages/Lists/sub/ListsSelectorItemRow';
import { reorder } from 'utils/reorder';

/**
 * Config
 */
const ITEM_SIZE = 73.2;
const MAX_SCROLLSIZE = 918;

/**
 * Component
 */
function ListsSelector() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const {
    emptyListSearch,
    lists,
    selectedList,
    listsLoading,
    searchAllListsValue,
    sortLists,
    mobileDrawerOpen,
    setMobileDrawerOpen,
    searchLoading
  } = useListsPageContext();
  const searchValue = searchAllListsValue;
  /**
   * Memo
   */
  const myLists = useMemo(
    () =>
      lists.map<ListSpecial>((list) => ({
        ...list,
        isDragDisabled: Boolean(searchAllListsValue)
      })),
    [lists, searchAllListsValue]
  );

  /**
   * Callbacks
   */
  const onDragEnd = (result: DropResult) => {
    // outside the list
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const adjustedItems = reorder(
      lists,
      result.source.index,
      result.destination.index
    );
    sortLists(adjustedItems);
  };

  /**
   * Render
   */
  return (
    <div
      className={clsx(
        'relative md:hidden md:ml-0 md:pt-2 min-h-full py-6 ml-2 transition-all basis-[30%]',
        { 'md:!block !basis-full': mobileDrawerOpen && isSmallScreen }
      )}
    >
      <Hidden mdUp>
        <Button
          type="button"
          data-testid="lists-selector-close-lists-panel"
          className="flex !pr-2 !pl-4 gap-2 mb-4 hover:bg-transparent !text-current bg-transparent font-bold text-md"
          onClick={() => setMobileDrawerOpen(false)}
        >
          <LeftArrowBaseIcon />
          {t('lists.hideListsPanel')}
        </Button>
      </Hidden>
      {/* ----- Search ----- */}
      <ListsSearch />
      {/* ----- Tip ----- */}
      <div
        data-testid="list-selector-tip"
        className="flex flex-1 text-xs text-secondary-2-70 items-center px-4 pt-2 pb-4 gap-2 md:px-2"
      >
        <InfoOutlineIcon className="!h-4" />
        <p>{t('lists.tipLists')}</p>
      </div>
      {/* ----- List title -or- search result ----- */}
      {!emptyListSearch &&
        (searchValue ? (
          <p
            className="px-4 pb-2 mb-4 md:px-2"
            data-testid="lists-selector-summary"
          >
            {!searchLoading ? (
              <Trans
                i18nKey="lists.listsSearchStatus"
                values={{
                  listCount: lists.length,
                  searchValue
                }}
                components={baseI18nComponents}
              />
            ) : (
              <Trans
                i18nKey="lists.listsSearchLoading"
                values={{
                  searchValue
                }}
                components={baseI18nComponents}
              />
            )}
          </p>
        ) : (
          <p
            className="ml-4 mb-4 font-normal text-lg"
            data-testid="lists-selector-list-count"
          >
            {`${t('common.lists')} 
            ${Number.isInteger(lists.length) && `(${lists.length})`}`}
          </p>
        ))}
      {/* ----- main area ----- */}
      {!emptyListSearch && (listsLoading || searchLoading) ? (
        <Loader />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="lists-selector"
            mode="virtual"
            renderClone={(provided, snapshot, rubric) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <ListsSelectorItem
                  isDragged={snapshot.isDragging}
                  list={lists[rubric.source.index]}
                  index={rubric.source.index}
                  selected={rubric.source.index === selectedList?.index}
                  testId={`lists-${rubric.source.index}-selector-item`}
                />
              </div>
            )}
          >
            {(provided) => (
              <FixedSizeList<ListSpecial[]>
                {...provided.droppableProps}
                height={Math.min(MAX_SCROLLSIZE, ITEM_SIZE * lists.length)}
                width="100%"
                overscanCount={5}
                itemSize={ITEM_SIZE}
                itemCount={lists.length}
                itemData={myLists}
                style={{ flex: 1, overflow: 'hidden auto', padding: 0 }}
                innerRef={provided.innerRef}
              >
                {ListsSelectorItemRow}
              </FixedSizeList>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </div>
  );
}

export default ListsSelector;
