import { ElementType } from 'react';

import {
  IconButton,
  InputAdornment,
  InputBaseComponentProps,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDateRangeContext } from 'common/DateRange';
import BaseMaskedInput from 'components/MaskedInput/BaseMaskedInput';
import { dateMask, dateRangeMask } from 'utils/masks';
import { DateRangeCalendarIcon } from 'icons';

// 🔴 Note - Due to the odd behaviors of IMaskedInput, we'll be using MUI Input instead
/**
 * Config
 */
const inputComponent =
  BaseMaskedInput as unknown as ElementType<InputBaseComponentProps>;

/**
 * Types
 */
export type DateRangeToFromInputsProps = {
  label?: string;
  invoiceStatus?: string;
  singleField?: boolean;
};

function DateRangeToFromInputs(props: DateRangeToFromInputsProps) {
  /**
   * Props
   */
  const { label, invoiceStatus, singleField } = props;
  const allOrClosed = invoiceStatus === 'All' || invoiceStatus === 'Closed';

  /**
   * Context
   */
  const {
    focused,
    fromProps,
    handleRangeSubmit,
    handleSubmit,
    month,
    rangeProps,
    setMonth,
    setOpen,
    toProps,
    value
  } = useDateRangeContext();

  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Callbacks
   */
  // 🟤 Cb - from focus
  const onFromFocus = () => {
    setMonth(value?.from ?? month ?? new Date());
    setOpen(true);
  };
  // 🟤 Cb - to focus
  const onToFocus = () => {
    setMonth(value?.to ?? month ?? new Date());
    setOpen(true);
  };
  // 🟤 Cb - range focus
  const onRangeFocus = () => {
    setMonth(value?.from ?? month ?? new Date());
    setOpen(true);
  };

  // 🟤 Cb - range focus
  const onCalendarClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    rangeProps?.onFocus(e);
    setMonth(value?.from ?? month ?? new Date());
    setOpen(true);
  };

  /**
   * Render
   */

  if (singleField) {
    return (
      <form
        className="flex-1"
        onSubmit={handleRangeSubmit}
        data-testid="from-to-form"
      >
        <TextField
          label={label ?? t('common.dateRange')}
          placeholder="from - to"
          name="from-to"
          fullWidth
          focused={focused === 'from' || focused === 'to'}
          onFocus={onRangeFocus}
          required={allOrClosed}
          helperText={
            allOrClosed && !value?.from ? t('common.fromDateRequired') : ''
          }
          FormHelperTextProps={{ className: 'text-support-1-100' }}
          inputProps={{
            className: '!py-[14.5px] !pr-0',
            'data-testid': 'range-from-to',
            ...dateRangeMask,
            ...rangeProps
          }}
          className="!py-1"
          InputLabelProps={{ className: '!text-sm' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  color="primary"
                  size="small"
                  data-testid="preferred-date-button"
                  onClick={onCalendarClick}
                >
                  <DateRangeCalendarIcon />
                </IconButton>
              </InputAdornment>
            ),
            inputComponent
          }}
        />
      </form>
    );
  }

  return (
    <>
      <form className="flex-1" onSubmit={handleSubmit} data-testid="from-form">
        <TextField
          label={label ?? t('common.dateRange')}
          placeholder="from"
          name="from"
          fullWidth
          focused={focused === 'from'}
          onFocus={onFromFocus}
          required={allOrClosed}
          helperText={
            allOrClosed && !value?.from ? t('common.fromDateRequired') : ''
          }
          FormHelperTextProps={{ className: 'text-support-1-100' }}
          inputProps={{
            className: '!py-[14.5px]',
            'data-testid': 'range-from',
            ...dateMask,
            ...fromProps
          }}
          className="!py-1"
          InputLabelProps={{ className: '!text-sm' }}
          InputProps={{ inputComponent }}
        />
      </form>
      <form className="flex-1" onSubmit={handleSubmit} data-testid="to-form">
        <TextField
          placeholder="to"
          name="to"
          fullWidth
          focused={focused === 'to'}
          onFocus={onToFocus}
          helperText={
            allOrClosed && !value?.to ? t('common.toDateRequired') : ''
          }
          FormHelperTextProps={{ className: 'text-support-1-100' }}
          inputProps={{
            className: '!py-[14.5px]',
            'data-testid': 'range-to',
            ...dateMask,
            ...toProps
          }}
          className="!py-1"
          InputLabelProps={{ className: '!text-sm' }}
          InputProps={{ inputComponent }}
        />
      </form>
    </>
  );
}

export default DateRangeToFromInputs;
