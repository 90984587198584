import { memo } from 'react';

import { ListChildComponentProps, areEqual } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';

import {
  ListProduct,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import ListLineItem from 'pages/Lists/sub/ListLineItem';

/**
 * Component
 */
const ListLineItemRow = memo(
  ({ data, index, style }: ListChildComponentProps<ListProduct[]>) => {
    /**
     * Props
     */
    const { isDragDisabled, ...item } = data[index];

    /**
     * Context
     */
    const { listProducts } = useListsPageContext();

    /**
     * Render
     */
    return (
      <div
        style={style}
        className="border-x border-b border-solid border-secondary-3-100"
      >
        <Draggable
          key={`line-item-drag-${item.productId}`}
          draggableId={`${item.productId}`}
          isDragDisabled={isDragDisabled}
          index={index}
        >
          {(provided, snapshot, rubric) => (
            <div
              key={`line-item-${item.productId}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="h-full select-none"
              style={provided.draggableProps.style}
              data-testid={`lists-line-item-row-${rubric.source.index}`}
            >
              <ListLineItem
                testId={`lists-line-item`}
                product={item}
                index={index}
                isLast={index >= listProducts.length - 1}
                isDragged={snapshot.isDragging}
                isDragDisabled={isDragDisabled}
                dragHandleProps={provided.dragHandleProps}
              />
            </div>
          )}
        </Draggable>
      </div>
    );
  },
  areEqual
);

export default ListLineItemRow;
