import { useCallback, useMemo } from 'react';

import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from 'AuthProvider';
import { Badge, Button, CircularProgress, IconButton } from 'components';
import { CartIcon } from 'icons';
import { useCartContext } from 'providers/CartProvider';

/**
 * Config
 */
export const navAlertState = { canShowNavAlert: true };

/**
 * Component
 */
function CartButton() {
  /**
   * Custom hooks
   */
  const { push } = useHistory();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState } = useAuthContext();
  const {
    cart,
    cartLoading,
    checkingOutWithQuote,
    contract,
    clearQuote,
    itemCount,
    itemLoading,
    refreshCart
  } = useCartContext();
  const isLoading = Boolean(cartLoading || itemLoading);

  /**
   * Memo
   */
  // 🔵 Memo - cart button content
  const cartButtonContent = useMemo(() => {
    return (
      <Badge
        showZero={false}
        badgeContent={itemCount}
        invisible={isLoading || !itemCount}
        max={999}
      >
        <CartIcon height={20} width={20} className="text-primary-1-100" />
        {isLoading && (
          <Hidden mdDown>
            <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <CircularProgress size="2rem" noBack />
            </div>
          </Hidden>
        )}
      </Badge>
    );
  }, [isLoading, itemCount]);

  /**
   * Callbacks
   */
  // 🟤 Cb - go to cart
  const goToCart = useCallback(() => {
    // consts
    const isAuth = authState?.isAuthenticated;
    // clear quote
    if (checkingOutWithQuote) {
      clearQuote();
    }
    // call to refresh pricing and availability data on cart when view cart
    if (cart?.products && !checkingOutWithQuote) {
      refreshCart?.();
    }
    // go to path
    push(isAuth ? '/cart' : '/login', isAuth && contract ? navAlertState : {});
  }, [
    authState?.isAuthenticated,
    cart?.products,
    checkingOutWithQuote,
    clearQuote,
    contract,
    push,
    refreshCart
  ]);

  /**
   * Render
   */
  return (
    <>
      <Hidden mdUp>
        <div className="relative ml-6">
          {isLoading && (
            <div className="flex absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <CircularProgress
                size="2rem"
                noBack
                testId="go-to-cart-button-loading"
              />
            </div>
          )}
          <IconButton
            onClick={goToCart}
            size="large"
            data-testid="go-to-cart-button-mobile"
          >
            {cartButtonContent}
          </IconButton>
        </div>
      </Hidden>
      <Hidden mdDown>
        <Button
          data-testid="go-to-cart-button-desktop"
          color="gray"
          iconStart={cartButtonContent}
          kind="text"
          onClick={goToCart}
          className="text-sm leading-[18px] py-2 !px-0 font-bold"
        >
          {t('common.cart')}
        </Button>
      </Hidden>
    </>
  );
}

export default CartButton;
