import { useContext } from 'react';

import { AuthContext } from 'AuthProvider';

import { useHistory } from 'react-router-dom';

import useScreenSize from 'hooks/useScreenSize';

import wpdBannerDesktop from 'images/togglePage/World-Plumbing-Day-Banner-Desktop.jpg';
import wpdBannerMobile from 'images/togglePage/World-Plumbing-Day-Banner-Mobile.jpg';

/**
 * Component
 */
function MessageBanner() {
  /**
   * Custom Hooks
   */
  const history = useHistory();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const { activeFeatures } = useContext(AuthContext);

  /**
   * Render
   */
  if (!activeFeatures?.includes('SHOW_BANNER')) {
    return null;
  }
  return (
    <header
      className="cursor-pointer bg-etc-lighter-gray"
      data-testid="message-banner"
      onClick={() => history.replace('/events/world-plumbing-day')}
    >
      <div className="flex">
        <div
          className="w-full h-[180px] bg-center bg-no-repeat bg-[contain,_cover] md:bg-[center_right_15%] md:bg-cover md:h-[275px]"
          style={{
            backgroundImage: `url(${
              isSmallScreen ? wpdBannerMobile : wpdBannerDesktop
            })`
          }}
          data-testid="wpd-banner"
        />
      </div>
    </header>
  );
}

export default MessageBanner;
