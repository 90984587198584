import { Container, Toolbar } from '@mui/material';
import { useAuthContext } from 'AuthProvider';

import SwitchAccounts from 'common/Header/sub/SwitchAccounts';
import { BranchButton } from 'common/Branches';

/**
 * Component
 */
function DesktopSubheader() {
  /**
   * Hooks
   */

  /**
   * Context
   */
  const { profile } = useAuthContext();

  /**
   * Render
   */

  return (
    <Toolbar disableGutters className="min-h-14">
      <Container maxWidth="lg" data-testid="header-desktopsubheader-container">
        <div className="flex items-center">
          {/*Branch */}
          <BranchButton data-testid="branch-button-desktop" />
          {Boolean(profile?.userId) && (
            <div
              className="ml-auto -mr-2"
              data-testid="header-desktopsubheader-switchaccount"
            >
              <SwitchAccounts />
            </div>
          )}
        </div>
      </Container>
    </Toolbar>
  );
}

export default DesktopSubheader;
