import { useRef } from 'react';

import { Card, ClickAwayListener, Grow, Hidden, Popper } from '@mui/material';
import { differenceInDays, subYears } from 'date-fns';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { useDateRangeContext } from 'common/DateRange';
import DateRangeToFromInputs from 'common/DateRange/sub/DateRangeToFromInputs';
import { Button, DayPicker } from 'components';
import useScreenSize from 'hooks/useScreenSize';

/**
 * Config
 */
const popperModifiers = [
  { name: 'flip', options: { rootBoundary: 'document', enabled: false } }
];

/**
 * Types
 */
export type DateRangeInputProps = {
  label?: string;
  invoiceStatus?: string;
  singleField?: boolean;
  testId?: string;
};

/**
 * Compoment
 */
function DateRangeInput(props: DateRangeInputProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const {
    handleDayClick,
    handleDone,
    handlePreset,
    modifiers,
    month,
    onClear,
    open,
    selected,
    setApplied,
    setFocused,
    setMonth,
    setOpen,
    value
  } = useDateRangeContext();

  const diffInDays = differenceInDays(value?.to!, value?.from!);
  const includesToday = !differenceInDays(value?.to!, new Date());

  /**
   * Refs
   */
  const containerEl = useRef<HTMLDivElement | null>(null);

  /**
   * Callbacks
   */
  // 🟤 Cb - clear
  const handleClear = () => {
    onClear!();
    setOpen(false);
    setApplied?.(false);
  };
  // 🟤 Cb - click away
  const handleClickAway = () => {
    setOpen(false);
    setFocused(null);
  };

  /**
   * Render
   */
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="flex gap-2" ref={containerEl}>
        <DateRangeToFromInputs
          label={props.label}
          invoiceStatus={props.invoiceStatus}
          singleField={props.singleField}
        />
        <Popper
          id={open ? 'orders-range' : undefined}
          open={open}
          anchorEl={containerEl.current}
          placement={
            props.singleField && !isSmallScreen ? 'bottom-start' : 'bottom'
          }
          modifiers={popperModifiers}
          transition
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Card className="pb-8">
                {props.singleField ? (
                  <>
                    <div
                      className={clsx({
                        flex: !isSmallScreen
                      })}
                    >
                      <div
                        className={clsx(
                          'min-w-[125px] mt-4 grid grid-cols-1 md:gap-2 z-10',
                          {
                            'mb-[175px]': !isSmallScreen
                          }
                        )}
                      >
                        <Button
                          kind="text"
                          onClick={() => handlePreset(0)}
                          data-testid="today"
                          className="col-span-1 !p-0 !justify-start !px-4"
                          size="sm"
                        >
                          <div
                            className={clsx('px-2 !text-primary-3-100', {
                              'py-1 rounded bg-primary-2-10':
                                diffInDays === 0 && includesToday
                            })}
                          >
                            {t('common.dateToday')}
                          </div>
                        </Button>

                        <Button
                          kind="text"
                          onClick={() => handlePreset(7)}
                          data-testid="last-seven-days"
                          className="col-span-1 !p-0 !justify-start !px-4"
                          size="sm"
                        >
                          <div
                            className={clsx('px-2 !text-primary-3-100', {
                              'py-1 rounded bg-primary-2-10':
                                diffInDays === 7 && includesToday
                            })}
                          >
                            {t('common.dateLastSevenDays')}
                          </div>
                        </Button>
                        <Button
                          kind="text"
                          size="sm"
                          onClick={() => handlePreset(30)}
                          data-testid="last-thirty-days"
                          className="col-span-1 !p-0 !justify-start !px-4"
                        >
                          <div
                            className={clsx('px-2 !text-primary-3-100', {
                              'py-1 rounded bg-primary-2-10':
                                diffInDays === 30 && includesToday
                            })}
                          >
                            {t('common.dateLastThirtyDays')}
                          </div>
                        </Button>
                      </div>
                      <div>
                        <DayPicker
                          mode="custom"
                          month={month}
                          modifiers={modifiers}
                          onMonthChange={setMonth}
                          onDayClick={handleDayClick}
                          selected={selected}
                          toDate={new Date()}
                          id="date-range"
                          testId={props.testId}
                          fromMonth={subYears(new Date(), 2)}
                          numberOfMonths={
                            props.singleField && !isSmallScreen ? 2 : 1
                          }
                          showOutsideDays={props.singleField ? false : true}
                        />
                      </div>
                    </div>
                    <div className="px-3">
                      <hr className="h-px my-2 px-5 bg-gray-200"></hr>
                    </div>
                    <div>
                      <div className="flex items-end justify-end -mb-6">
                        <Button
                          kind="outline"
                          onClick={handleClear}
                          data-testid="clear-date-range-button"
                          className="mr-4 border border-primary-2-100 text-primary-3-100 !rounded-[30px] py-1"
                        >
                          {t('common.reset')}
                        </Button>
                        <Button
                          kind="fill"
                          onClick={handleDone}
                          data-testid="date-range-done-button"
                          className="mr-4 border border-primary-2-100 !text-common-white !rounded-[30px] py-1"
                        >
                          {t('common.done')}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <DayPicker
                      mode="custom"
                      month={month}
                      modifiers={modifiers}
                      onMonthChange={setMonth}
                      onDayClick={handleDayClick}
                      selected={selected}
                      toDate={new Date()}
                      id="date-range"
                      testId={props.testId}
                    />
                    {Boolean(onClear) && (
                      <Hidden mdDown>
                        <div className="flex justify-end -mb-6">
                          <Button
                            kind="text"
                            onClick={handleClear}
                            data-testid="clear-date-range-button"
                          >
                            {t('common.clear')}
                          </Button>
                        </div>
                      </Hidden>
                    )}
                  </>
                )}
              </Card>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

export default DateRangeInput;
