import { AccordionDetails, AccordionSummary } from '@mui/material';
import { kebabCase } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Accordion, Button } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { ArrowDropDownIcon, TechnicalSpecsIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';

/**
 * Component
 */
function TechnicalDocuments() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Props
   */
  const { product } = useProductPageContext();
  const technicalDocuments = product?.technicalDocuments;

  /**
   * Render
   */
  // 🟩 Empty
  if (!technicalDocuments?.length) {
    return null;
  }
  // 🟩 Desktop
  if (!isSmallScreen) {
    return (
      <div
        className="pt-6 border-secondary-3-100 border-t"
        data-testid="pdp-techdocs-desktop"
      >
        <h5 className="text-primary-1-100 text-xl font-medium">
          {t('product.techDocs')}
        </h5>
        <div className="w-full py-4 flex flex-wrap">
          {product?.technicalDocuments?.map((doc, i) => (
            <div
              className="basic-1/2 grow-0 w-1/2"
              key={doc.name ?? `tech-doc-${i}`}
            >
              <a href={doc.url ?? ''} target="_blank" rel="noreferrer">
                <Button
                  kind="text"
                  data-testid={kebabCase(`${doc.name}-button`)}
                  className="!p-0"
                  iconStart={<TechnicalSpecsIcon />}
                >
                  {doc.name}
                </Button>
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  }
  // 🟩 Mobile
  return (
    <Accordion type="product" data-testid="pdp-techdocs-mobile">
      <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
        {t('product.techDocs')}
      </AccordionSummary>
      <AccordionDetails>
        <ul className="w-full list-none px-6 py-2">
          {technicalDocuments.map((doc, i) => (
            <a
              href={doc.url ?? ''}
              target="_blank"
              rel="noreferrer"
              key={doc.name ?? `tech-doc-${i}`}
            >
              <li className="flex justify-start items-center w-full py-4 border-secondary-3-100 hover:bg-secondary-4-100">
                <span className="min-w-[56px]">
                  <TechnicalSpecsIcon className="text-primary-1-100" />
                </span>
                <span className="text-primary-3-100 text-base font-medium">
                  {doc.name}
                </span>
              </li>
            </a>
          ))}
        </ul>
      </AccordionDetails>
    </Accordion>
  );
}
export default TechnicalDocuments;
