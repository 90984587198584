import { useCallback, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';
import { Permission } from 'common/PermissionRequired';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Types
 */
export type NavItem = {
  id: string;
  name: string;
  to: string;
  condition: boolean;
  comingSoon: boolean;
};
export type NavData = {
  max: NavItem[];
  profile: NavItem[];
  resources: NavItem[];
};

/**
 * Hook
 */
function useNavDataMemo() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isWaterworks } = useDomainInfo();

  /**
   * Context
   */
  const { authState, ecommUser, profile } = useAuthContext();
  const { isMincron } = useSelectedAccountsContext();
  const isWWOrMincron = isWaterworks || isMincron;
  const isInvoiceOnly = ecommUser?.role?.name !== t('roles.invoiceOnly');
  const isAuthenticated = Boolean(authState?.isAuthenticated);

  /**
   * Callbacks
   */
  // 🟤 Cb - Permissions
  const hasPermission = useCallback(
    (find: string) => Boolean(profile?.permissions?.includes(find)),
    [profile?.permissions]
  );

  /**
   * Output (memo)
   */
  return useMemo<NavData>(
    () => ({
      max: [
        {
          id: 'contracts',
          name: t('common.contracts'),
          to: '/contracts',
          condition: (isInvoiceOnly && isMincron) || isWaterworks,
          comingSoon: false
        },
        {
          id: 'orders',
          name: t('common.orders'),
          to: '/orders',
          condition: true,
          comingSoon: false
        },
        {
          id: 'previously-purchased-products',
          name: t('common.buyAgain'),
          to: '/buyagain',
          condition: isAuthenticated && !isWWOrMincron,
          comingSoon: false
        },
        {
          id: 'invoices',
          name: t('common.invoices'),
          to: '/invoices',
          condition: !isAuthenticated || hasPermission(Permission.VIEW_INVOICE),
          comingSoon: false
        },
        {
          id: 'purchase-approvals',
          name: t('common.purchaseApprovals'),
          to: '/purchase-approvals',
          condition:
            (!isAuthenticated || hasPermission(Permission.APPROVE_CART)) &&
            !isWWOrMincron,
          comingSoon: false
        },
        {
          id: 'quotes',
          name: t('common.quotes'),
          to: '/quotes',
          condition: !isWWOrMincron,
          comingSoon: false
        },
        {
          id: 'lists',
          name: t('common.lists'),
          to: '/lists',
          condition: !isWWOrMincron,
          comingSoon: false
        }
      ],
      profile: [
        {
          id: 'customer-approval',
          name: t('common.customerApproval'),
          to: '/customer-approval',
          condition: hasPermission(Permission.APPROVE_ALL_USERS),
          comingSoon: false
        },
        {
          id: 'user-management',
          name: t('common.userManagement'),
          to: '/user-management',
          condition: hasPermission(Permission.MANAGE_ROLES),
          comingSoon: false
        },
        {
          id: 'account-information',
          name: t('common.accountInformation'),
          to: '/account',
          condition: !!profile?.permissions?.length,
          comingSoon: false
        },
        {
          id: 'payment-information',
          name: t('common.paymentInformation'),
          to: '/payment-information',
          condition:
            hasPermission(Permission.MANAGE_PAYMENT_METHODS) && !isMincron,
          comingSoon: false
        }
      ],
      resources: [
        {
          id: 'credit-forms',
          name: t('common.creditForms'),
          to: '/credit-forms',
          condition: true,
          comingSoon: false
        },
        {
          id: 'job-form',
          name: t('common.jobForm'),
          to: isWWOrMincron ? '/jobform' : '/job-form',
          condition: true,
          comingSoon: false
        },
        {
          id: 'about',
          name: t('common.about'),
          to: '/about',
          condition: true,
          comingSoon: false
        }
      ]
    }),
    [
      hasPermission,
      isAuthenticated,
      isInvoiceOnly,
      isMincron,
      isWWOrMincron,
      isWaterworks,
      profile?.permissions?.length,
      t
    ]
  );
}

export default useNavDataMemo;
