import { memo } from 'react';

import { ListChildComponentProps, areEqual } from 'react-window';
import { Draggable } from 'react-beautiful-dnd';

import { ListSpecial } from 'pages/Lists/provider/ListsPageProvider';
import ListsSelectorItem from 'pages/Lists/sub/ListsSelectorItem';

/**
 * Component
 */
const ListsSelectorItemRow = memo(
  ({ data, index, style }: ListChildComponentProps<ListSpecial[]>) => {
    const { isDragDisabled, ...item } = data[index];
    return (
      <div style={style}>
        <Draggable
          key={`line-item-drag-${item.id}`}
          draggableId={`${item.id}`}
          index={index}
          isDragDisabled={isDragDisabled}
        >
          {(provided, snapshot, rubric) => (
            <div
              key={`line-item-${item.id}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              className="h-full select-none"
              style={provided.draggableProps.style}
              data-testid={`lists-selector-item-row-${rubric.source.index}`}
            >
              <ListsSelectorItem
                testId={`lists-selector-item`}
                isDragDisabled={isDragDisabled}
                list={item}
                index={index}
                isDragged={snapshot.isDragging}
                dragHandleProps={provided.dragHandleProps}
              />
            </div>
          )}
        </Draggable>
      </div>
    );
  },
  areEqual
);

export default ListsSelectorItemRow;
