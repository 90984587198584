import { Order, ProductPricing } from 'generated/graphql';
import { TFunction } from 'react-i18next';

import { ToastConfig } from 'components/Toast';

type AddItemsToCartFn = (items: AddedItemInfo[]) => void;
type AddItemsToCartProps = {
  order?: Order;
  toast: (config: ToastConfig) => void;
  t: TFunction<'translation', undefined>;
  addItemsToCart: AddItemsToCartFn;
  pricingData: ProductPricing[];
};
export type AddedItemInfo = {
  productId: string;
  qty: number;
  minIncrementQty: number;
  pricingData?: ProductPricing;
};

export default function addItemsToCartCb({
  order,
  toast,
  t,
  addItemsToCart,
  pricingData
}: AddItemsToCartProps) {
  if (order?.lineItems?.length) {
    const items: AddedItemInfo[] = order.lineItems
      .filter((i) => {
        const pricing = pricingData.filter(
          (price) => price.productId === i.erpPartNumber
        );
        return !!pricing?.[0]?.sellPrice;
      })
      .map((i) => {
        const pricing = pricingData.filter(
          (price) => price.productId === i.erpPartNumber
        );
        return {
          productId: i?.erpPartNumber ?? '',
          qty: i?.orderQuantity ?? 0,
          minIncrementQty: 1,
          pricingData: pricing[0]
        };
      });

    const badItemCount = order.lineItems.length - items.length;
    if (badItemCount > 0) {
      toast({
        message: t('orders.badItemCount', {
          count: badItemCount,
          plural: badItemCount > 1 ? 's' : ''
        }),
        kind: 'error'
      });
    }

    addItemsToCart(items);
  }
}
