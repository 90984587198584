import { APIOptions } from 'API/hooks/useApiBase';
import { useApiOnMount } from 'API/hooks/useApiOnMount';
import { useApiOnMountAuth } from 'API/hooks/useApiOnMountAuth';
import { ApiCallKinds, HeaderProps } from 'API/lib/core';

/**
 * Types
 */
export type ApiLazyProps<Res, Req = undefined> = APIOptions<Res> & {
  body?: Req;
};
export type ApiOnMountProps<Res, Req = undefined> = ApiLazyProps<Res, Req> & {
  skip?: boolean;
};
export type MakeAPIProps = {
  url: string;
  kind: ApiCallKinds;
  header: HeaderProps;
};

/**
 * Hooks
 */
// 🔵 on mount
export function makeApiOnMount<Res, Req = object>(props: MakeAPIProps) {
  const useBuiltHook = ({
    body,
    skip,
    ...options
  }: ApiOnMountProps<Res, Req>) => {
    const api = useApiOnMount<Res, Req>({ ...props, body, options, skip });
    const refetch = async () => await api.refetch();
    return { ...api, refetch };
  };
  return useBuiltHook;
}

// 🔵 on mount (auth)
export function makeApiOnMountAuth<Res, Req = object>(props: MakeAPIProps) {
  const useBuiltHook = ({
    body,
    skip,
    ...options
  }: ApiOnMountProps<Res, Req>) => {
    const api = useApiOnMountAuth<Res, Req>({
      ...props,
      body,
      options,
      skip
    });
    const refetch = async () => await api.refetch();
    return { ...api, refetch };
  };
  return useBuiltHook;
}
