export const testIds = {
  JobForm: {
    page: 'job-form-page',
    JobFormCard: {
      page: 'job-form-card-page',
      stepOne: 'job-form-card-step-one',
      stepTwo: 'job-form-card-step-two',
      stepThree: 'job-form-card-step-three',
      stepOneContinue: 'job-form-card-step-one-continue',
      stepTwoBack: 'job-form-card-step-two-back',
      stepTwoContinue: 'job-form-card-step-two-continue',
      stepThreeBack: 'job-form-card-step-three-back',
      stepThreeContinue: 'job-form-card-step-three-continue',
      customerNumberEmployee: 'job-form-card-customer-number-employee',
      customerNumberCustomer: 'job-form-card-customer-number-customer',
      customerName: 'job-form-card-customer-name',
      customerNameReset: 'job-form-card-customer-name-reset',
      customerNameLookup: 'job-form-card-customer-name-lookup',
      customerEmail: 'job-form-card-customer-email',
      customerPhone: 'job-form-card-customer-phone',
      projectJobName: 'job-form-card-project-name',
      projectLot: 'job-form-card-project-lot',
      projectAddressOne: 'job-form-card-project-address-one',
      projectAddressTwo: 'job-form-card-project-address-two',
      projectCity: 'job-form-card-project-city',
      projectState: 'job-form-card-project-state',
      projectZip: 'job-form-card-project-zip',
      projectEstimate: 'job-form-card-project-estimate',
      projectTaxExempt: 'job-form-card-project-tax-exempt',
      projectTaxFile: 'job-form-card-project-tax-file',
      contractorName: 'job-form-card-contractor-name',
      contractorAddressOne: 'job-form-card-contractor-address-one',
      contractorAddressTwo: 'job-form-card-contractor-address-two',
      contractorCity: 'job-form-card-contractor-city',
      contractorState: 'job-form-card-contractor-state',
      contractorZip: 'job-form-card-contractor-zip',
      contractorPhone: 'job-form-card-contactor-phone',
      bondingName: 'job-form-card-bonding-name',
      bondingAddressOne: 'job-form-card-bonding-address-one',
      bondingAddressTwo: 'job-form-card-bonding-address-two',
      bondingCity: 'job-form-card-bonding-city',
      bondingState: 'job-form-card-bonding-state',
      bondingZip: 'job-form-card-bonding-zip',
      bondingPhone: 'job-form-card-bonding-phone',
      bondingBond: 'job-form-card-bonding-bond',
      ownerName: 'job-form-card-owner-name',
      ownerAddressOne: 'job-form-card-owner-address-one',
      ownerAddressTwo: 'job-form-card-owner-address-two',
      ownerCity: 'job-form-card-owner-city',
      ownerState: 'job-form-card-owner-state',
      ownerZip: 'job-form-card-owner-zip',
      ownerPhone: 'job-form-card-owner-phone',
      lenderName: 'job-form-card-lender-name',
      lenderAddressOne: 'job-form-card-lender-address-one',
      lenderAddressTwo: 'job-form-card-lender-address-two',
      lenderCity: 'job-form-card-lender-city',
      lenderState: 'job-form-card-lender-state',
      lenderZip: 'job-form-card-lender-zip',
      lenderPhone: 'job-form-card-lender-phone',
      lenderLoan: 'job-form-card-lender-loan',
      successModal: 'job-form-submit-success-modal',
      successModalTitle: 'job-form-success-modal-title',
      successModalEmployeeMessage: 'job-form-success-modal-employee-message',
      successModalReturnHome: 'job-form-success-modal-return-home'
    },
    JobFormLink: {
      page: 'job-form-link'
    }
  },
  SelectAccounts: {
    SelectAccountsForm: {
      billTo: 'select-accounts-form-bill-to',
      shipTo: 'select-accounts-form-ship-to',
      makeDefault: 'select-accounts-form-make-default',
      continue: 'select-accounts-form-continue',
      refreshShipToDone: 'select-accounts-form-refresh-done',
      refreshShipToButton: 'select-accounts-form-refresh-shipto'
    }
  },
  Register: {
    page: 'register-page',
    StepOne: {
      page: 'register-step-one-page',
      emailInput: 'register-step-one-email-input',
      alreadyExists: 'register-step-one-already-exists',
      submitButton: 'register-step-one-submit-button'
    },
    StepTwo: {
      page: 'register-step-two-page',
      employee: 'register-step-two-employee',
      form: 'register-step-two-form',
      accountRadio: 'register-step-two-account-radio',
      customerRadio: 'register-step-two-account-customer',
      fortiline: 'register-step-two-fortiline',
      submitButton: 'register-step-two-submit-button'
    },
    StepFour: {
      page: 'register-step-four-page',
      firstName: 'register-step-four-firstName',
      lastName: 'register-step-four-lastname',
      phoneNumber: 'register-step-four-phoneNumber',
      phoneType: 'register-step-four-phoneType',
      submitButton: 'register-step-four-submit-button',
      previousButton: 'register-step-four-previous-button'
    },
    StepThree: {
      page: 'register-step-three-page',
      accountNumberInput: 'register-step-three-account-number',
      zipCodeInput: 'register-step-three-zipCode',
      accountMessage: 'register-step-three-account-confirmation-message',
      findAccountButton: 'register-step-three-find-button',
      previousButtonMobile: 'register-step-three-previous-button-mobile',
      previousButtonDesktop: 'register-step-three-previous-button-desktop',
      submitButton: 'register-step-three-submit-button',
      openReceiptModal: 'register-step-three-modal',
      receiptModal: 'register-step-three-receipt-modal',
      contactBranchButton: 'register-step-three-contact-branch--button',
      closeReceiptModal: 'register-step-three-close-account-number-modal'
    },
    StepFive: {
      page: 'register-step-five-page',
      passwordInput: 'register-step-five-password',
      txtFldInputConfirm: 'register-step-five-pass-confirm',
      checkboxTermsOfSale: 'register-step-five-checkbox-terms-of-sale',
      checkPrivacyPolicy: 'register-step-five-checkbox-privacy-policy',
      form: 'register-step-five-form',
      errorMessage: 'register-step-five-error-message',
      containEightCharacters: 'register-step-five-eight-character',
      containOneLowercaseLetter: 'register-step-five-lowercase-letter',
      containOneUppercaseLetter: 'register-step-five-uppercase-letter',
      containOneNumber: 'register-step-five-one-number',
      notContainEmailAddress: 'register-step-five-email-address',
      termsOfSaleLink: 'sign-in-form-terms-of-sales',
      privacyPolicyLink: 'sign-in-form-privacy-policy',
      contactBranchLink: 'sign-in-form-contact-branch--button',
      previousButtonDesktop: 'sign-in-previous-button-desktop',
      previousButtonMobile: 'sign-in-previous-button-mobile',
      submitButton: 'register-step-five-submit-button'
    }
  },
  Search: {
    FeaturedSearch: {
      loader: 'featured-search-loader',
      container: 'featured-search-container',
      item: 'featured-search-item',
      itemName: 'featured-search-item-name',
      itemImage: 'featured-search-item-image',
      itemUrl: 'featured-search-item-url'
    }
  },
  SignIn: {
    page: 'sign-in-page',
    emailInput: 'sign-in-form-username',
    title: 'sigin-in-title',
    text: 'sign-in-text',
    passwordInput: 'sign-in-form-password',
    signInButton: 'sign-in-form-button'
  }
};
