import { SyntheticEvent, useMemo, useState } from 'react';

import { Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import useScreenSize from 'hooks/useScreenSize';
import { TabPanel } from 'components';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import FeaturesAndBenefits from 'pages/Product/sub/FeaturesAndBenefits';
import PackageDimensions from 'pages/Product/sub/PackageDimensions';
import ProductOverview from 'pages/Product/sub/ProductOverview';
import TechnicalSpecifications from 'pages/Product/sub/TechnicalSpecifications';

/**
 * Component
 */
function ProductTabs() {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { product } = useProductPageContext();

  /**
   * State
   */
  const [currentTab, setCurrentTab] = useState(0);

  // 🔵 Memo - Empty dimensions
  const isPackageDimensionsEmpty = useMemo(() => {
    const pkgDimensions = product?.packageDimensions;
    return !Boolean(
      pkgDimensions?.height ||
        pkgDimensions?.weight ||
        pkgDimensions?.length ||
        pkgDimensions?.volume ||
        pkgDimensions?.width
    );
  }, [product]);

  /**
   * Callbacks
   */
  // 🟤 Cb - Tab change
  const handleTabChange = (_: SyntheticEvent, newValue: number) =>
    setCurrentTab(newValue);

  /**
   * Render
   */
  if (isSmallScreen || !product) {
    return null;
  }
  return (
    <div className="mt-[60px]" data-testid="pdp-tabs">
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        className="border-secondary-4-100 border-b"
      >
        <Tab
          label={t('product.productOverview')}
          id="product-overview-tab"
          aria-controls="product-overview-tabpanel"
          className={clsx({ '!hidden': !product.productOverview })}
          data-testid="prod-overview-tab"
        />
        <Tab
          label={t('product.featuresBenefits')}
          id="features-and-benefits-tab"
          aria-controls="features-and-benefits-tabpanel"
          className={clsx({ '!hidden': !product.featuresAndBenefits })}
          data-testid="prod-feature-benefits-tab"
        />
        <Tab
          label={t('product.techSpecs')}
          id="technical-specifications-tab"
          aria-controls="technical-specifications-tabpanel"
          className={clsx({ '!hidden': !product.techSpecifications?.length })}
          data-testid="prod-tech-spec-tab"
        />
        <Tab
          label={t('product.dimensions')}
          id="package-dimensions-tab"
          aria-controls="package-dimensions-tabpanel"
          className={clsx({
            '!hidden': !product.packageDimensions || isPackageDimensionsEmpty
          })}
          data-testid="prod-dimensions-tab"
        />
      </Tabs>
      <TabPanel
        value={currentTab}
        index={0}
        aria-labelledby="product-overview-tab"
      >
        <ProductOverview />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={1}
        aria-labelledby="features-and-benefits-tab"
      >
        <FeaturesAndBenefits />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={2}
        aria-labelledby="technical-specifications-tab"
      >
        <TechnicalSpecifications />
      </TabPanel>
      <TabPanel
        value={currentTab}
        index={3}
        aria-labelledby="package-dimensions-tab"
      >
        <PackageDimensions />
      </TabPanel>
    </div>
  );
}
export default ProductTabs;
