import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Order } from 'API/types/orders.types';
import { ChevronRightIcon, OpenIcon } from 'icons';
import { formatDate } from 'utils/dates';

export type OrderSummaryCardProps = {
  content?: Order;
};

function OrderSummaryCard(props: OrderSummaryCardProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { search } = useLocation();

  /**
   * Render
   */
  return (
    <div
      className="bg-common-white border rounded shadow-surround pt-4 pb-2"
      data-testid="order-summary-card"
    >
      <div className="px-4">
        <p>
          <span
            className="block text-secondary-2-70 font-medium"
            data-testid="order-fullfilment"
          >
            {props.content?.fulfillment}
          </span>
        </p>
      </div>
      <div className="px-4 py-1 grid grid-cols-2">
        <p
          className="grid grid-cols-1 text-left text-primary-3-100 font-bold text-xl"
          data-testid="order-number"
        >
          {props.content?.orderNumber}
          <span
            className={clsx(
              'text-sm font-semibold rounded block items-center w-fit gap-x-1.5 px-2 py-1',
              {
                '!text-secondary-2-100 bg-secondary-4-100':
                  props.content?.orderStatus === 'Order placed' ||
                  props.content?.orderStatus === 'INVOICED' ||
                  props.content?.orderStatus === 'OPEN'
              },
              {
                '!text-secondary-2-100 bg-primary-2-5':
                  props.content?.orderStatus === 'In-transit' ||
                  props.content?.orderStatus === 'Ready for pick up'
              },
              {
                '!text-success-100 !bg-success-10':
                  props.content?.orderStatus === 'SHIPPED' ||
                  props.content?.orderStatus === 'Delivered' ||
                  props.content?.orderStatus === 'Picked up'
              },
              {
                '!text-support-1-100 bg-support-1-10':
                  props.content?.orderStatus === 'Canceled'
              }
            )}
            data-testid="order-status"
          >
            {props.content?.orderStatus}
          </span>
        </p>
        <p className="flex justify-end">
          <Link
            to={`/order/${props.content?.orderNumber}`}
            data-testid={`orders-ordernumber-link-${props.content?.orderNumber}`}
            onClick={() => localStorage.setItem('filters', search)}
          >
            <ChevronRightIcon className="text-secondary-2-100" />
          </Link>
        </p>
        <hr className="h-px my-2 px-5 bg-gray-200"></hr>
        <hr className="h-px my-2 px-5 bg-gray-200"></hr>
        <p>
          <span className="block pt-1 pb-1">{t('common.orderDate')}:</span>
        </p>
        <p>
          <span className="flex justify-end pt-1 pb-1" data-testid="order-date">
            {formatDate(
              new Date(props.content?.orderDate?.replace(/-/g, '/')!)
            )}
          </span>
        </p>
        <p>
          <span className="block pb-1">{t('orders.fulfilledDate')}:</span>
        </p>
        <p>
          <span
            className="flex justify-end pb-1"
            data-testid="order-fulfilleddate"
          >
            {Boolean(props.content?.fulfilledDate) &&
              formatDate(
                new Date(props.content?.fulfilledDate?.replace(/-/g, '/')!)
              )}
          </span>
        </p>
        <p>
          <span className="block pb-1">{t('orders.poNumber')}:</span>
        </p>
        <p>
          <span className="flex justify-end pb-1" data-testid="order-ponumber">
            {Boolean(props.content?.poNumber) ? props.content?.poNumber : '--'}
          </span>
        </p>
        <p>
          <span className="block pb-1">{t('common.invoice')}:</span>
        </p>
        <p className="flex justify-end pb-1">
          {Boolean(props.content?.invoiceUrl?.length) ? (
            <a
              href={props.content?.invoiceUrl!}
              target="_blank"
              rel="noreferrer"
              data-testid="order-invoicestatus"
            >
              {props.content?.invoiceStatus}{' '}
              <OpenIcon
                className="inline"
                data-testid="order-invoice-openicon"
              />
            </a>
          ) : (
            '--'
          )}
        </p>
        <p>
          <span className="font-bold block pb-1">
            {t('orders.orderSummaryTotal')}:
          </span>
        </p>
        <p>
          <span
            className="flex justify-end font-bold pb-1"
            data-testid="order-total"
          >
            {Boolean(props.content?.orderTotal) && `$`}
            {props.content?.orderTotal}
          </span>
        </p>
      </div>
    </div>
  );
}

export default OrderSummaryCard;
