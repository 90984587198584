import { useContext, useMemo, useState, useEffect } from 'react';
import { BuilderComponent } from '@builder.io/react';

import {
  Box,
  Container,
  Grid,
  Hidden,
  Button,
  Divider,
  useScreenSize
} from '@dialexa/reece-component-library';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { CategoriesContext } from 'Categories/CategoriesProvider';
import BackToTop from 'common/BackToTop';
import SearchBreadcrumbs from 'Search/SearchBreadcrumbs';
import useBuilderIOSyncAPI from 'pages/BuilderIO/lib/useBuilderIOSyncAPI';
import CategoriesFilterMenu from 'Categories/CategoriesFilterMenu';

function CategoryPage() {
  /**
   * Custom components
   */
  const { isSmallScreen } = useScreenSize();
  /**
   * Context
   */
  const { categories } = useContext(CategoriesContext);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { t } = useTranslation();
  const pathname = window.location.pathname.toLowerCase();
  const categoryName = useMemo(() => {
    const name = pathname.split('/')[2].replaceAll('-', ' ');
    return name.toLowerCase() === 'heating cooling'
      ? t('common.heatingAndCooling')
      : name;
    // eslint-disable-next-line
  }, [pathname]);

  const catFilter = useMemo(() => {
    if (categories)
      return categories!.filter(
        (cat) => cat!.name.toLowerCase() === categoryName.toLowerCase()
      );
    else return null;
  }, [categories, categoryName]);

  //  🟣 BuilderIO - page content
  const {
    contents: content,
    loading: pageLoading,
    reFetch
  } = useBuilderIOSyncAPI('page-category', pathname.toLowerCase());

  useEffect(() => {
    reFetch();
  }, [categoryName, pathname, reFetch]);
  const { title, description, canonicalUrl } = content?.data ?? {};

  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <Box
        flex="1"
        pb={isSmallScreen ? 6 : 12}
        sx={{ bgcolor: 'common.white' }}
      >
        <Container
          maxWidth="lg"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              py: 0,
              px: 4
            }
          })}
        >
          {!pageLoading && isSmallScreen && (
            <Button
              variant="secondary"
              size="small"
              fullWidth
              onClick={() => setFiltersOpen(true)}
              sx={{ mb: 0 }}
              data-testid="filter-count-mob"
            >
              {t('common.filter')}
            </Button>
          )}

          <Grid
            container
            spacing={6}
            sx={{ mt: 0 }}
            data-testid="grid-page-category"
          >
            <Grid item md={3}>
              <Box
                mt={0}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <div>
                  <CategoriesFilterMenu
                    loading={pageLoading}
                    catfilters={catFilter!}
                    category={categoryName}
                    filtersOpen={filtersOpen}
                    onShowHide={(value) => setFiltersOpen(value)}
                  />
                  {isSmallScreen && (
                    <Box pt={3}>
                      <Divider />
                    </Box>
                  )}
                </div>
              </Box>
            </Grid>

            <Grid item xs={12} md={9}>
              {!pageLoading ? (
                <BuilderComponent
                  model="page-category"
                  content={content}
                  data-testid="builderio-page-category"
                />
              ) : null}
              <Hidden mdDown>
                {!pageLoading ? <SearchBreadcrumbs /> : null}
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {!pageLoading && isSmallScreen ? <BackToTop /> : null}
    </>
  );
}

export default CategoryPage;
