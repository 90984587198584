import { EmbeddedSessionResponse } from 'API/types/embedded.types';

/**
 * Function to handle embedding page data in session storage.
 * @param embeddedSession The embedded session to be used
 */
export const embedPage = (embeddedSession: EmbeddedSessionResponse) => {
  if (!embedding()) {
    clearSessionStorage();
    return;
  }
  const oktaToken = embeddedSession.oktaToken;
  // istanbul ignore next
  const decoded = oktaToken ? window.atob(oktaToken.split('.')[1]) : '';
  const data = JSON.parse(decoded);

  const dataToStore = {
    accessToken: {
      accessToken: oktaToken.split(' ')[1],
      claims: data
    }
  };
  sessionStorage.setItem('embeddedOktaToken', JSON.stringify(dataToStore));
  sessionStorage.setItem('deviceId', embeddedSession.deviceId);
  sessionStorage.setItem('extraData', embeddedSession.extraData);
};

/**
 * Function to clear any sessionStorage data related to embedding pages.
 */
export const clearSessionStorage = () => {
  sessionStorage.removeItem('embeddedOktaToken');
  sessionStorage.removeItem('deviceId');
  sessionStorage.removeItem('extraData');
};

/**
 * Function to know if we are embedding.
 */
export const embedding = () => {
  const path = window.location.pathname;
  return path.includes('embedded');
};
