import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, Modal } from 'components';
import useScreenSize from 'hooks/useScreenSize';

/**
 * Props
 */
export type ListDeleteDialogProps = {
  title: string;
  description: string;
  deleteButtonLabel: string;
  open: boolean;
  onClose: () => void;
  onClick: () => void;
};

/**
 * Component
 */
function ListDeleteDialog(props: ListDeleteDialogProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * States
   */
  const [clicked, setClicked] = useState(false);

  /**
   * Render
   */
  return (
    <Modal
      testId="list-delete-dialog"
      open={props.open}
      onClose={props.onClose}
      width={isSmallScreen ? 'xs' : 'sm'}
      disableClose={clicked}
      headerContent
    >
      {/* <>-------------------------<> DELETE <>-------------------------<> */}
      <h5 className="text-primary-1-100 text-center text-2xl font-medium mb-4">
        {props.title}
      </h5>
      <div className="mt-4 text-lg text-center mb-10">{props.description}</div>

      {/* <>-------------------------<> SUBMIT BUTTONS <>-------------------------<> */}
      <div className="flex gap-y-4 items-center transition-none !justify-end flex-col">
        <Button
          key={`list-dialog-delete-button`}
          data-testid={`list-dialog-delete-button`}
          className="flex-1 w-[30%] md:w-full"
          onClick={() => {
            props.onClick();
            setClicked(true);
          }}
          disabled={clicked}
        >
          {clicked ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            props.deleteButtonLabel
          )}
        </Button>
        <Button
          data-testid="list-dialog-cancel-delete-button"
          className="underline !bg-transparent text-primary-2-100 mb-8"
          onClick={props.onClose}
          disabled={clicked}
          color="secondary"
        >
          {t('common.cancel')}
        </Button>
      </div>
    </Modal>
  );
}

export default ListDeleteDialog;
