import {
  createContext,
  FormEventHandler,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { addDays, differenceInDays, subDays, subYears } from 'date-fns';
import { noop } from 'lodash-es';
import {
  DateRange,
  DayClickEventHandler,
  Matcher,
  Modifiers,
  MonthChangeEventHandler
} from 'react-day-picker';
import { useLocation } from 'react-router-dom';

import { OnFormSubmit, OnInputChange, OnInputFocus } from '@reece/global-types';
import useScreenSize from 'hooks/useScreenSize';
import { formatDate } from 'utils/dates';

/**
 * Config
 */
// Used to reset or garantee props
export const EMPTY_RANGE = { from: undefined, to: undefined };
export const nonAutofocusRoutes = ['/orders', '/invoices', '/contracts'];

/**
 * Types
 */
export type FromTo = 'from' | 'to' | null;
export type InputProps = {
  ref: RefObject<HTMLInputElement> | null;
  onBlur: (e: OnInputFocus) => void;
  onFocus: (e: OnInputFocus) => void;
  value?: string;
};
export type DateRangeContextType = {
  fromProps?: InputProps;
  handleDayClick: DayClickEventHandler;
  handleDone?: () => void;
  handlePreset: (preset: number) => void;
  handleRangeSubmit: FormEventHandler;
  handleSubmit: FormEventHandler;
  modifiers?: Modifiers;
  month?: Date;
  rangeProps?: InputProps;
  selected?: Date[];
  setMonth: MonthChangeEventHandler;
  toProps?: InputProps;
  value?: Partial<DateRange>;
  onClear?: () => void;
  setApplied?: (applied: boolean) => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  focused: string | null;
  setFocused: (focused: 'from' | 'to' | null) => void;
};
export type DateRangeProviderProps = {
  children: ReactNode;
  value?: DateRange;
  onChange: (range: DateRange) => void;
  onClear?: () => void;
  setApplied?: (applied: boolean) => void;
  focus?: FromTo;
  viewResults?: (dateRange?: DateRange) => void;
  singleField?: boolean;
};

/**
 * Context
 */
export const defaultDateRangeContext: DateRangeContextType = {
  fromProps: undefined,
  handleDayClick: noop,
  handleDone: noop,
  handlePreset: noop,
  handleRangeSubmit: noop,
  handleSubmit: noop,
  modifiers: undefined,
  month: undefined,
  rangeProps: undefined,
  selected: undefined,
  setMonth: noop,
  toProps: undefined,
  value: undefined,
  onClear: undefined,
  setApplied: undefined,
  setOpen: noop,
  open: false,
  focused: '',
  setFocused: noop
};
export const DateRangeContext = createContext(defaultDateRangeContext);
export const useDateRangeContext = () => useContext(DateRangeContext);

/**
 * Provider
 */
function DateRangeProvider(props: DateRangeProviderProps) {
  /**
   * Props
   */
  const {
    children,
    onChange,
    onClear,
    setApplied,
    singleField,
    value,
    viewResults
  } = props;
  const { from, to } = value ?? {};

  /**
   * Custom hooks
   */
  const { pathname } = useLocation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Refs
   */
  const fromEl = useRef<HTMLInputElement>(null);
  const toEl = useRef<HTMLInputElement>(null);
  const rangeEl = useRef<HTMLInputElement>(null);

  /**
   * State
   */
  const [focused, setFocused] = useState<FromTo>(props.focus ?? null);
  const [month, setMonth] = useState<Date>(value?.from ?? new Date());
  const [open, setOpen] = useState(false);
  const [hasCheckedAutofocus, setHasCheckedAutofocus] = useState(false);
  /**
   * Memos
   */
  // 🔵 memo - modifiers
  const modifiers = useMemo(() => {
    // middle range
    const range_middle: Matcher = { from: new Date(), to: new Date() };
    const twoYearsAgo = subYears(new Date(), 2);
    if (from && to && differenceInDays(to, from) > 1) {
      range_middle.from = addDays(from, 1);
      range_middle.to = subDays(to, 1);
    }
    // output
    return {
      range_middle,
      range_end: to ?? new Date(),
      range_start: from ?? new Date(),
      disabled: [{ before: twoYearsAgo }, { after: new Date() }]
    } as unknown as Modifiers;
  }, [from, to]);
  // 🔵 memo - selected
  const selected = useMemo(() => Object.values(value ?? {}) as Date[], [value]);
  // 🔵 memo - from props
  const fromProps = useMemo(
    () => ({
      onBlur: (e: OnInputChange) => {
        const date = new Date(e.target.value || 'NaN');
        const isDateNaN = isNaN(date.getTime());
        const from = isDateNaN ? undefined : date;
        onChange({ ...EMPTY_RANGE, ...value, from });
        !isDateNaN && setMonth(date);
      },
      onFocus: () => setFocused('from'),
      ref: fromEl,
      value: formatDate(value?.from ?? ''),
      defaultValue: formatDate(value?.from ?? '')
    }),
    [value, onChange]
  );
  // 🔵 memo - to props
  const toProps = useMemo(
    () => ({
      onBlur: (e: OnInputChange) => {
        const date = new Date(e.target.value || 'NaN');
        const isDateNaN = isNaN(date.getTime());
        const to = isDateNaN ? undefined : date;
        onChange({ ...EMPTY_RANGE, ...value, to });
        !isDateNaN && setMonth(date);
      },
      onFocus: () => setFocused('to'),
      ref: toEl,
      value: formatDate(value?.to ?? ''),
      defaultValue: formatDate(value?.to ?? '')
    }),
    [value, onChange]
  );
  // 🔵 memo - range props
  const rangeProps = useMemo(
    () => ({
      onBlur: (e: OnInputChange) => noop,
      onFocus: () => !focused && setFocused('from'),
      ref: rangeEl,
      value:
        formatDate(value?.from ?? '', 'P') +
        ' - ' +
        formatDate(value?.to ?? '', 'P')
    }),
    [focused, value]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - day click
  const handleDayClick = useCallback(
    (day: Date) => {
      setApplied?.(false);
      const newRange = { ...EMPTY_RANGE, ...value };
      const isToFocused = focused === 'to';
      if (focused === 'from') {
        if (to && day > to) {
          newRange.from = to;
          newRange.to = day;
          !singleField && fromEl.current?.focus();
        } else {
          newRange.from = day;
          !singleField ? toEl.current?.focus() : setFocused('to');
        }
      } else if (focused === 'to') {
        if (from && day < from) {
          newRange.from = day;
          newRange.to = from;
          !singleField && toEl.current?.focus();
        } else {
          newRange.to = day;
          !singleField ? fromEl.current?.focus() : setFocused('from');
        }
        !singleField && setOpen(false);
        !singleField && fromEl.current?.blur();
      }
      onChange(newRange);
      isToFocused && !singleField && viewResults?.(newRange);
    },
    [focused, from, onChange, setApplied, singleField, to, value, viewResults]
  );
  // 🟤 Cb - handle done
  const handleDone = useCallback(() => {
    setApplied?.(false);
    setOpen(false);
    setFocused('from');
    viewResults?.(value);
  }, [setApplied, value, viewResults]);
  // 🟤 Cb - handle preset
  const handlePreset = useCallback(
    (preset: number) => {
      setApplied?.(false);
      const newRange = { ...EMPTY_RANGE, ...value };
      newRange.to = new Date();
      newRange.from = subDays(new Date(), preset);
      setOpen(false);
      rangeEl.current?.blur();
      onChange(newRange);
      viewResults?.(newRange);
    },
    [onChange, setApplied, value, viewResults]
  );
  // 🟤 Cb - date range submit
  function handleRangeSubmit(e: OnFormSubmit) {
    e.preventDefault();
    const dateRange = String(e.target[0]?.value);
    const fromDate = new Date(
      dateRange.substring(0, dateRange.indexOf('-')).trim()
    );
    const toDate = new Date(
      dateRange.substring(dateRange.indexOf('-') + 1).trim()
    );
    if (!isNaN(fromDate.getTime()) && !isNaN(toDate.getTime())) {
      setApplied?.(false);
      const newRange = { ...EMPTY_RANGE, ...value };
      newRange.to = toDate;
      newRange.from = fromDate;
      setOpen(false);
      rangeEl.current?.blur();
      onChange(newRange);
      viewResults?.(newRange);
    }
  }
  // 🟤 Cb - submit
  function handleSubmit(e: OnFormSubmit) {
    e.preventDefault();
    let date = new Date(e.target[0]?.value);
    isNaN(date.getTime()) && (date = new Date());
    handleDayClick(date);
  }

  /**
   * Effects
   */
  // 🟡 effect - update input when value changes
  useEffect(() => {
    fromEl.current && (fromEl.current.value = from ? formatDate(from) : '');
    toEl.current && (toEl.current.value = to ? formatDate(to) : '');
  }, [from, to]);
  // 🟡 effect - autofocus
  useEffect(() => {
    if (
      isSmallScreen &&
      !focused &&
      !nonAutofocusRoutes.includes(pathname) &&
      !hasCheckedAutofocus
    ) {
      setHasCheckedAutofocus(true);
      setFocused('from');
      setOpen(true);
      fromEl.current?.focus();
    }
  }, [focused, hasCheckedAutofocus, isSmallScreen, pathname]);

  /**
   * Render
   */
  return (
    <DateRangeContext.Provider
      value={{
        modifiers,
        month,
        setMonth,
        selected,
        handleDayClick,
        handleDone,
        handlePreset,
        handleRangeSubmit,
        handleSubmit,
        fromProps,
        toProps,
        rangeProps,
        value,
        onClear,
        setApplied,
        setOpen,
        open,
        focused,
        setFocused
      }}
    >
      {children}
    </DateRangeContext.Provider>
  );
}

export default DateRangeProvider;
