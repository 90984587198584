import { ReactNode, useContext, useState } from 'react';

import { Collapse } from '@mui/material';
import { isUndefined } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { GetLastSelectedAccountsRequest } from 'API/types/user.types';
import AvailabilityChip from 'common/AvailabilityChip';
import BranchCardLoading from 'common/Branches/BranchCardLoading';
import { useBranchCardData } from 'common/Branches/lib/useBranchCardData';
import useSelectAccountsFormData from 'common/SelectAccounts/lib/useSelectAccountsFormData';
import { Button } from 'components';
import { Branch } from 'generated/graphql';
import { ArrowDropDownIcon, ArrowDropUpIcon } from 'icons';
import { BranchContext } from 'providers/BranchProvider';
import { useSnackbar } from 'providers/SnackbarProvider';

/**
 * Types
 */
export type BranchCardProps = {
  header?: string;
  branch?: Branch;
  setShippingBranch?: (v?: string) => void;
  loading?: boolean;
  availabilityLoading?: boolean;
  icon?: ReactNode;
  stock?: number;
};

/**
 * Component
 */
function BranchCard(props: BranchCardProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { pushAlert } = useSnackbar();
  const branch = useBranchCardData(props.branch);
  const { UpdateLastSelectedAccounts } = useSelectAccountsFormData();

  /**
   * State
   */
  const [moreDetails, setMoreDetails] = useState(false);

  /**
   * Context
   */
  const { shippingBranchLoading, isLocationDistance } =
    useContext(BranchContext);

  /**
   * Callbacks
   */
  // 🟤 Cb - Change branch id
  const handleChangeBranch = async () => {
    // Set last selected accounts
    const body: GetLastSelectedAccountsRequest = {
      lastSelectedBranch: props.branch?.branchId
    };
    try {
      await UpdateLastSelectedAccounts(body);
    } catch (e) {
      pushAlert(t('common.messageFail'), { variant: 'error' });
    }
    // Set branch with graphql
    props.setShippingBranch?.(props.branch?.branchId);
  };

  /**
   * Render
   */
  if (props.loading) {
    return <BranchCardLoading testId={branch.testId} />;
  }
  return (
    <div className="py-5" data-testid={`branchcard-wrapper-${branch.testId}`}>
      {props.header && (
        <p
          className="text-success-100 text-base"
          data-testid={`branchcard-header-${branch.testId}`}
        >
          {props.header}
        </p>
      )}
      <div className="grid grid-cols-3">
        {/* LEFT SIDE - name, address, store details */}
        <div className="col-span-2">
          {/* Name */}
          <div className="flex items-end">
            {Boolean(props.icon) && (
              <span
                className="mr-2"
                data-testid={`branchcard-icon-${branch.testId}`}
              >
                {props.icon}
              </span>
            )}
            <p
              className="text-primary-1-100 text-base font-bold"
              data-testid={`branchcard-name-${branch.testId}`}
            >
              {branch.name}
            </p>
          </div>
          {/* Address */}
          <p
            className="text-base"
            data-testid={`branchcard-address1-${branch.testId}`}
          >
            {branch.address}
          </p>
          {/* City, State Zip */}
          <p
            className="text-base"
            data-testid={`branchcard-address2-${branch.testId}`}
          >
            {branch.cityStateZip}
          </p>
          {/*  Store details */}
          {Boolean(props.branch?.phone || branch.hours.length) && (
            <>
              {/* store details button */}
              <Button
                data-testid={`branchcard-details-button-${branch.testId}`}
                onClick={() => setMoreDetails(!moreDetails)}
                color="gray"
                kind="text"
                size="sm"
                className="mt-4 !p-0"
                iconEndClassName="text-primary-1-100"
                iconEnd={
                  moreDetails ? (
                    <ArrowDropUpIcon data-testid="branchcard-details-icon-up" />
                  ) : (
                    <ArrowDropDownIcon data-testid="branchcard-details-icon-down" />
                  )
                }
              >
                {t('branch.storeDetails')}
              </Button>
              {/* store details collapse */}
              <Collapse in={moreDetails} className="mx-4">
                {/* store details - business hours */}
                {Boolean(branch.hours.length) && (
                  <>
                    <p className="text-primary-1-100 text-sm font-bold">
                      {t('common.hours')}
                    </p>
                    {branch.hours.map((eachHours, i) => (
                      <p className="text-sm" key={i}>
                        {eachHours}
                      </p>
                    ))}
                  </>
                )}
                {/* store details - phone */}
                {Boolean(props.branch?.phone) && (
                  <>
                    <p className="text-primary-1-100 text-sm font-bold">
                      {t('common.phone')}
                    </p>
                    <p
                      className="text-sm"
                      data-testid={`branchcard-phone-${branch.testId}`}
                    >
                      {props.branch!.phone}
                    </p>
                  </>
                )}
              </Collapse>
            </>
          )}
        </div>
        {/* RIGHT SIDE - distamce */}
        <div className="col-span-1 flex flex-col justify-end">
          {/* Distance */}
          {(isLocationDistance || !branch.isHomeBranch) && (
            <div className="mb-6 text-right">
              <p
                className="text-base"
                data-testid={`branchcard-distance-${branch.testId}`}
              >
                {branch.distance}
              </p>
              <p
                className="text-[0.8rem] leading-4 "
                data-testid={`branchcard-distance-sub-${branch.testId}`}
              >
                {isLocationDistance
                  ? t('branch.fromLocation')
                  : t('branch.fromBranch')}
              </p>
            </div>
          )}
          {/* Stock */}
          {!isUndefined(props.stock) && (
            <div className="flex flex-col items-end pb-2">
              <AvailabilityChip
                branch={props.branch}
                loading={shippingBranchLoading || props.availabilityLoading}
                stock={props.stock}
              />
            </div>
          )}
          {/* Select button */}
          <Button
            data-testid={`branchcard-select-button-${branch.testId}`}
            onClick={handleChangeBranch}
            color={branch.isShippingBranch ? 'green' : 'primary'}
            kind={branch.isShippingBranch ? 'fill' : 'outline'}
          >
            {t(branch.isShippingBranch ? 'common.selected' : 'common.select')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default BranchCard;
