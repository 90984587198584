import { useAuthContext } from 'AuthProvider';
import { UseAPILazyProps, useApiLazy } from 'API/hooks/useApiLazy';
import { APICoreProps } from 'API/lib/core';

/**
 * Hook
 */
export function useApiLazyAuth<Response, Request = object>(
  props: UseAPILazyProps<Response, Request>
) {
  /**
   * Context
   */
  const { authState, oktaAuth } = useAuthContext();

  /**
   * Memo
   */
  const token = oktaAuth?.getAccessToken();
  const mySkip = !(token && authState?.isAuthenticated);

  /**
   * API
   */
  const { call, ...rest } = useApiLazy<Response, Request>(props);

  /**
   * Callback
   */
  const asyncCall = async (myProps: APICoreProps<Request>) => {
    if (mySkip) {
      return;
    }
    return await call({
      ...myProps,
      header: { ...myProps.header, Authorization: `Bearer ${token}` }
    });
  };

  /**
   * Output
   */
  return { ...rest, call: asyncCall };
}
