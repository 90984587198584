import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { useHistory } from 'react-router-dom';

import AuthProvider from 'AuthProvider';
import BranchSidebar from 'common/Branches/BranchSidebar';
import CategoriesProvider from 'Categories/CategoriesProvider';
import Footer from 'common/Footer';
import HeaderSelector from 'common/Header/HeaderSelector';
import CompanyListHeaderSelector from 'common/Header/HeaderSelector/CompanyListHeaderSelector';
import HeaderProvider from 'common/Header/HeaderProvider';
import HoldAlert from 'common/HoldAlert';
import MaintenanceBanner from 'common/MaintenanceBanner';
import I18N from 'i18nTestComponent';
import MaintenancePage from 'Maintenance';
import BranchProvider from 'providers/BranchProvider';
import CartProvider from 'providers/CartProvider';
import EmbeddedProvider from 'providers/EmbeddedProvider';
import SelectedAccountsProvider from 'providers/SelectedAccountsProvider';
import SnackbarProvider from 'providers/SnackbarProvider';
import UtagProvider from 'providers/UtagProvider';
import Routes from 'Routes';
import { Configuration } from 'utils/configuration';
import { embedding } from 'utils/embedPage';

// Delete these when refactoring is complete
import { SnackbarProvider as OldSnackbarProvider } from '@dialexa/reece-component-library';
import 'global.scss';
import MessageBanner from 'common/MessageBanner';
import ListsProvider from 'providers/ListsProvider';
import ToastProvider from 'providers/ToastProvider';
/**
 * Config
 */
const oktaAuth = new OktaAuth({
  issuer: `${Configuration.oktaUrl}/oauth2/default`,
  clientId: Configuration.oktaClientId,
  redirectUri: `${window.location.origin}/login/callback`,
  pkce: true
});

/**
 * Component
 */
function App() {
  /**
   * Custom Hooks
   */
  const history = useHistory();

  /**
   * Callbacks
   */
  // 🟤 Cb - Go to login page
  const onAuthRequired = () => history.push('/login');
  // 🟤 Cb - Replace location to select accounts page
  const restoreOriginalUri = (_: OktaAuth, originalUri: string) =>
    history.replace('/postlogin', originalUri);
  const embeddingPage = embedding();
  /**
/**
   * Effect
   */

  /**
   * Render
   */
  if (window.location.pathname === '/credit_callback') {
    return <Routes />;
  }
  return (
    <Security
      oktaAuth={oktaAuth}
      restoreOriginalUri={restoreOriginalUri}
      onAuthRequired={onAuthRequired}
    >
      <ToastProvider>
        <UtagProvider>
          <AuthProvider>
            <OldSnackbarProvider>
              <SnackbarProvider>
                <SelectedAccountsProvider>
                  <EmbeddedProvider>
                    <CategoriesProvider>
                      <CartProvider>
                        <BranchProvider>
                          <HeaderProvider>
                            <MaintenanceBanner />
                            <MessageBanner />
                            <MaintenancePage>
                              <CompanyListHeaderSelector />
                              <BranchSidebar />
                              {Configuration.enableTranslationTest && <I18N />}
                              <div
                                id="main"
                                className="flex flex-col grow shrink-0 basis-auto"
                              >
                                {
                                  /* Conditionally render HeaderSelector if embeddingPage is false; otherwise, we are embedding a screen for Max Mobile, so we do not want to display this element */
                                  !embeddingPage && <HeaderSelector />
                                }
                                <div className="flex flex-col flex-1">
                                  <HoldAlert />
                                  <ListsProvider>
                                    <div className="flex flex-col flex-1 items-stretch">
                                      <div
                                        className="flex flex-col flex-1 min-h-full overflow-hidden"
                                        id="content"
                                      >
                                        <Routes />
                                      </div>
                                    </div>
                                  </ListsProvider>
                                </div>
                                {
                                  /* Conditionally render Footer if embeddingPage is false; otherwise, we are embedding a screen for Max Mobile, so we do not want to display this element */
                                  !embeddingPage && <Footer />
                                }
                              </div>
                            </MaintenancePage>
                          </HeaderProvider>
                        </BranchProvider>
                      </CartProvider>
                    </CategoriesProvider>
                  </EmbeddedProvider>
                </SelectedAccountsProvider>
              </SnackbarProvider>
            </OldSnackbarProvider>
          </AuthProvider>
        </UtagProvider>
      </ToastProvider>
    </Security>
  );
}

export default App;
