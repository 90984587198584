import { useMemo } from 'react';

import { Maybe } from 'graphql/jsutils/Maybe';

/**
 * Hook
 */
function useSavingsMemo(cmp?: Maybe<number>, sellPrice?: number) {
  // memo
  return useMemo(() => {
    const price = (cmp || 0) - (sellPrice || 0);
    const isSavings = price >= 0.01;
    const percentage = ((price / (cmp || 0)) * 100).toFixed(0);
    return { price, percentage, isSavings };
  }, [cmp, sellPrice]);
}
export default useSavingsMemo;
