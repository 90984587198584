import { useMemo, useState } from 'react';

import { orderBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useFlexLayout, usePagination, useSortBy, useTable } from 'react-table';

import { useBranchColumnMemo } from 'pages/BranchManagement/lib/columns';
import EditModal from 'pages/BranchManagement/sub/EditModal';
import PermissionRequired, { Permission } from 'common/PermissionRequired';
import TablePageLayout from 'common/TablePageLayout';
import TableRenderer from 'common/TablePageLayout/TableRenderer';
import {
  BranchListItemFragment,
  useGetBranchesListQuery
} from 'generated/graphql';

/**
 * Component
 */
function BranchManagement() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  /**
   * State
   */
  const [editOpen, setEditOpen] = useState(false);
  const [selectedBranch, setSelectedBranch] =
    useState<BranchListItemFragment>();

  /**
   * Data
   */
  const { data: branchesListData, loading: branchesListLoading } =
    useGetBranchesListQuery();

  /**
   * Memos
   */
  // 🔵 Memo - ordered branch data
  const displayedData = useMemo(
    () => orderBy(branchesListData?.branches, ({ branchId }) => branchId),
    [branchesListData?.branches]
  );

  const columns = useBranchColumnMemo();

  /**
   * Table
   */
  const tableInstance = useTable(
    {
      data: displayedData,
      columns
    },
    useSortBy,
    useFlexLayout,
    usePagination
  );

  /**
   * Render
   */
  return (
    <>
      <EditModal
        open={editOpen}
        selectedBranch={selectedBranch}
        onClose={handleEditClose}
        testId="branch-edit-modal"
      />
      <PermissionRequired
        permissions={[Permission.MANAGE_BRANCHES]}
        redirectTo="/"
      >
        <TablePageLayout
          loading={branchesListLoading}
          pageTitle={t('branchManagement.storeFinderAdmin')}
          table={
            <TableRenderer
              resultsCount={tableInstance.rows.length}
              resultsCountText={t('branchManagement.branches')}
              noResultsMessage={t('common.noResultsFound')}
              tableInstance={tableInstance}
              primaryKey="branchId"
              testId="store-finder-table"
              onRowClick={handleRowClick}
            />
          }
        />
      </PermissionRequired>
    </>
  );
  /**
   * Callback Defs
   */
  function handleRowClick(data: BranchListItemFragment) {
    setSelectedBranch(data);
    setEditOpen(true);
  }

  function handleEditClose() {
    setEditOpen(false);
    setSelectedBranch(undefined);
  }
}

export default BranchManagement;
