import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { noop } from 'lodash-es';

import { WrapperProps } from '@reece/global-types';
import { useAuthContext } from 'AuthProvider';
import {
  AccountDetailsQuery,
  ErpAccount,
  useAccountDetailsQuery,
  UserQuery,
  useUserQuery
} from 'generated/graphql';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { encryptData } from 'utils/encrypt';

/**
 * Config
 */
export const defaultUserInfo = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneTypeId: '',
  email: '',
  role: '',
  id: ''
};

/**
 * Types
 */
export type UserInfo = typeof defaultUserInfo;
export type AccountPageContextType = {
  accountQuery?: AccountDetailsQuery;
  companyInfo: ErpAccount;
  handleFinishEditing: (values?: UserInfo) => void;
  handleStartEditing: () => void;
  isEditing: boolean;
  loading: boolean;
  userInfo: UserInfo;
  userQuery?: UserQuery;
};

/**
 * Context
 */
export const defaultAccountPageContext: AccountPageContextType = {
  companyInfo: {},
  handleFinishEditing: noop,
  handleStartEditing: noop,
  isEditing: false,
  loading: false,
  userInfo: { ...defaultUserInfo }
};
export const AccountPageContext = createContext(defaultAccountPageContext);
export const useAccountPageContext = () => useContext(AccountPageContext);

/**
 * Provider
 */
function AccountProvider({ children }: WrapperProps) {
  /**
   * Custom Hooks
   */
  const { brand } = useDomainInfo();

  /**
   * Contexts
   */
  const { profile } = useAuthContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  /**
   * States
   */
  const [isEditing, setIsEditing] = useState(false);
  const [userInfo, setUserInfo] = useState(defaultUserInfo);

  // 🔵 Memo - Encrypted ShipTo and BillTo
  // 🔶 Initialized here so it can be used on graphql query
  const encryptedBillTo = useMemo(
    () => encryptData(selectedAccounts?.billTo?.id ?? ''),
    [selectedAccounts?.billTo?.id]
  );

  /**
   * Data
   */
  // 🟣 Query - User
  const { data: userQuery, loading: userLoading } = useUserQuery({
    variables: { userId: profile?.userId }
  });
  // 🟣 Query - Account Details
  const { data: accountQuery, loading: accountLoading } =
    useAccountDetailsQuery({
      variables: {
        accountId: encryptedBillTo,
        brand
      }
    });

  /**
   * Memo
   */
  // 🔵 Memo - Company info
  const companyInfo: ErpAccount = useMemo(() => {
    const erpSystemName = selectedAccounts.erpSystemName;
    if (accountQuery?.account && erpSystemName) {
      return accountQuery.account.filter(
        (i) => i?.erpName === erpSystemName
      )[0];
    }
    // Check if accountQuery data is available and accountId is stored in sessionStorage
    if (accountQuery) {
      // If so, we return the first account
      return accountQuery.account[0];
    }

    return {};
  }, [accountQuery, selectedAccounts.erpSystemName]);

  /**
   * Callbacks
   */
  // 🟤 Cb - start editing
  const handleStartEditing = () => setIsEditing(true);
  // 🟤 Cb - finish editing
  const handleFinishEditing = (values?: UserInfo) => {
    values && setUserInfo({ ...userInfo, ...values });
    setIsEditing(false);
  };

  /**
   * Effects
   */
  // 🟡 Effect - apply user info if valid
  useEffect(() => {
    if (userQuery?.user) {
      setUserInfo({
        firstName: userQuery.user.firstName!,
        lastName: userQuery.user.lastName!,
        phoneNumber: userQuery.user.phoneNumber!,
        phoneTypeId: userQuery.user.phoneType!,
        email: userQuery.user.email!,
        role: userQuery.user.role?.name!,
        id: userQuery.user.id!
      });
    }
  }, [userQuery?.user]);

  /**
   * Render
   */
  return (
    <AccountPageContext.Provider
      value={{
        accountQuery,
        companyInfo,
        handleFinishEditing,
        handleStartEditing,
        isEditing,
        loading: userLoading || accountLoading,
        userInfo,
        userQuery
      }}
    >
      {children}
    </AccountPageContext.Provider>
  );
}

export default AccountProvider;
