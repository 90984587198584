import { useCallback, useMemo } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ProductBasicInfo } from 'API/types/lists.types';
import { useAuthContext } from 'AuthProvider';
import { Button } from 'components';
import { StarEmptyIcon, StarFilledIcon } from 'icons';
import { useListsContext } from 'providers/ListsProvider';
import { useCartContext } from 'providers/CartProvider';
import injectTestId from 'utils/injectTestId';

/**
 * Types
 */
export type AddToListButtonProps = {
  partNumber?: string;
  quantity?: number;
  index?: number;
  availableInList?: string[];
  routePath?: string;
  updatedAddedToLists?: (val: string[]) => void;
  isAddAlltoList?: boolean;
  cartId?: string;
  isAddedToList?: boolean;
};

/**
 * Component
 */
function AddToListButton(props: AddToListButtonProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Context
   */
  const { featuresLoading } = useAuthContext();
  const { cart } = useCartContext();
  const {
    addProductToLists,
    openAddToListDrawer,
    savedSelectedLists,
    setSelectedProducts,
    associatedLists
  } = useListsContext();

  /**
   * Memos
   */
  // 🔵 memo - added
  const isAdded = useMemo(
    () =>
      Boolean(
        associatedLists?.find(({ productId }) => productId === props.partNumber)
          ?.listIds?.length
      ),
    [props, associatedLists]
  );

  // 🔵 memo - button text
  const buttonText = useMemo(() => {
    if (props.routePath) {
      return '';
    }
    if (isAdded) {
      return t(
        props.isAddAlltoList ? 'common.addedAllToList' : 'common.addedToList'
      );
    }
    return t(props.isAddAlltoList ? 'common.addAllToList' : 'common.addToList');
  }, [isAdded, props.isAddAlltoList, props.routePath, t]);

  /**
   * Callbacks
   */
  // 🟤 Cb - Handle add to list
  const handleAddToList = useCallback(
    (products: ProductBasicInfo[]) => {
      const noSliderNeeded =
        Boolean(Object.keys(savedSelectedLists).length) && !isAdded;
      if (noSliderNeeded) {
        addProductToLists(products, savedSelectedLists);
        return;
      }
      setSelectedProducts(products);
      openAddToListDrawer(products);
    },
    [
      addProductToLists,
      isAdded,
      openAddToListDrawer,
      savedSelectedLists,
      setSelectedProducts
    ]
  );
  // 🟤 Cb - button click
  const onButtonClick = useCallback(() => {
    // Add 1 product to list
    if (!props.isAddAlltoList) {
      const myProduct: ProductBasicInfo = {
        productId: props.partNumber ?? '',
        quantity: props.quantity ?? 1
      };
      handleAddToList([myProduct]);
      return;
    }
    // Add all (cart as of now) to list
    if (!cart?.products) {
      return;
    }
    const products = cart.products.map<ProductBasicInfo>((item) => ({
      productId: item.product?.partNumber ?? '',
      quantity: item.quantity ?? 1
    }));
    handleAddToList(products);
  }, [
    cart?.products,
    handleAddToList,
    props.isAddAlltoList,
    props.partNumber,
    props.quantity
  ]);

  /**
   * Consts
   */
  const testId = injectTestId('add-to-list-button', props.index);

  /**
   * Render
   */
  return (
    <Button
      kind="text"
      color="lightBlue"
      disabled={featuresLoading}
      data-testid={testId}
      className={clsx('!p-0', { 'underline font-bold': isAdded })}
      iconStart={
        isAdded ? (
          <StarFilledIcon
            className="text-secondary-1-100"
            data-testid={`${testId}-filled`}
          />
        ) : (
          <StarEmptyIcon data-testid={`${testId}-empty`} />
        )
      }
      onClick={onButtonClick}
    >
      {buttonText}
    </Button>
  );
}

export default AddToListButton;
