import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Configuration } from 'utils/configuration';

/**
 * Types
 */
export type ApiCallKinds = 'get' | 'delete' | 'post' | 'put';
export type HeaderProps = {
  'X-Auth-Token'?: string;
  'X-UserOperation'?: string;
  Authorization?: string;
};
export type APICoreProps<Request = object> = {
  url: string;
  kind: ApiCallKinds;
  header: HeaderProps;
  body?: Request;
  hostOverride?: string;
  upload?: boolean;
  options?: AxiosRequestConfig;
};
export type SuccessResponse = {
  success: boolean;
};

/**
 * Config
 */
const host = Configuration.bffUrl || '';
const headerConfig = { 'content-type': 'application/json' };
const uploadConfig = { 'content-type': 'multipart/multipart/form-data' };
const otherConfig: AxiosRequestConfig = {
  maxBodyLength: Configuration.maxBodyWidth,
  maxContentLength: Configuration.maxContentWidth
};

/**
 * Main API Call method
 * @param props - APICoreProps<Request>
 * @returns AxiosResponse<Response>
 */
export async function apiCall<Response, Request = object>(
  props: APICoreProps<Request>
) {
  // variables
  const config = {
    headers: { ...headerConfig, ...props.header },
    ...otherConfig,
    ...props.options
  };
  const url = `${props.hostOverride ?? host}/${props.url}`;
  let data: Request | FormData | undefined = props.body;
  if (props.upload && props.body) {
    config.headers = { ...config.headers, ...uploadConfig };
    data = new FormData();
    Object.entries(props.body).forEach(([key, val]: [string, any]) =>
      (data as FormData).append(key, val)
    );
  }
  // types
  type Res = AxiosResponse<Response>;
  // output
  switch (props.kind) {
    // GET
    case 'get':
      return await axios.get<Request, Res>(url, config);
    // POST
    case 'post':
      return await axios.post<Request, Res>(url, data ?? {}, config);
    // PUT
    case 'put':
      return await axios.put<Request, Res>(url, data ?? {}, config);
    // DELETE
    case 'delete':
      return await axios.delete<Request, Res>(url, { ...config, data });
  }
}
