import { Fragment, useMemo } from 'react';

import { DragIndicator } from '@mui/icons-material';
import { Hidden, Skeleton } from '@mui/material';
import clsx from 'clsx';
import { isUndefined } from 'lodash-es';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import AvailabilityChip from 'common/AvailabilityChip';
import ItemUnavailable from 'common/ItemUnavailable';
import QtyInput from 'common/QtyInput';
import {
  AdvancedToolTip,
  Button,
  CheckboxInput,
  FeedbackButton,
  Highlighted,
  Image,
  Link
} from 'components';
import { ProductPricing } from 'generated/graphql';
import { BranchIcon, WarningIcon } from 'icons';
import useScreenSize from 'hooks/useScreenSize';
import getProductAvailability from 'pages/Lists/lib/getProductAvailability';
import {
  ListProduct,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import { useBranchContext } from 'providers/BranchProvider';
import { MAX_CART_ITEMS, useCartContext } from 'providers/CartProvider';
import { format } from 'utils/currency';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Types
 */
export type ListLineItemProps = {
  product: ListProduct;
  index: number;
  isLast?: boolean;
  isDragged: boolean;
  isDragDisabled?: boolean;
  draggable?: boolean;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  testId?: string;
};

/**
 * Component
 */
function ListLineItem(props: ListLineItemProps) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();
  const { push } = useHistory();

  /**
   * Context
   */
  const { toast } = useToastContext();
  const { shippingBranch, setBranchSelectOpen } = useBranchContext();
  const { addItemToCart, disableAddToCart, itemCount } = useCartContext();
  const {
    findProductPricing,
    listProducts,
    pricingError,
    pricingLoading,
    searchListValue,
    selectedList,
    selectedLineItems,
    setListProducts,
    toggleSelectedLineItem,
    callUpdateList
  } = useListsPageContext();
  const incrementQty = props.product.minIncrementQty || 1;
  const myQty =
    (!(props.product.quantity % incrementQty) && props.product.quantity) ||
    incrementQty;

  /**
   * Memo
   */
  // 🔵 Memo - get product pricing
  const pricing = useMemo(
    () => findProductPricing(props.index, props.product.productId),
    [findProductPricing, props.index, props.product.productId]
  );
  // 🔵 Memo - product pricing text
  const productPricing = useMemo(() => {
    const uom = pricing?.orderUom || t('product.each');
    return pricing?.sellPrice ? `${format(pricing.sellPrice)} ${uom}` : '';
  }, [pricing, t]);
  // 🔵 Memo - availability status
  const isAvailable = useMemo(
    () =>
      getProductAvailability(props.product.status, !pricing && !pricingLoading),
    [pricing, pricingLoading, props.product.status]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - add to cart
  const handleAddToCart = () => {
    // Convert BFF type to GQL type (since cart still uses GQl)
    // We can update this once we work on cart to handle BFF
    const priceData: ProductPricing = {
      branchAvailableQty: pricing?.branchAvailableQty ?? 0,
      catalogId: pricing?.catalogId ?? '',
      orderUom: pricing?.orderUom ?? '',
      productId: pricing?.productId ?? '',
      sellPrice: pricing?.sellPrice ?? 0,
      totalAvailableQty: pricing?.totalAvailableQty ?? 0
    };
    addItemToCart(props.product.productId, myQty, incrementQty, priceData);
    toast({
      message: t('lists.addedItemsToCart', { qty: 1 }),
      kind: 'success',
      button: { display: t('lists.viewCart'), action: () => push('/cart') }
    });
  };
  // 🟤 Cb - Qty input change
  const handleQtyInputChange = (value: number) => {
    if (!selectedList || value === myQty) {
      return;
    }
    const mutableProducts = [...listProducts];
    mutableProducts[props.index].quantity = value;
    const mutableProduct = mutableProducts[props.index];
    setListProducts([...mutableProducts]);
    callUpdateList(selectedList.list.id, {
      name: selectedList.list.name,
      description: selectedList.list.description ?? undefined,
      products: [mutableProduct]
    });
  };

  /**
   * Render
   */
  const ProductName = (
    <Highlighted
      text={props.product.name}
      query={searchListValue}
      nullPlaceholder="-"
      data-testid={`${props.testId}-product-name-${props.index}`}
    />
  );

  const AvailabilityBadges = () => (
    <div className="flex flex-nowrap gap-x-2 pt-2 md:gap-y-1 md:flex-col">
      {!isAvailable ? (
        !pricingError && <ItemUnavailable />
      ) : (
        <>
          <Hidden mdUp>
            {pricingLoading ? (
              <Skeleton className="w-24 !h-6" />
            ) : (
              <div className="whitespace-nowrap text-primary-1-90">
                {pricing?.sellPrice ? (
                  <p className="text-sm font-normal">
                    <span className="text-xl font-bold">
                      {`${format(pricing.sellPrice)} `}
                    </span>
                    {t('product.each')}
                  </p>
                ) : (
                  ''
                )}
              </div>
            )}
          </Hidden>
          <span className="min-w-28">
            <AvailabilityChip
              error={pricingError}
              branch={shippingBranch}
              loading={pricingLoading}
              stock={pricing?.branchAvailableQty}
            />
          </span>
          <Button
            data-testid={`check-nearby-branches-button-${props.index}`}
            size="sm"
            kind="text"
            className="!p-0 whitespace-nowrap md:!justify-start"
            iconStart={<BranchIcon />}
            onClick={() => setBranchSelectOpen(true)}
          >
            {t('product.checkNearByBranches')}
          </Button>
        </>
      )}
    </div>
  );

  return (
    <div
      className={clsx(
        'mx-4 py-3 h-[144px] justify-between md:h-full md:max-h-[332px] md:mx-0 md:px-2 flex flex-nowrap items-center gap-x-2 rounded-none shadow-none transition-all md:flex-col md:items-start',
        {
          'rounded-b': props.isLast,
          '!border-transparent !rounded-xl !shadow-surround-lg !mx-2 px-2':
            props.isDragged
        }
      )}
      data-testid={`${props.testId}-line-item-${props.index}`}
    >
      <div className="flex flex-row items-center gap-x-2 md:items-start md:flex-1">
        <div className="h-[120px] max-h-[120px] flex flex-col md:h-full md:max-h-[240px]">
          <div className="flex-1">
            <CheckboxInput
              checked={!isUndefined(selectedLineItems[props.product.productId])}
              onChange={() =>
                toggleSelectedLineItem(props.product.productId, isAvailable)
              }
              color="secondary"
              data-testid={`${props.testId}-checkbox-${props.index}`}
            />
          </div>
          <div
            {...props.dragHandleProps}
            data-testid={`${props.testId}-drag-handle-${props.index}`}
          >
            <DragIndicator
              className={clsx('text-secondary-3-100', {
                '!hidden': props.isDragDisabled
              })}
            />
          </div>
          <div className="flex-1" />
        </div>
        <div className="basis-1/5 h-[72px] max-w-[72px] max-h-[72px] mx-2 self-center">
          <Image
            className="w-full h-full object-contain"
            src={props.product.imageUrls?.thumb ?? ''}
          />
        </div>
        <div className="basis-[80%] w-4/5 md:flex-1">
          <div className="flex flex-nowrap items-center w-full">
            <div className="flex-1 min-w-0">
              <p className="text-secondary-2-100 text-caption mb-2">
                <Highlighted
                  text={props.product.manufacturerName}
                  query={searchListValue}
                  nullPlaceholder="-"
                  data-testid={`${props.testId}-product-manufacturer-name-${props.index}`}
                />
              </p>
              {!isAvailable ? (
                <span
                  className={clsx(
                    '!text-primary-1-100 text-base font-medium mb-2',
                    { 'line-clamp-1': !isSmallScreen }
                  )}
                >
                  {ProductName}
                </span>
              ) : (
                <Link
                  to={`/product/MSC-${props.product.productId}`}
                  className={clsx(
                    '!text-primary-1-100 text-base font-medium mb-2',
                    { 'line-clamp-1': !isSmallScreen }
                  )}
                >
                  {ProductName}
                </Link>
              )}
              <p className="text-secondary-2-100 text-caption">
                <span>{`${t('lists.mfr')} `}</span>
                <Highlighted
                  text={props.product.manufacturerNumber}
                  query={searchListValue}
                  nullPlaceholder="-"
                  data-testid={`${props.testId}-product-manufacturer-number-${props.index}`}
                />
                {', '}
                <Highlighted
                  text={`MSC-${props.product.productId}`}
                  query={searchListValue}
                  data-testid={`${props.testId}-product-id-${props.index}`}
                />
              </p>
              <p className="text-secondary-2-100 text-caption truncate">
                {props.product.customerProductId?.length && (
                  <>
                    <span>{`${t('lists.myPartNumber')}: `}</span>
                    {props.product.customerProductId.map((item, index) => (
                      <Fragment
                        key={`${props.product.productId}-myPartNum-${index}`}
                      >
                        <Highlighted text={item} query={searchListValue} />
                        {index <
                          props.product.customerProductId!.length - 1 && (
                          <span>{', '}</span>
                        )}
                      </Fragment>
                    ))}
                  </>
                )}
              </p>
            </div>
          </div>
          <AvailabilityBadges />
        </div>
      </div>
      <div className="flex gap-2 items-center mx-2 md:w-full md:mx-0">
        <Hidden mdDown>
          {pricingLoading ? (
            <Skeleton className="w-24 !h-6" />
          ) : (
            <p className="text-center whitespace-nowrap">{productPricing}</p>
          )}
        </Hidden>
        <QtyInput
          size={isSmallScreen ? 'md' : 'sm'}
          disabled={!isAvailable}
          value={myQty}
          sync
          noDebounce
          onUpdate={handleQtyInputChange}
          increment={incrementQty}
          min={incrementQty}
          testId={`${props.testId}-product-number-input-${props.index}`}
          max={9999}
        />
        <AdvancedToolTip
          title="Warning"
          text={t('cart.maxLimitToolTip')}
          icon={<WarningIcon width={16} height={16} />}
          placement="bottom"
          disabled={itemCount < MAX_CART_ITEMS}
          wrapperClassName={clsx({ 'w-full': isSmallScreen })}
        >
          <FeedbackButton
            fullWidth
            size={isSmallScreen ? 'lg' : 'md'}
            className="md:!w-full md:!min-w-full"
            testId={`${props.testId}-add-to-cart-button-${props.index}`}
            onClick={handleAddToCart}
            disabled={!isAvailable || disableAddToCart}
            value={t('common.addToCart')}
            valueDone={t('common.addedToCart')}
          />
        </AdvancedToolTip>
      </div>
    </div>
  );
}
export default ListLineItem;
