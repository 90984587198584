import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';
import useSavingsMemo from 'hooks/useSavingsMemo';
import useScreenSize from 'hooks/useScreenSize';
import { HelpIcon } from 'icons';
import { useProductPageContext } from 'pages/Product/ProductPageProvider';
import { format } from 'utils/currency';

/**
 * Component
 */
function ProductPricing() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Context
   */
  const { authState, hasFeature } = useAuthContext();
  const { pricingAndAvailability, product } = useProductPageContext();

  /**
   * Memo
   */
  const saved = useSavingsMemo(
    pricingAndAvailability?.cmp,
    pricingAndAvailability?.sellPrice
  );

  /**
   * Consts
   */
  const uom = pricingAndAvailability?.orderUom ?? t('product.each');
  const sellPrice = format(pricingAndAvailability?.sellPrice ?? 0);

  /**
   * Render
   */
  // 🟩 logged in, CMP enabled
  if (
    authState?.isAuthenticated &&
    saved.isSavings &&
    hasFeature?.('COMPETITIVE_MARKET_PRICE')
  ) {
    // 🟢 no sell price
    if (!pricingAndAvailability?.sellPrice) {
      return (
        <div data-testid="pdp-price-unavailable">
          <h4 className="text-secondary-3-100 text-2xl font-bold">
            {t('product.priceUnavailable')}
          </h4>
        </div>
      );
    }
    // 🟢 has sell price
    return (
      <div data-testid="pdp-price">
        {/* CMP */}
        <div className="flex items-center">
          <p className="text-secondary-2-100 text-base font-normal">
            {t('product.cmp')}
            {': '}
            <span className="font-bold line-through" data-testid="cmp-price">
              {/* cmp is always truthy because isSaving requires cmp to be bigger than 0.01 */}
              {format(pricingAndAvailability.cmp!)}{' '}
            </span>
          </p>
          <Tooltip
            enterTouchDelay={0}
            title={t('product.cmpTooltipText') as string}
            placement={isSmallScreen ? 'top' : 'right'}
          >
            <HelpIcon className="w-5 h-5 ml-2 text-primary-1-100" />
          </Tooltip>
        </div>
        {/* MAIN Price */}
        <span
          className="text-primary-1-100 text-4xl font-bold lowercase"
          data-testid="sell-price"
        >
          {sellPrice} <span className="text-3xl font-light">{uom}</span>
        </span>
        {/* You Save */}
        <p
          className="text-success-100 text-base font-normal"
          data-testid="price"
        >
          {t('common.youSave')}{' '}
          <b data-testid="saved-price">{format(saved.price)} </b>
          {`(${saved.percentage}%)`}
        </p>
      </div>
    );
  }
  // 🟩 Logged in, competitive price (CMP) disabled
  if (authState?.isAuthenticated) {
    return (
      <span
        className="text-secondary-2-100 text-4xl font-bold lowercase"
        data-testid="pdp-sell-price"
      >
        {sellPrice} <span className="text-3xl font-light">{uom}</span>
      </span>
    );
  }
  // 🟩 Logged out, show price feature enabled
  if (hasFeature?.('SHOWS_PRICES')) {
    return (
      <span
        className="text-secondary-2-100 text-4xl font-bold lowercase"
        data-testid="pdp-cmp-price"
      >
        {format(product?.cmp ?? 0)}{' '}
        <span className="text-3xl font-light">{uom}</span>
      </span>
    );
  }
  // 🟩 Logged out, show price feature disabled
  return null;
}
export default ProductPricing;
