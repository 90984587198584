import { createContext, useContext, useEffect, useMemo, useState } from 'react';

import { noop } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { WrapperProps } from '@reece/global-types';
import { useAuthContext } from 'AuthProvider';
import { makeProductSlug } from 'Cart/util';
import { ErrorTypes } from 'common/ErrorBoundary/ErrorComponent';
import { useHeaderContext } from 'common/Header/HeaderProvider';
import {
  Maybe,
  Product,
  ProductPricing,
  useGetProductPricingQuery,
  useGetProductQuery
} from 'generated/graphql';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { useBranchContext } from 'providers/BranchProvider';
import { useListsContext } from 'providers/ListsProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { trackProductViewAction } from 'utils/analytics';
import { timestamp } from 'utils/dates';
import { encryptData } from 'utils/encrypt';

/**
 * Types
 */
export type ProductPageContextType = {
  availableInList: string[];
  product?: Maybe<Product>;
  pricingAndAvailability?: ProductPricing;
  productLoading: boolean;
  pricingLoading: boolean;
  quantity: number;
  setAvailableInList: (v: string[]) => void;
  setQuantity: (v: number) => void;
};
export type ProductPageParam = {
  id: string;
  slugBrand: string;
  slugCategory: string;
};

/**
 * Config
 */
export const PAGE_SIZE = 12;

/**
 * Context
 */
export const defaultProductPageContext: ProductPageContextType = {
  availableInList: [],
  productLoading: false,
  pricingLoading: false,
  quantity: 0,
  setAvailableInList: noop,
  setQuantity: noop
};
export const ProductPageContext = createContext(defaultProductPageContext);
export const useProductPageContext = () => useContext(ProductPageContext);

/**
 * Provider
 */
function ProductPageProvider({ children }: WrapperProps) {
  /**
   * Custom Hooks
   */
  const { brand } = useDomainInfo();
  const history = useHistory();
  const param = useParams<ProductPageParam>();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { authState, user } = useAuthContext();
  const { shippingBranch } = useBranchContext();
  const { searchPage, trackedSearchTerm, pageIndex } = useHeaderContext();
  const { selectedAccounts } = useSelectedAccountsContext();
  const { callGetAssociatedLists } = useListsContext();

  /**
   * State
   */
  const [quantity, setQuantity] = useState(0);
  const [availableInList, setAvailableInList] = useState<string[]>([]);

  // 🔵 Memo - Encrypted ShipTo and BillTo
  // 🔶 Initialized here so it can be used on graphql query
  const encryptedBillTo = useMemo(
    () => encryptData(selectedAccounts.billToErpAccount?.erpAccountId ?? ''),
    [selectedAccounts.billToErpAccount?.erpAccountId]
  );
  const encryptedShipTo = useMemo(
    () => encryptData(selectedAccounts.shipTo?.erpAccountId ?? ''),
    [selectedAccounts.shipTo?.erpAccountId]
  );

  /**
   * Data
   */
  // 🟣 Query - product
  const { data, loading: productLoading } = useGetProductQuery({
    fetchPolicy: 'no-cache',
    variables: {
      productInput: {
        productId: param.id,
        customerNumber: encryptedBillTo
      }
    },
    onCompleted: ({ product }) => {
      setQuantity(product?.minIncrementQty || 1);
      trackProductViewAction({
        authenticated: authState?.isAuthenticated,
        userEmail: user?.email,
        pageNumber: searchPage || null,
        pageIndex: pageIndex + 1 || null,
        searchIndex: (searchPage - 1) * PAGE_SIZE + pageIndex + 1 || null,
        product,
        searchTerm: trackedSearchTerm,
        timestamp,
        brand
      });
      product?.partNumber &&
        callGetAssociatedLists({ products: [product.partNumber] });
    },
    onError: () => {
      history.push({
        pathname: '/error',
        state: { errorType: ErrorTypes.NOT_FOUND }
      });
    }
  });
  // 🟣 Query - product price
  const { data: pricingData, loading: pricingLoading } =
    useGetProductPricingQuery({
      fetchPolicy: 'no-cache',
      skip: !authState?.isAuthenticated || !data?.product,
      variables: {
        input: {
          customerId: encryptedShipTo,
          branchId: shippingBranch?.branchId ?? '',
          productIds: [data?.product?.partNumber ?? ''],
          includeListData: true
        }
      }
    });

  /**
   * Memo
   */
  // 🔵 memo - pricingAndAvailability
  const pricingAndAvailability = useMemo(
    () =>
      pricingData?.productPricing.products.find(
        ({ productId }) => productId === data?.product?.partNumber
      ),
    [data?.product?.partNumber, pricingData?.productPricing.products]
  );

  /**
   * Page Title
   */
  useDocumentTitle(
    t('dynamicPageTitles.product', {
      mfrName: data?.product?.manufacturerName ?? '',
      productName: data?.product?.name ?? '',
      mfrNumber: data?.product?.manufacturerNumber ?? ''
    })
  );

  /**
   * Effects
   */
  // 🟡 effect - adjust qty
  useEffect(() => {
    !productLoading && data && setQuantity(data?.product?.minIncrementQty || 1);
  }, [productLoading, data, setQuantity]);
  // 🟡 effect - adjust page slug url
  useEffect(() => {
    if (
      !productLoading &&
      data?.product &&
      param.slugBrand &&
      param.slugCategory
    ) {
      const { id, manufacturerName, categories } = data.product;
      const productSlug = makeProductSlug(manufacturerName, categories?.at(-1));
      history.replace(`/product/${productSlug}${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productLoading, data?.product, param.slugBrand, param.slugCategory]);

  /**
   * Render
   */
  return (
    <ProductPageContext.Provider
      value={{
        availableInList,
        pricingAndAvailability,
        product: data?.product,
        productLoading,
        pricingLoading,
        quantity,
        setAvailableInList,
        setQuantity
      }}
    >
      {children}
    </ProductPageContext.Provider>
  );
}
export default ProductPageProvider;
