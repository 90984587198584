import { useMemo } from 'react';

import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { ApiOnMountProps, makeApiOnMountAuth } from 'API/lib/makeApi';
import {
  ExportOrdersCSVRequest,
  GetOrdersRequest,
  GetOrdersResponse,
  GetOrderDetailsRequest,
  GetOrderDetailResponse,
  GetProofOfDeliveryRequest,
  PrintOrdersRequest
} from 'API/types/orders.types';

import { generateQueryParam } from 'utils/generateQueryParam';
import { ProofOfDeliveryResponse } from 'generated/graphql';

/**
 * Config
 */
const BASE_URL = 'orders';

/**
 * Hooks
 */
// 🟦 GET /orders - Get orders
export function useApiGetOrders(options: APIOptions<GetOrdersResponse> = {}) {
  const props: UseAPILazyProps<GetOrdersResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {
      'X-UserOperation': 'orders'
    }
  };
  const api = useApiLazyAuth<GetOrdersResponse>(props);
  const call = async (param: GetOrdersRequest) =>
    await api.call({ ...props, url: generateQueryParam(BASE_URL, param) });
  return { ...api, call };
}

// 🟦 GET /orders - Print orders as PDF
export function useApiPrintOrders(options: APIOptions<string> = {}) {
  const props: UseAPILazyProps<string> = {
    url: '',
    kind: 'get',
    options: { ...options, responseType: 'blob' },
    header: {}
  };
  const api = useApiLazyAuth<string>(props);
  const call = async (params: PrintOrdersRequest) =>
    await api.call({
      ...props,
      url: generateQueryParam(`${BASE_URL}/pdf`, params)
    });
  return { ...api, call };
}

// 🟩 POST /orders/csv - Export orders as CSV
export function useApiExportOrdersCSV(options: APIOptions<BlobPart> = {}) {
  const props: UseAPILazyProps<BlobPart> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<BlobPart>(props);
  const call = async (params: ExportOrdersCSVRequest) => {
    const url = generateQueryParam(`${BASE_URL}/csv`, params);
    return await api.call({ ...props, url });
  };
  return { ...api, call };
}

// 🔵 GET /orders/details/{orderNumber} - Get orders details
export function useApiGetOrderDetails(
  props: ApiOnMountProps<GetOrderDetailResponse>,
  params: GetOrderDetailsRequest
) {
  const url = useMemo(
    () =>
      generateQueryParam(`${BASE_URL}/details/${params.orderNumber}`, params),
    [params]
  );
  return makeApiOnMountAuth<GetOrderDetailResponse>({
    url,
    kind: 'get',
    header: {}
  })(props);
}

// 🔵 GET /orders/proof-of-delivery - Get proof of delivery
export function useApiGetProofOfDelivery(
  options: APIOptions<ProofOfDeliveryResponse> = {}
) {
  const props: UseAPILazyProps<ProofOfDeliveryResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<ProofOfDeliveryResponse>(props);
  const call = async (param: GetProofOfDeliveryRequest) =>
    await api.call({
      ...props,
      url: generateQueryParam(`${BASE_URL}/proof-of-delivery`, param)
    });
  return { ...api, call };
}
