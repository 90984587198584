import { useCallback, useEffect, useState } from 'react';

import { Container, Hidden, Link, MenuItem } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';

import useCompanyList from 'common/OldHeader/lib/useCompanyList';
import UserButton from 'common/OldHeader/sub/UserButton';
import { Button, DropdownButton } from 'components';
import { useDomainInfo } from 'hooks/useDomainInfo';
import { HelpIcon, LocationIcon } from 'icons';
import { generateCompanyUrl } from 'utils/brandList';
import { Configuration } from 'utils/configuration';

/**
 * Component
 */
function OldCompanyListHeader() {
  /**
   * Custom Hooks
   */
  const { activeFeatures } = useAuthContext();
  const { port, subdomain } = useDomainInfo();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  /**
   * State
   */
  const [waterworksMaintenance, setWaterworksMaintenance] = useState(false);

  /**
   * Context
   */

  /**
   * Memo
   */
  // 🔵 Memo - Filter company list
  const filteredCompanyList = useCompanyList();

  /**
   * Callbacks
   */
  // 🟤 Cb - company list dropdown content
  const companyListDropdownContent = useCallback(
    () => (
      <div className="py-4">
        {filteredCompanyList.map((company, i) => (
          <Link
            key={i}
            data-testid={`${company.sub}-link`}
            underline="none"
            href={generateCompanyUrl(
              company.sub,
              port,
              Configuration.environment
            )}
          >
            <MenuItem>{company.link}</MenuItem>
          </Link>
        ))}
      </div>
    ),
    [filteredCompanyList, port]
  );

  /**
   * Effect
   */
  // 🟡 Effect - Checks if in fortiline subdomain and waterworks maintenance toggle active
  useEffect(() => {
    activeFeatures?.includes('WATERWORKS_MAINTENANCE') &&
      subdomain === 'fortiline' &&
      setWaterworksMaintenance(true);
  }, [activeFeatures, subdomain]);

  /**
   * Render
   */
  if (
    pathname.includes('/select-accounts') ||
    pathname.includes('/register') ||
    pathname.includes('/barcode') ||
    pathname.includes('/careers') ||
    pathname.includes('/banner/')
  ) {
    return null;
  }
  return (
    <Hidden mdDown>
      <div
        className="bg-common-white border-b border-primary-3-10 print:hidden"
        data-testid="header-companylist-wrapper"
      >
        <Container maxWidth="lg" className="!px-4">
          <div className="py-2 flex justify-between items-center">
            <DropdownButton
              data-testid="company-list"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              content={companyListDropdownContent}
            >
              {t('common.reeceCompanies')}
            </DropdownButton>
            {/* used to control flex */}
            <div className={`w-100 ${waterworksMaintenance && 'hidden'}`}>
              <UserButton />
              <Button
                color="gray"
                className="!py-2 !px-3 !text-xs !leading-normal font-normal"
                iconStart={
                  <LocationIcon className="!w-[18px] !h-[18px] text-primary-1-100" />
                }
                kind="text"
                onClick={() => push('/location-search')}
                data-testid="location-search-button"
              >
                {t('common.storeFinder')}
              </Button>
              <Button
                color="gray"
                className="!py-2 !px-3 !text-xs !leading-normal font-normal"
                iconStart={
                  <HelpIcon className="!w-[18px] !h-[18px] text-primary-1-100" />
                }
                kind="text"
                onClick={() => push('/support')}
                data-testid="support-button"
              >
                {t('support.help')}
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </Hidden>
  );
}

export default OldCompanyListHeader;
