import { useCallback } from 'react';

import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Maybe } from 'yup/lib/types';

import {
  ListAction,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import { ListFormDialogProps } from 'pages/Lists/sub/ListFormDialog';

/**
 * Config
 */
// eslint-disable-next-line no-useless-escape
export const acceptedCharPattern = /^[a-zA-Z0-9\s\-.()#\/]+$/;

/**
 * Types
 */
export type ListNaming = {
  name: string;
  description: string;
  id?: string;
};

/**
 * Components
 **/
function useListActionsForm(props: ListFormDialogProps) {
  /**
   * Custom Hook
   */
  const { lists } = useListsPageContext();
  const { t } = useTranslation();

  /**
   * Callbacks
   */
  // 🟤 Cb - Validate list name to be unique
  const isNameUnique = useCallback(
    (name?: Maybe<string>) =>
      Boolean(
        ((props.actionType === ListAction.create ||
          props.actionType === ListAction.duplicate) &&
          name?.trim() !== props.initialValues.name &&
          !lists.some((l) => l.name === name?.trim())) ||
          // only setting description
          (props.actionType === ListAction.update &&
            name?.trim() === props.initialValues.name) ||
          // rnaming a  list
          (props.actionType === ListAction.update &&
            name?.trim() !== props.initialValues.name &&
            !lists.some((l) => l.name === name?.trim()))
      ),
    [lists, props.actionType, props.initialValues.name]
  );

  /**
   * Form
   */
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('validation.nameRequired'))
      .matches(acceptedCharPattern, {
        message: t('lists.listCharError', { type: 'name' })
      })
      .test('is-name-unique', `${t('lists.listExistsWarning')}`, isNameUnique),
    description: Yup.string().max(
      200,
      `${t('lists.listCountError', {
        type: 'description',
        count: 200
      })}`
    )
  });

  const formik = useFormik({
    initialValues: props.initialValues,
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: props.onSubmitCallback,
    onReset: () => props.onClose()
  });
  return { formik, sendLoading: false, listsLoading: false }; // todo: handle loading state
}

export default useListActionsForm;
