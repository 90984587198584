import React, { useContext, useEffect, useState } from 'react';

import { Container, useScreenSize } from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthContext } from 'AuthProvider';
import EditUser from 'User/EditUser';
import ViewUser from 'User/ViewUser';
import Breadcrumbs from 'common/Breadcrumbs';
import {
  PhoneType,
  useGetAllUnapprovedAccountRequestsLazyQuery,
  useUnapprovedAccountRequestsLazyQuery
} from 'generated/graphql';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { isUndefined } from 'lodash-es';

export type UserInfo = {
  firstName: string;
  lastName: string;
  company: string;
  phone: string;
  email: string;
  language: string;
  id: any;
  roleId?: string;
  approverId?: string;
  phoneType: PhoneType;
  createdAt?: string;
  branchId?: string;
  accountNumber?: string;
  contactUpdatedAt?: string;
  contactUpdatedBy?: string;
};

export const standardUserRoles = [
  'Standard Access',
  'Purchase - No Invoices',
  'Purchase with Approval',
  'Invoice Only'
];

type State = {
  selectedUser: UserInfo;
  customerApproval?: boolean;
  search?: string;
};

function User() {
  /**
   * Custom Hooks
   */
  const { profile } = useContext(AuthContext);
  const { selectedAccounts } = useSelectedAccountsContext();
  const location = useLocation<State>();
  const { search } = useLocation();
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();
  const history = useHistory();

  const rowId = location.pathname.split('/');

  /**
   * State
   */
  const [isEditing, setIsEditing] = useState(false);
  /**
   * Callbacks
   */
  const handleEditClicked = () => setIsEditing(true);
  const handleCancelEditing = () => setIsEditing(false);

  const [customerApprovalQuery] = useGetAllUnapprovedAccountRequestsLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (!location.state && d) {
        //data for Customer Approval
        let customerApprovalData = d.allUnapprovedAccountRequests.filter(
          (elem: any) => elem.id === rowId[2]
        )[0];
        if (customerApprovalData) {
          let userInfo = {
            id: customerApprovalData.id,
            firstName: customerApprovalData.firstName,
            lastName: customerApprovalData.lastName,
            language: 'English',
            email: customerApprovalData.email,
            company: customerApprovalData.companyName,
            phone: customerApprovalData.phoneNumber,
            phoneType: customerApprovalData.phoneType,
            branchId: customerApprovalData.branchId ?? '',
            accountNumber: customerApprovalData.accountNumber ?? ''
          };
          history.push({
            pathname: `/user/${userInfo.id}`,
            state: { selectedUser: userInfo, customerApproval: true, search }
          });
        }
      }
    },
    onError: () => {}
  });

  const [userManagementQuery] = useUnapprovedAccountRequestsLazyQuery({
    variables: {
      accountId: selectedAccounts?.billTo?.id ?? ''
    },
    fetchPolicy: 'network-only',
    onCompleted: (d) => {
      if (!location.state && d) {
        //data for user Management
        let userManagementData: any = d.unapprovedAccountRequests
          ? d.unapprovedAccountRequests.filter(
              (elem: any) => elem.id === rowId[2]
            )[0]
          : undefined;
        if (userManagementData) {
          let userInfo = {
            id: userManagementData.id,
            firstName: userManagementData.firstName,
            lastName: userManagementData.lastName,
            language: 'English',
            email: userManagementData.email,
            company: userManagementData.companyName,
            phone: userManagementData.phoneNumber,
            roleId: userManagementData.role?.id ?? '',
            approverId: userManagementData.approverId,
            phoneType: userManagementData.phoneType,
            contactUpdatedAt: userManagementData.contactUpdatedAt,
            contactUpdatedBy: userManagementData.contactUpdatedBy
          };
          history.push({
            pathname: `/user/${userInfo.id}`,
            state: { selectedUser: userInfo, customerApproval: false, search }
          });
        }
      }
    },
    onError: () => {}
  });

  useEffect(() => {
    customerApprovalQuery();
    userManagementQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {location.state?.customerApproval ? (
        <Breadcrumbs
          config={[
            {
              text: t('common.customerApproval'),
              to: `/customer-approval${location.state?.search ?? ''}`
            }
          ]}
          pageTitle={t('common.userDetails')}
        />
      ) : (
        <Breadcrumbs
          config={[
            {
              text: t('common.userManagement'),
              to: `/user-management${location.state?.search ?? ''}`
            }
          ]}
          pageTitle={t('common.userDetails')}
        />
      )}
      {!isUndefined(location.state) && (
        <Container disableGutters={isSmallScreen}>
          {isEditing ? (
            <EditUser
              loggedInId={profile?.userId}
              user={location.state?.selectedUser}
              onCancel={handleCancelEditing}
            />
          ) : (
            <ViewUser
              data-testid="view-user-button"
              user={location.state?.selectedUser}
              onEditClicked={handleEditClicked}
              customerApproval={Boolean(location.state?.customerApproval)}
            />
          )}
        </Container>
      )}
    </>
  );
}

export default User;
