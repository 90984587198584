import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import { GetProductsPricingResponse } from 'API/types/products.types';

/**
 * Config
 */
const BASE_URL = 'products';

/**
 * Hooks
 */
// 🟦 GET /products/{listId} - Get product pricing with multiple product ids
export function useApiGetProductsPricing(
  options: APIOptions<GetProductsPricingResponse> = {}
) {
  const props: UseAPILazyProps<GetProductsPricingResponse> = {
    url: '',
    kind: 'get',
    options,
    header: {}
  };
  const api = useApiLazyAuth<GetProductsPricingResponse>(props);
  const call = async (listId: string) => {
    return await api.call({ ...props, url: `${BASE_URL}/${listId}` });
  };
  return { ...api, call };
}
