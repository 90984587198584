import { Fragment, useMemo } from 'react';

import {
  Box,
  Card,
  Divider,
  Skeleton,
  Typography
} from '@dialexa/reece-component-library';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useTranslation } from 'react-i18next';

import LineItem from 'Order/LineItem';
import { Order, ProductPricing } from 'generated/graphql';
import Link from 'components/Link';
import { ChevronDownIcon } from 'icons';

type Props = {
  loading?: boolean;
  order?: Order;
  isMincron?: boolean;
  pricingData: ProductPricing[];
  fromOrders?: boolean;
};

function ProductsCard(props: Props) {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Memos
   */
  const orderedPricing = useMemo(
    () =>
      props.order?.lineItems?.map((item) =>
        props.pricingData.find(
          (price) => price.productId === item?.erpPartNumber
        )
      ) ?? [],
    [props.order?.lineItems, props.pricingData]
  );

  return (
    <Card
      sx={{
        height: 1,
        '& .MuiTypography-subtitle2': {
          fontWeight: 400,
          fontSize: (theme) => theme.spacing(1.5)
        }
      }}
    >
      <Accordion>
        <Box p={3}>
          <AccordionSummary
            expandIcon={<ChevronDownIcon />}
            aria-controls="printGridWidth_15"
            id="panel1-header"
          >
            <Typography
              color="primary"
              variant="subtitle2"
              data-testid="items-ordered"
            >
              {props.loading ? (
                <Skeleton width={200} />
              ) : (
                `${t('common.products')} (${props?.order?.lineItems?.length})`
              )}
            </Typography>
          </AccordionSummary>
        </Box>
        <Divider />
        <AccordionDetails>
          <Box px={3}>
            {props.loading ? (
              <LineItem loading />
            ) : props?.order?.lineItems?.length ? (
              props?.order?.lineItems?.map((p, idx) => (
                <Fragment key={p.productId ?? idx}>
                  <LineItem
                    loading={false}
                    lineItem={p}
                    isMincron={props.isMincron}
                    uom={p.uom ?? orderedPricing[idx]?.orderUom}
                    listIds={orderedPricing[idx]?.listIds ?? []}
                    pricingData={orderedPricing[idx]}
                  />
                  {idx + 1 < props!.order!.lineItems!.length && <Divider />}
                </Fragment>
              ))
            ) : (
              <Box px={3} py={8}>
                <Typography
                  variant="h4"
                  textAlign="center"
                  color="secondary03.main"
                >
                  {t('orders.noItemsWaterworks')}
                </Typography>
                <Typography
                  variant="h5"
                  textAlign="center"
                  color="secondary03.main"
                >
                  {t('orders.noOrdersContactMessage')}
                  <Link to="/support" className="underline">
                    {t('orders.noOrdersContactBranch')}
                  </Link>
                </Typography>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
}

export default ProductsCard;
