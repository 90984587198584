import { useState } from 'react';

import { ArrowBack } from '@mui/icons-material';
import { Hidden } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
  useApiGetOrderDetails,
  useApiGetProofOfDelivery
} from 'API/orders.api';
import Breadcrumbs from 'common/Breadcrumbs';
import Loader from 'components/Loader';
import {
  ErpSystemEnum,
  Order,
  ProofOfDeliveryResponse
} from 'generated/graphql';
import { PrintIcon } from 'icons';
import ProductsCard from 'Order/ProductsCard';
import OrderDetailsDelivery from 'pages/Orders/components/OrderDetailsDelivery';
import OrderDetailsRelated from 'pages/Orders/components/OrderDetailsRelated';
import OrderDetailsStatus from 'pages/Orders/components/OrderDetailsStatus';
import OrderDetailsSummary from 'pages/Orders/components/OrderDetailsSummary';
import ProductsCardMobile from 'Order/ProductsCardMobile';
import { useListsContext } from 'providers/ListsProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import ProofOfDeliveryModal from 'Invoice/ProofOfDeliveryModal';

/** Types Order and ProductPricing on some sections of the new orders work are using generated/graphql to be able to reuse components
 * that are still needed, specially for the <ProductCard> component that will be added in ticket MAX-7077. This also affects MAX-7216.
 * Will be adressed with a more updated code in the future to not depend on these versions.
 */

function OrderDetails() {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const breadcrumbConfig = {
    text: t('common.orders'),
    to: `/orders/${localStorage.getItem('filters') || ''}`
  };

  /**
   * Context
   */
  const { selectedAccounts } = useSelectedAccountsContext();
  const erpName = selectedAccounts?.erpSystemName ?? ErpSystemEnum.Eclipse;
  const billTo = selectedAccounts?.billTo?.erpAccountId ?? '';
  const { isMincron } = useSelectedAccountsContext();
  const { callGetAssociatedLists } = useListsContext();

  /**
   * State
   */
  const [proofOfDelivery, setProofOfDelivery] =
    useState<ProofOfDeliveryResponse>();
  const [proofOfDeliveryModalOpen, setProofOfDeliveryModalOpen] =
    useState(false);

  /**
   * API
   */
  // 🟣 Lazy API - get specific order detail
  const { called, loading, data } = useApiGetOrderDetails(
    {
      skip: !id,
      onCompleted: ({ data }) => {
        const productIds = data.productList.products.map(
          (product) => product.erpPartNumber
        );
        callGetAssociatedLists({ products: productIds });
      }
    },
    { orderNumber: id, erpName, billTo }
  );

  // 🟣 Lazy API - get proof of delivery
  const { call: callProofOfDelivery } = useApiGetProofOfDelivery({
    onCompleted: ({ data }) => {
      setProofOfDelivery(data);
    }
  });

  const selectedOrder: Order = {
    amount: null,
    billToName: billTo || null,
    contractNumber: data?.contractNumber,
    creditCard: null,
    customerPO: data?.orderInformation?.poNumber,
    deliveryMethod: data?.deliverySummary?.fulfillmentMethod,
    handling: Number(data?.orderSummary?.handling) || 0,
    invoiceNumber: id || null,
    jobNumber: null,
    lineItems: data?.productList?.products,
    orderDate: data?.orderInformation?.orderDate,
    orderNumber: id,
    orderStatus: data?.orderInformation?.orderStatus,
    orderTotal: data?.orderSummary?.orderTotal,
    orderedBy: data?.orderInformation?.orderedBy,
    shipAddress: data?.deliverySummary?.orderAddress,
    shipDate: data?.deliverySummary?.fulfilledDate,
    shipToId: selectedAccounts.shipTo?.id,
    shipToName: data?.orderInformation?.jobName,
    shippingAmount: Number(data?.orderSummary?.shipping) || 0,
    specialInstructions: data?.deliverySummary?.specialInstructions,
    subTotal: Number(data?.orderSummary?.subTotal),
    tax: Number(data?.orderSummary?.tax),
    terms: null,
    webStatus: data?.orderInformation?.orderStatus
  };

  /**
   * Callbacks
   */
  // 🟤 Cb - handle proof of delivery modal open
  const handleProofOfDeliveryModalOpen = (open: boolean) => () =>
    setProofOfDeliveryModalOpen(open);

  /**
   * Render
   */
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container mx-auto lg">
      <Breadcrumbs
        pageTitle={t('cart.orderDetails')}
        config={[breadcrumbConfig]}
        newMobileStyle
        className="md:pl-6"
      />
      {/** Back and Print buttons section START */}
      <div className="flex mb-4 px-6" data-testid="order-details-back-link">
        <a
          href={`/orders/${localStorage.getItem('filters') || ''}`}
          className="contents"
        >
          <ArrowBack />
          <span
            className="text-base font-medium pl-2"
            data-testid="order-details-back-text"
          >
            {t('common.back')}
          </span>
        </a>
        <button
          className="p-2 mx-5 my-2 border bg-common-white rounded hidden font-semibold float-right"
          data-testid="order-details-print-button"
        >
          <PrintIcon />
          <span className="p-1">{t('common.print')}</span>
        </button>
      </div>
      {/** Back button section end */}
      {called && (
        /** 1st SECTION: Order Status, Delivery and Order Summary */
        <div className="grid grid-cols-3 gap-8 px-6 sm:grid-cols-1">
          <ProofOfDeliveryModal
            open={proofOfDeliveryModalOpen}
            onClose={handleProofOfDeliveryModalOpen(false)}
            pod={proofOfDelivery}
            data-testid="order-detail-pod-modal"
          />
          <OrderDetailsStatus
            orderNumber={id}
            content={data?.orderInformation}
            podAvailable={data?.deliverySummary?.podStatus!}
            handleProofOfDelivery={handleProofOfDelivery}
          />
          <OrderDetailsDelivery
            content={data?.deliverySummary}
            orderNumber={id}
            podAvailable={data?.deliverySummary?.podStatus!}
            handleProofOfDelivery={handleProofOfDelivery}
          />
          <OrderDetailsSummary
            content={data?.orderSummary}
            customerPO={data?.orderInformation?.poNumber}
            productOrder={selectedOrder}
            productPricing={data?.productPricing?.products ?? []}
          />
        </div>
      )}
      {called && (
        /** 2nd SECTION: Product Details and Linked Orders */
        <div className="grid grid-cols-3 gap-8 mt-8 mb-8 px-6 sm:grid-cols-1">
          {/** Product Detail section START */}
          <Hidden mdUp>
            <ProductsCardMobile
              loading={loading}
              order={selectedOrder}
              isMincron={isMincron}
              pricingData={data?.productPricing?.products ?? []}
              fromOrders
            />
          </Hidden>
          <Hidden mdDown>
            <div className="col-span-2">
              <ProductsCard
                loading={loading}
                order={selectedOrder}
                isMincron={isMincron}
                pricingData={data?.productPricing?.products ?? []}
                fromOrders
              />
            </div>
          </Hidden>
          {
            /** Product Details END */
            /** Linked Orders START */
            erpName === ErpSystemEnum.Eclipse && (
              <OrderDetailsRelated
                content={data?.linkedOrders?.linkedOrders ?? []}
              />
            )
            /** Linked Orders END */
          }
        </div>
      )}
    </div>
  );

  // 🔣 util - handle page change
  function handleProofOfDelivery(invoiceNumber: string) {
    callProofOfDelivery({ invoiceNumber, erpName });
    setProofOfDeliveryModalOpen(true);
  }
}

export default OrderDetails;
