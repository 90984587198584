import { useMemo, useCallback, useContext } from 'react';

import {
  Box,
  Button,
  Chip,
  Collapse,
  Hidden,
  Skeleton,
  Typography
} from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'AuthProvider';
import { ProductAttribute, Maybe, AggregationResults } from 'generated/graphql';
import { CloseIcon } from 'icons';
import { BranchContext } from 'providers/BranchProvider';
import SearchFilterList from 'Search/SearchFilterList';
import {
  handleToggleLogics,
  getSelectedFiltersArray
} from 'Search/util/searchFiltersUtil';
import useSearchQueryParams from 'Search/util/useSearchQueryParams';
import { isNumber } from 'lodash-es';

type Props = {
  filters?: Maybe<AggregationResults>;
  loading: boolean;
  onClear: () => void;
};

function SearchFiltersContent(props: Props) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const [params, setParams] = useSearchQueryParams();
  const { categories = [], filters = [] } = params;

  /**
   * Context
   */
  const { shippingBranch } = useContext(BranchContext);
  const { profile } = useContext(AuthContext);

  /**
   * Memos
   */
  const selectedFilters = useMemo(selectedFiltersMemo, [filters]);

  /**
   * Callbacks
   */
  const handleToggle = useCallback(handleToggleCb, [
    categories,
    params,
    selectedFilters,
    setParams
  ]);

  /**
   * Render
   */
  return (
    <Box display="flex" flexDirection="column" width="1">
      <Hidden mdUp>
        <Collapse in={filters.length > 0}>
          <Box pt={1} pb={2} overflow="hidden">
            <Typography
              color="primary"
              variant="body1"
              fontWeight={500}
              py={1}
              px={4}
            >
              {t('common.filters')}
            </Typography>
            <Box display="flex" overflow="auto hidden" px={3}>
              {selectedFilters.map((selectedFilter) => (
                <Box key={selectedFilter.attributeValue} p={1}>
                  <Chip
                    avatar={<CloseIcon />}
                    onClick={() =>
                      handleToggle(selectedFilter as ProductAttribute)
                    }
                    label={`${handleAttributeDisplay(
                      selectedFilter.attributeType
                    )}: ${
                      selectedFilter.attributeType === 'in_stock_location'
                        ? shippingBranch?.name
                        : selectedFilter?.attributeValue
                    }`}
                    data-testid={`selected-fiter-${selectedFilter?.attributeValue}`}
                    styleVariant="stroke"
                  />
                </Box>
              ))}
              <Box
                sx={{ width: (theme) => theme.spacing(3) }}
                flex="1 0 auto"
              />
            </Box>
          </Box>
        </Collapse>
      </Hidden>
      <Hidden mdDown>
        <Collapse in={Boolean(selectedFilters.length)}>
          <Box
            px={5}
            py={3}
            display="flex"
            flexDirection="column"
            borderTop={1}
            borderColor="secondary03.main"
          >
            <Box
              pb={1}
              color="primary.main"
              fontWeight={500}
              letterSpacing="0.005em"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(14),
                lineHeight: theme.typography.pxToRem(22)
              })}
            >
              {t('search.selectedItem', {
                count: selectedFilters.length + categories.length
              })}
              <Button
                variant="inline"
                color="primaryLight"
                onClick={props.onClear}
              >
                <Typography variant="caption">
                  {t('search.clearAll')}
                </Typography>
              </Button>
            </Box>
            {selectedFilters.map((selectedFilter) => (
              <Box
                key={selectedFilter?.attributeValue}
                px={0.5}
                py={1}
                display="grid"
                gridTemplateColumns="24px 100%"
                alignItems="center"
                onClick={() => handleToggle(selectedFilter as ProductAttribute)}
                sx={{ cursor: 'pointer' }}
                data-testid={`selected-fiter-${selectedFilter?.attributeValue}`}
              >
                <Box
                  component={CloseIcon}
                  mr={1.5}
                  height={16}
                  width={16}
                  color="primary.main"
                />
                <Typography variant="caption">
                  {`${handleAttributeDisplay(selectedFilter.attributeType)}: ${
                    selectedFilter.attributeType === 'in_stock_location'
                      ? shippingBranch?.name
                      : selectedFilter?.attributeValue
                  }`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Collapse>
      </Hidden>
      {!props.filters &&
        Array(2).map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            width="100%"
            height={56}
            sx={{ mb: 0.5 }}
          />
        ))}
      {props.filters?.builtAggregates &&
        Array.isArray(props.filters.builtAggregates) &&
        props.filters.builtAggregates.map((filterGroup, index) => {
          const subfilters = filterGroup.individualAggregates ?? [];

          if (
            !(
              filterGroup.filterDisplay?.filterName === 'Category' &&
              categories.length === 3
            ) &&
            !(
              filterGroup.filterDisplay?.filterName === 'In Stock' &&
              !profile?.userId
            ) &&
            isNumber(filterGroup.sortOrder) &&
            filterGroup.sortOrder >= 0 &&
            !(
              filterGroup.filterDisplay?.filterName !== 'In Stock' &&
              !subfilters?.length
            )
          ) {
            return (
              <SearchFilterList
                filter={filterGroup.filterDisplay}
                expanded={filterGroup.expanded ?? false}
                subfilters={subfilters}
                loading={props.loading}
                skeletonTotal={categories.length ?? 0 ? 1 : 3}
                selectedFilters={selectedFilters}
                handleToggle={handleToggle}
                key={filterGroup.filterDisplay?.filterKey}
                index={index}
              />
            );
          }

          return null;
        })}
    </Box>
  );

  /**
   * Memo Definitions
   */
  function selectedFiltersMemo() {
    return getSelectedFiltersArray(filters);
  }

  /**
   * Callback Definitions
   */
  function handleToggleCb(changedFilter: ProductAttribute) {
    handleToggleLogics({
      categories,
      changedFilter,
      params,
      selectedFilters,
      setParams
    });
  }
  function handleAttributeDisplay(attributeType: string) {
    const foundFilter = props.filters?.builtAggregates?.find(
      (filter) => filter.filterDisplay?.filterKey === attributeType
    );
    return foundFilter?.filterDisplay?.filterName ?? attributeType;
  }
}

export default SearchFiltersContent;
