export const links = {
  posh: {
    mainShop: '/search?filters=brand%7CPOSH',
    shopBathroom: '/search?filters=brand%7CPOSH&categories=Bath',
    shopKitchen:
      '/search?filters=brand%7CPOSH&categories=Kitchen%2C%20Bar%20%26%20Laundry',
    youTubeEn: 'https://www.youtube.com/watch?v=UnhlUc7o_uo',
    youTubeEs: 'https://www.youtube.com/watch?v=gLBtKHS0MPw',
    shopProducts: [
      '/search?criteria=Posh%20Solus%20Single%20Handle%20Pull-Down%20Kitchen%20Faucet',
      '/search?criteria=Posh%20Solus%20Pressure%20Balance%20Shower',
      '/search?criteria=Posh%20Solus%20Pressure%20Balance%20Tub%20and%20Shower',
      '/search?criteria=Posh%20Solus%20Widespread%20lavaratory%20Faucet',
      '/search?criteria=1304413%3B%201797339%3B%201797349%3B%201797356',
      '/search?criteria=1304416%3B%201797346%3B%201797363%3B%201797354'
    ],
    brochure: '/files/posh_brochure.pdf'
  },
  careers: 'https://morsco.wd5.myworkdayjobs.com/Reece_Careers',
  footer: {
    doNotSell: '/do-not-sell-my-info',
    privacyPolicy: '/privacy-policy',
    termsOfAccess: '/terms-of-access',
    termsOfSale: '/terms-of-sale'
  },
  bigDog: {
    seeFullRange: '/search?filters=brand%7CBIGDOG',
    featuredItems: [
      '/product/MSC-1585329',
      '/product/MSC-1585382',
      '/product/MSC-1585442',
      '/product/MSC-1585403',
      '/product/MSC-1585395'
    ]
  }
} as const;
