import { useTranslation } from 'react-i18next';

import { OnInputChange } from '@reece/global-types';
import { useAuthContext } from 'AuthProvider';
import { Feature } from 'generated/graphql';
import { useSnackbar } from 'providers/SnackbarProvider';

/**
 * Hook
 */
export function useToggleFeatures() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { pushAlert } = useSnackbar();

  /**
   * Contest
   */
  const { setFeature: setFeatureAuth } = useAuthContext();

  /**
   * Output
   */
  const setFeature = (feature: Feature) => (e: OnInputChange) => {
    setFeatureAuth?.(feature.id, e.target.checked);
    pushAlert(t('featureToggle.changeSaved'), { variant: 'success' });
  };
  return { setFeature };
}
