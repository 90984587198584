import { useMemo } from 'react';

import { escapeRegExp } from 'lodash-es';
import { Maybe } from 'yup/lib/types';

/**
 * Types
 */
export type HightlightedProps = {
  caseSensitive?: boolean;
  className?: string;
  'data-testid'?: string;
  key?: string;
  nullPlaceholder?: string;
  query: string;
  text?: Maybe<string>;
};

/**
 * Component
 */
function Highlighted(props: HightlightedProps) {
  /**
   * Props
   */
  const testId = props['data-testid'] ?? '';
  const {
    caseSensitive,
    className,
    key,
    nullPlaceholder = '',
    query,
    text
  } = props;

  /**
   * Memo
   */
  // 🔵 memo - regex
  const regex = useMemo(
    () => new RegExp(`(${escapeRegExp(query)})`, caseSensitive ? 'g' : 'gi'),
    [caseSensitive, query]
  );
  // 🔵 memo - main logics
  const highlightedElements = useMemo(
    () =>
      (text ?? '')
        .split(regex)
        .filter((part) => part)
        .map((part, i) => {
          const myKey = `${key}-${i}`;
          const myTestId = `${testId}-HL-part${i}`;
          return regex.test(part) ? (
            <mark key={myKey} data-testid={`${myTestId}-highlighted`}>
              {part}
            </mark>
          ) : (
            <span key={myKey} data-testid={`${myTestId}-span`}>
              {part}
            </span>
          );
        }),
    [text, regex, key, testId]
  );

  /**
   * Render
   */
  // No highlight
  if (!query.trim() || !text) {
    return (
      <span className={className} data-testid={`${testId}-HL-none`}>
        {text ?? nullPlaceholder}
      </span>
    );
  }
  // split off highlighted parts
  return (
    <span className={className} data-testid={`${testId}-HL`}>
      {highlightedElements}
    </span>
  );
}

export default Highlighted;
