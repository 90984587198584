import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  useScreenSize
} from '@dialexa/reece-component-library';
import { FormHelperTextProps } from '@mui/material';
import { camelCase, isNull } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { InviteUserFormInput } from 'Invite';
import useInviteUserForm from 'Invite/util/useInviteUserForm';
import { AuthContext } from 'AuthProvider';
import { useContext } from 'react';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

export type InviteUserFormValues = Omit<InviteUserFormInput, 'erp'>;
export type InviteUserProps = {
  onSubmitInvite: (invite: InviteUserFormValues) => void;
};

export default function InviteUser(props: InviteUserProps) {
  /**
   * Custom Hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const { profile } = useContext(AuthContext);
  const { selectedAccounts, setAccountsModalOpen } =
    useSelectedAccountsContext();
  const { isEmployee } = profile ?? {};

  /**
   * Form
   */
  const { approvers, formik, requiresApprover, roles } =
    useInviteUserForm(props);

  const { errors, touched, values, handleBlur, handleChange, handleSubmit } =
    formik;

  /**
   * Render
   */
  return (
    <Box display="flex" flexDirection="column" pt={isSmallScreen ? 3 : 6}>
      <Typography
        variant={isSmallScreen ? 'h4' : 'h3'}
        component="h1"
        color="primary"
        fontWeight={700}
        alignSelf="center"
      >
        {t('user.inviteNewUser')}
      </Typography>
      <Box pt={4} pb={2}>
        <Typography fontWeight={400} data-testid="invite-user-account-text">
          {t('user.inviteUserAccountText')}
        </Typography>
      </Box>
      <Box
        bgcolor="common.white"
        pt={2}
        pb={1}
        border={0.5}
        borderRadius={1}
        borderColor="secondary03.main"
      >
        <Box px={3}>
          <p className="text-base font-bold truncate md:text-caption">
            {isEmployee && (
              <span
                className="text-primary-1-100"
                data-testid="invite-user-account-branchid"
              >
                {t('common.branch')} {selectedAccounts?.billTo?.branchId}
                {' • '}
              </span>
            )}
            <span className="text-primary-3-100">
              {`#${selectedAccounts?.billTo?.erpAccountId} - ${selectedAccounts?.billTo?.name}`}
            </span>
          </p>
          <p className="text-secondary-2-70 text-base truncate md:text-caption">
            {selectedAccounts?.billTo?.address}
          </p>
        </Box>
        <Button
          className="!text-caption"
          color="primaryLight"
          data-testid="change-button"
          variant="text"
          onClick={() => setAccountsModalOpen(true)}
        >
          {t('user.inviteUserAccountChange')}
        </Button>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        pt={isSmallScreen ? 4 : 5}
        pb={isSmallScreen ? 6 : 0}
      >
        <Grid container spacing={isSmallScreen ? 1 : 2}>
          <Grid item xs={6}>
            <TextField
              id="invite-first-name"
              data-testid="firstNameInput"
              name="firstName"
              label={t('common.firstName')}
              placeholder={t('common.firstName')}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.firstName && errors.firstName)}
              helperText={
                touched.firstName && errors.firstName ? errors.firstName : ' '
              }
              inputProps={{ 'data-testid': 'invite-user-firstname-input' }}
              FormHelperTextProps={
                {
                  'data-testid': 'firstname-msg'
                } as FormHelperTextProps
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="invite-last-name"
              data-testid="lastNameInput"
              name="lastName"
              label={t('common.lastName')}
              placeholder={t('common.lastName')}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.lastName && errors.lastName)}
              helperText={
                touched.lastName && errors.lastName ? errors.lastName : ' '
              }
              inputProps={{ 'data-testid': 'invite-user-lastname-input' }}
              FormHelperTextProps={
                {
                  'data-testid': 'lastname-msg'
                } as FormHelperTextProps
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="invite-email"
              data-testid="emailInput"
              name="email"
              label={t('common.emailAddress')}
              placeholder={t('common.enterEmailAddress')}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(touched.email && errors.email)}
              helperText={touched.email && errors.email ? errors.email : ' '}
              inputProps={{ 'data-testid': 'invite-user-email-input' }}
              FormHelperTextProps={
                {
                  'data-testid': 'email-msg'
                } as FormHelperTextProps
              }
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6} data-testid="invite-user-role-grid">
            <Select
              id="invite-role"
              name="userRoleId"
              label={t('common.role')}
              placeholder={t('common.select')}
              value={values.userRoleId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={!!(errors.userRoleId && touched.userRoleId)}
              helperText={
                touched.userRoleId && errors.userRoleId
                  ? errors.userRoleId
                  : ' '
              }
              renderValue={(id) => {
                if (roles.length) {
                  const selectedType = roles
                    .find((pt) => pt?.id === id)
                    ?.name?.toLowerCase();

                  return selectedType
                    ? t(`roles.${camelCase(selectedType)}`)
                    : t('common.select');
                }
              }}
              inputProps={{ 'data-testid': 'invite-user-roles-input' }}
              fullWidth
              required
              sx={{ mt: '0 !important' }}
            >
              {!!roles &&
                roles.map(
                  (role) =>
                    !isNull(role.id) &&
                    !isNull(role.name) && (
                      <MenuItem value={role?.id} key={role.id}>
                        {t(`roles.${camelCase(role.name)}`)}
                      </MenuItem>
                    )
                )}
            </Select>
          </Grid>
          <Grid item xs={6} data-testid="invite-user-approver-grid">
            {requiresApprover && (
              <Select
                id="invite-role-approver"
                data-testid="roleInput"
                name="approverId"
                label={t('common.approver')}
                placeholder="Select"
                value={values.approverId}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                error={!!(touched.approverId && errors.approverId)}
                helperText={
                  touched.approverId && errors.approverId
                    ? errors.approverId
                    : ' '
                }
                renderValue={(id) => {
                  const approver = approvers.find((a) => a.id === id);
                  return approver
                    ? `${approver?.firstName} ${approver?.lastName}`
                    : '';
                }}
                fullWidth
                inputProps={{ 'data-testid': 'invite-user-approver-input' }}
                sx={{ mt: '0 !important' }}
              >
                {approvers.map((approver) => (
                  <MenuItem value={approver.id} key={approver.id}>
                    {approver?.firstName} {approver?.lastName}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Box width={isSmallScreen ? 0.75 : 0.5} mt={isSmallScreen ? 3 : 5}>
              <Button type="submit" fullWidth data-testid="inviteUserButton">
                {t('user.inviteUser')}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
