import { UseAPIOnMountProps, useApiOnMount } from 'API/hooks/useApiOnMount';
import { useAuthContext } from 'AuthProvider';

/**
 * Hook
 */
export function useApiOnMountAuth<Response, Request = object>({
  skip,
  ...props
}: UseAPIOnMountProps<Response, Request>) {
  /**
   * Context
   */
  const { authState, oktaAuth } = useAuthContext();

  /**
   * Memo
   */
  const token = oktaAuth?.getAccessToken();
  const mySkip = !(token && authState?.isAuthenticated);

  /**
   * API
   */
  return useApiOnMount<Response, Request>({
    ...props,
    skip: mySkip || skip,
    header: { ...props.header, Authorization: `Bearer ${token}` }
  });
}
