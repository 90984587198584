import { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { Modify } from '@reece/global-types';
import Button, { ButtonProps } from 'components/Button';

/**
 * Tyles
 */
type NewProps = {
  onClick?: () => void;
  value: string;
  valueDone: string;
  testId?: string;
  timeout?: number;
};
export type FeedbackButtonProps = Modify<ButtonProps, NewProps>;

/**
 * Component
 */
function FeedbackButton(props: FeedbackButtonProps) {
  /**
   * Props
   */
  const {
    className,
    color,
    disabled,
    fullWidth,
    value,
    valueDone,
    size,
    testId,
    timeout,
    iconStart
  } = props;

  /**
   * Refs
   */
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * State
   */
  const [applied, setApplied] = useState(false);

  /**
   * Effects
   */
  useEffect(() => {
    return () => {
      timerRef.current && clearTimeout(timerRef.current);
    };
  }, []);

  /**
   * Callbacks
   */
  const handleClick = () => {
    if (props.onClick) {
      setApplied(true);
      props.onClick();
      timerRef.current = setTimeout(() => setApplied(false), timeout || 3000);
    }
  };

  /**
   * Render
   */
  return (
    <Button
      iconStart={iconStart}
      color={applied ? 'green' : color}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={applied ? undefined : handleClick}
      size={size}
      className={clsx('whitespace-nowrap', className)}
      data-testid={testId}
    >
      {applied ? valueDone : value}
    </Button>
  );
}

export default FeedbackButton;
