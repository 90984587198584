import { useEffect, useMemo } from 'react';

import { CircularProgress, Divider } from '@dialexa/reece-component-library';
import { useTranslation } from 'react-i18next';

import useBuilderIOGetAllAPI from 'pages/BuilderIO/lib/useBuilderIOGetAllAPI';
import FeaturedSearchItem from 'Search/FeaturedSearch/FeaturedSearchItem';
import 'Search/styles/featuredSearch.scss';
import useSearchQueryParams from 'Search/util/useSearchQueryParams';
import { testIds } from 'test-utils/testIds';

/**
 * Types
 */
export type FSItem = {
  name: string;
  image?: string;
  search?: string;
  url?: string;
};

/**
 * Constants
 */
const { container: testIdContainer, loader: testIdLoader } =
  testIds.Search.FeaturedSearch;

/**
 * Component
 */
function FeaturedSearch() {
  /**
   * Custom Hooks
   */
  const [params] = useSearchQueryParams();
  const { criteria = '', page = '1' } = params;
  const { t } = useTranslation();

  /**
   * API
   */
  // 🟣 BuilderIO - popular categories contents
  const searchMatch = {
    searchText: criteria.toLowerCase()
  };
  const {
    contents: popularCategories,
    loading: popularCategoriesLoading,
    refetch
  } = useBuilderIOGetAllAPI(
    'featured-search-item',
    { data: { searchMatch } },
    !criteria || page !== '1'
  );

  /**
   * Memo
   */
  const items = useMemo(searchItemsMemo, [popularCategories, page]);

  /**
   * Effects
   */
  // 🟡 Effect - Refetch popular categories on search term change
  useEffect(() => {
    if (page === '1') {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria, page]);

  /**
   * Render
   */
  if (popularCategoriesLoading && page === '1') {
    return (
      <div
        className="featured-search__loadingcontainer"
        data-testid={testIdLoader}
      >
        <CircularProgress color="primary02.main" size={28} />
      </div>
    );
  }
  if (!items.length) {
    return null;
  }
  return (
    <div data-testid={testIdContainer}>
      <p className="featured-search__title">
        {t('search.featuredSearchTitle')}
      </p>
      <div className="featured-search__container">
        {items.map((item, i) => (
          <FeaturedSearchItem item={item} key={`featured-search-${i}`} />
        ))}
      </div>
      <Divider />
    </div>
  );

  /**
   * Memo Defs
   */
  function searchItemsMemo() {
    if (page !== '1') {
      return [];
    }

    if (!popularCategories?.length) {
      return [];
    }

    // Restructure the data type
    return popularCategories.map(
      ({ image, name, overrideSearchCriteria }) =>
        ({
          image,
          name,
          search: overrideSearchCriteria || undefined
        } as FSItem)
    );
  }
}

export default FeaturedSearch;
