import { BuilderComponent } from '@builder.io/react';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'AuthProvider';
import { Loader } from 'components';
import Home from 'Home';
import PendingUser from 'Home/PendingUser';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useScrollToTop } from 'hooks/useScrollToTop';
import useBuilderIOAPI from 'pages/BuilderIO/lib/useBuilderIOAPI';
import VerifyEmail from 'Register-old/VerifyEmail';

/**
 * Types
 */
export type BuilderIOProps = {
  authRequired?: boolean;
  model: string;
  pathname: string;
  title?: string;
};

/**
 * Component
 */
function BuilderIOCore(props: BuilderIOProps) {
  /**
   * Custom Hooks
   */
  const { t } = useTranslation();
  const { content, isPreviewingInBuilder, loading } = useBuilderIOAPI(
    props.model,
    props.pathname
  );
  useDocumentTitle(props.title ?? t('common.plumbingHVACWaterworksSupply'));
  useScrollToTop();

  /**
   * Context
   */
  const { profile } = useAuthContext();

  /**
   * Render
   */
  if (loading) {
    return <Loader />;
  }
  if (profile?.isEmployee && !profile?.isVerified && props.authRequired) {
    return <VerifyEmail />;
  }
  if (!profile?.permissions?.length && profile?.userId && props.authRequired) {
    return <PendingUser />;
  }
  if (!content && !isPreviewingInBuilder) {
    return <Home />;
  }
  return (
    <div data-testid="main-content">
      <BuilderComponent model={props.model} data={content} />
    </div>
  );
}

export default BuilderIOCore;
