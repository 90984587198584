import { useEffect, useState } from 'react';

import { List, ListItem, SwipeableDrawer, Divider } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuthContext } from 'AuthProvider';
import CategoriesMobileWrapper from 'Categories/CategoriesMobileWrapper';
import MobileDrawerCollapseList from 'common/OldHeader/sub/MobileDrawerCollapseList';
import MobileDrawerCollapseListCompany from 'common/OldHeader/sub/MobileDrawerCollapseListCompany';
import SwitchAccounts from 'common/OldHeader/sub/SwitchAccounts';
import HoldAlert from 'common/HoldAlert';
import { Button, IconButton } from 'components';
import { CloseIcon, MaxIcon, SignOutIcon } from 'icons';
import { useDomainInfo } from 'hooks/useDomainInfo';
import useUserAgent from 'hooks/useUserAgent';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';

/**
 * Props
 */
export type MobileDrawerProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
};

/**
 * Component
 */
export default function MobileDrawer(props: MobileDrawerProps) {
  /**
   * Custom Hooks
   */
  const { push } = useHistory();
  const { t } = useTranslation();
  const { isiOS } = useUserAgent();
  const { isWaterworks, subdomain } = useDomainInfo();

  /**
   * Context
   */
  const { activeFeatures, authState, firstName, profile } = useAuthContext();
  const { selectedAccounts, error: erpAccountError } =
    useSelectedAccountsContext();
  const onHold =
    selectedAccounts.billToErpAccount?.creditHold ||
    selectedAccounts.shipToErpAccount?.creditHold;

  /**
   * State
   */
  const [profileOpen, setProfileOpen] = useState(false);
  const [maxOpen, setMaxOpen] = useState(false);
  const [resourcesOpen, setResourcesOpen] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [waterworksMaintenance, setWaterworksMaintenance] = useState(false);

  /**
   * Callbacks
   */
  // 🟤 Cb - open drawer
  const open = () => props.setOpen(true);
  // 🟤 Cb - close drawer
  const close = () => props.setOpen(false);
  // 🟤 Cb - mobile option
  const handleMobileOptionClick = (path: string, auth?: boolean) => () => {
    push(auth ? (authState?.isAuthenticated ? path : '/login') : path);
    props.setOpen(false);
    setProfileOpen(false);
    setMaxOpen(false);
    setResourcesOpen(false);
    setProfileOpen(false);
  };
  // 🟤 Cb - close categories
  const handleCategoriesClose = (closeDrawer: boolean) => {
    setShowCategories(false);
    if (closeDrawer) {
      close();
    }
  };

  /**
   * Effect
   */
  // 🟡 Effect - Checks if in fortiline subdomain and waterworks maintenance toggle active
  useEffect(() => {
    activeFeatures?.includes('WATERWORKS_MAINTENANCE') &&
      subdomain === 'fortiline' &&
      setWaterworksMaintenance(true);
  }, [activeFeatures, subdomain]);

  /**
   * Render
   */
  return (
    <SwipeableDrawer
      PaperProps={{ className: 'right-0 items-start' }}
      open={props.open}
      disableBackdropTransition={!isiOS}
      disableDiscovery={isiOS}
      onClose={close}
      onOpen={open}
      data-testid="mobile-menu-drawer"
    >
      <CategoriesMobileWrapper
        in={showCategories}
        handleClose={handleCategoriesClose}
      />
      <div className="w-full min-h-full pt-3 pb-6 flex flex-col flex-nowrap">
        <div className="w-full">
          {/* Title Bar */}
          <div className="px-6 flex justify-end">
            <IconButton
              onClick={close}
              size="large"
              className="!p-0"
              data-testid="mobile-close-button"
            >
              <CloseIcon />
            </IconButton>
          </div>
          {/* Accounts */}
          {Boolean(profile?.userId) && (
            <>
              <h5 className="px-6 pb-4 text-xl leading-6 font-medium">
                {t('common.hello', { name: firstName })}!
              </h5>
              <Divider className="px-6" />
              {Boolean(onHold) && (
                <div
                  className="pt-2 px-6"
                  data-testid="header-mobiledrawer-holdalertwrapper"
                >
                  <HoldAlert />
                </div>
              )}
              {!waterworksMaintenance && (
                <div className="px-6">
                  <SwitchAccounts onChange={close} />
                </div>
              )}
            </>
          )}
        </div>
        <div
          className={clsx('flex-1 overflow-auto mt-2', {
            '!mt-0': authState?.isAuthenticated
          })}
        >
          {!waterworksMaintenance ? (
            <List>
              {/* Browse Products */}
              {!isWaterworks && (
                <ListItem
                  className="!px-8 !py-4"
                  onClick={() => setShowCategories(true)}
                  data-testid="browse-products-button"
                >
                  {t('common.browseProducts')}
                </ListItem>
              )}
              {/* Lists */}
              {!isWaterworks && (
                <ListItem
                  className="!px-8 !py-4"
                  onClick={handleMobileOptionClick('/lists', true)}
                  data-testid="my-lists-button"
                >
                  {t('common.myLists')}
                </ListItem>
              )}
              {/* MAX */}
              <MobileDrawerCollapseList
                item="max"
                open={maxOpen}
                setOpen={setMaxOpen}
                urlHandler={handleMobileOptionClick}
              >
                <MaxIcon />
              </MobileDrawerCollapseList>
              {/* My Profile */}
              {!authState?.isAuthenticated ? (
                <ListItem
                  className="!px-8 !py-4"
                  onClick={handleMobileOptionClick('/login')}
                  data-testid="sign-in-header-button"
                >
                  {t('common.signInRegister')}
                </ListItem>
              ) : (
                !erpAccountError && (
                  <MobileDrawerCollapseList
                    item="profile"
                    open={profileOpen}
                    setOpen={setProfileOpen}
                    urlHandler={handleMobileOptionClick}
                  >
                    {t('common.myProfile')}
                  </MobileDrawerCollapseList>
                )
              )}
              {/* Resources */}
              <MobileDrawerCollapseList
                item="resources"
                open={resourcesOpen}
                setOpen={setResourcesOpen}
                urlHandler={handleMobileOptionClick}
              >
                {t('common.resources')}
              </MobileDrawerCollapseList>
              {/* Brands */}
              {!isWaterworks && (
                <ListItem
                  className="!px-8 !py-4"
                  onClick={handleMobileOptionClick('/brands')}
                  data-testid="brands-button"
                >
                  {t('common.brands')}
                </ListItem>
              )}
              {/* Store Finder */}
              <ListItem
                className="!px-8 !py-4"
                onClick={handleMobileOptionClick('/location-search')}
                data-testid="store-finder-button"
              >
                {t('common.storeFinder')}
              </ListItem>
              {/* Support */}
              <ListItem
                className="!px-8 !py-4"
                onClick={handleMobileOptionClick('/support')}
                data-testid="help-button"
              >
                {t('support.help')}
              </ListItem>
              {/* Companies */}
              <MobileDrawerCollapseListCompany />
            </List>
          ) : (
            <List>
              {/* Companies */}
              <MobileDrawerCollapseListCompany />
            </List>
          )}
        </div>
        {/* LOG OUT */}
        {Boolean(authState?.isAuthenticated) && (
          <div className="w-full pr-2 pt-4 flex justify-end">
            <Button
              iconStart={<SignOutIcon />}
              onClick={handleMobileOptionClick('/logout')}
              kind="text"
              data-testid="sign-out-button"
            >
              <p className="pl-3 text-base font-normal text-primary-2-100">
                {t('common.signOut')}
              </p>
            </Button>
          </div>
        )}
      </div>
    </SwipeableDrawer>
  );
}
