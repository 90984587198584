import { noop } from 'lodash-es';

// 🔶 These has no use for test coverage
// istanbul ignore next
export const defaultFnState = () => noop;
// istanbul ignore next
export const asyncNoop = <T>() => new Promise<T>(noop);
// prettier-ignore
// istanbul ignore next
export const defaultNoopOutput = <T>(o: T) => () => o;
// prettier-ignore
// istanbul ignore next
export const asyncNoopOutput = <T>(o: T) => () => new Promise<T>(defaultNoopOutput(o));

export function runMultipleFn(functions: (() => void)[]) {
  if (!functions.length) {
    return;
  }
  functions.forEach((fn) => fn());
}
